import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

const wrapperModifiers = {
  completed: (theme: DefaultTheme) => css`
    background: #e7e7e7 0% 0% no-repeat padding-box;
    border: 1px solid #747474;

    ${Title} {
      color: #747474;
    }
  `,
  pending: (theme: DefaultTheme) => css`
    ${Title} {
      color: ${theme.colors.secondBlack};
    }
  `,
  avaliable: (theme: DefaultTheme) => css``,
};

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: 2.4rem;
    font-family: ${theme.font.family.YaldeviMedium};
    font-weight: bold;
    text-align: center;
    width: 100%;
  `}
`;

export const LinkHeader = styled(Link)``;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    width: 1.5rem;
    height: 1.5rem;
  `}
`;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.secondGray};
    border-bottom: 1px solid ${theme.colors.secondGray};
    background-color: ${theme.colors.white};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    margin: 0 auto;
    background-color: ${theme.colors.white};
    padding: 1rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 88vh;
  `};
`;

export const Main = styled.div`
  ${({ theme }) => css`
    padding: 2rem 0;
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    ${media.greaterThan('small')`
      width:  70rem;
    `}
  `};
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    margin: 0 auto;
    color: #404040;
    text-align: center;

    font-family: ${theme.font.family.LoraRegular};
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.2rem;
    width: 28rem;

    span {
      text-decoration: underline;
    }

    ${media.greaterThan('small')`
      width: 100%;
      font-size: 28px;
    `}
  `}
`;

export const Info = styled.span`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    margin: 1.5rem 0;

    color: ${theme.colors.secondBlack};
    font-size: 1.6rem;
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;

    ${media.lessThan('small')`
      br {display: none}
    `}
  `}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 1rem;

  ${media.greaterThan('small')`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

type BoxProps = {
  selected: boolean;
  status: 'completed' | 'avaliable' | 'pending';
};

export const Box = styled.div<BoxProps>`
  ${({ theme, selected, status }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: ${selected && '1px solid #000000'};

    background-color: ${selected ? '#C3DB43' : theme.colors.white};
    min-height: 5.3rem;
    position: relative;
    cursor: pointer;

    ${Svg} {
      position: absolute;
      top: 1rem;
      right: 1rem;

      width: 2rem;
      height: 2rem;
      color: ${theme.colors.green};
      fill: ${theme.colors.secondOrange};
    }

    ${!!status && wrapperModifiers[status](theme)}
  `}
`;

type statusProps = {
  incomplet?: boolean;
};

export const Status = styled.p<statusProps>`
  ${({ theme, incomplet = false }) => css`
    position: absolute;
    top: 1rem;
    color: ${incomplet ? theme.colors.secondOrange : theme.colors.green};
    font-weight: bold;
  `}
`;

export const Image = styled.img`
  width: 60%;
`;

export const WrapperButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.primary};
    border: none;
    padding: 1.5rem 0;

    position: fixed;
    bottom: 0;

    ${media.greaterThan('small')`
      > button, a {
        width: 20%;
      }
    `}
  `}
`;

export const ButtonWrapper = styled.div`
  width: 24.6rem;
  margin: 5.6rem auto 0;

  ${Info} {
    font-size: 1.2rem;
    letter-spacing: 0;
  }

  ${media.lessThan('small')`
    width: 100%;
  `}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 3rem 0;
  box-shadow: 0 0.5px 0 #000;
`;
