import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const Container = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: 100%;
    `}
  `}
`;

type ContentProps = {
  bg: string;
};

export const Content = styled.div<ContentProps>`
  ${({ theme, bg }) => css`
    background-image: url(${bg});
    background-size: 25%;
    background-position-x: right;
    background-repeat: no-repeat;
    background-color: ${theme.colors.primary};
    width: 100%;
    height: 80vh;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${media.greaterThan('small')`
      height: 83vh;
      background-size: 10%;
    `}
  `}
`;

export const Top = styled.div``;
export const Middle = styled.div``;
export const Bottom = styled.div`
  ${({ theme }) => css`
    width: 100%;

    > button,
    a {
      font-weight: normal;
      letter-spacing: 0.2rem;
      font-family: ${theme.font.family.YaldeviRegular};
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.YaldeviSemiBold};
    font-weight: normal;
  `}
`;

type NumberProps = {
  number: string;
};

export const Number = styled.h1<NumberProps>`
  ${({ theme, number }) => css`
    color: ${theme.colors.secondBlack};
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: '${number}';
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${theme.font.family.LoraVariable};

      width: 5rem;
      height: 5rem;
      border-radius: 2.5rem;
      background-color: ${theme.colors.white};
    }
  `}
`;

export const ProductName = styled.h1`
  ${({ theme }) => css`
    font-weight: bold;
    letter-spacing: 0.3rem;
    text-align: center;
    color: ${theme.colors.black};

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${theme.font.family.LoraVariable};

    &:after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 65%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Author = styled.h2`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraVariable};
    font-weight: normal;
    text-align: center;
  `}
`;

export const Image = styled.img`
  height: 7rem;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;
    font-family: ${theme.font.family.YaldeviRegular};

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.gray};
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
