import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 100vh;

    .field-editing {
      border: none;
      border-bottom: 1px solid ${theme.colors.secondGray};
      background: none;
      font-family: ${theme.font.family.LoraRegular};
      font-size: 16px;

      letter-spacing: 0.3rem;
      font-weight: bold;
      padding: 4px 0;
      width: 100%;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: 1.6rem;
      letter-spacing: 0.2rem;
      height: 3.5rem;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
    padding: 2rem 0;
  `}
`;
export const Description = styled.p`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: 2rem;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.2rem;
    line-height: 2rem;
    font-size: ${theme.font.sizes.small};
    width: 90%;
  `}
`;
export const BoxPerfum = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem 0 0 0;

    div {
      ${Title} {
        font-size: 1.1rem;
      }
    }
  `}
`;

export const Image = styled.img`
  width: 10rem;
  margin: 1rem 0 2rem 2rem;
`;

export const DescriptionPerfum = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    ${Title} {
      color: ${theme.colors.secondBlack};
      font-weight: normal;
      font-size: ${theme.font.sizes.medium};
      font-family: ${theme.font.family.YaldeviRegular};
      padding: 0;
      margin-bottom: 2rem;
      letter-spacing: 0.2rem;
    }

    ${Description} {
      text-align: left;
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      max-width: 80%;
      padding-bottom: 0;
    }
  `}
`;

export const Name = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin-bottom: 1rem;
  `}
`;

export const BoxInfo = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.fourthGray};
    color: ${theme.colors.white};
    font-family: ${theme.font.family.YaldeviRegular};
    font-size: ${theme.font.sizes.medium};
    padding: 1rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
  `}
`;

export const Button = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.green};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraRegular};
    display: block;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding: 1rem;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;
    padding: 2rem;
  `}
`;

export const Details = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};

    ${Title} {
      font-size: ${theme.font.sizes.small};
      text-align: left;
      margin: 0;

        display: block;
        color: ${theme.colors.black};
        font-size: ${theme.font.sizes.xsmall};
        font-weight: normal;
      }
    }

    ${TitleTotal} {
      font-size: ${theme.font.sizes.medium};
      font-weight: medium;
      text-align: left;
      margin: 0;
      color: ${theme.colors.green};
    }
  `}
`;

export const BoxInput = styled.div`
  display: flex;

  margin-top: 2rem;
  input {
    width: 90%;
  }
`;

type LabelProps = {
  disabled?: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme, disabled }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    display: block;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    opacity: ${disabled && 0.3};

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 2rem;
    }
  `}
`;

export const FormContent = styled(Formik)``;

export const FieldContent = styled(Field)`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid ${theme.colors.secondGray};
    padding-bottom: 1rem;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
    display: block;
    width: 100%;

    color: ${theme.colors.black};
    font-weight: bold;
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const Input = styled(InputMask)`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid ${theme.colors.secondGray};
    padding-bottom: 1rem;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
    display: block;
    width: 100%;

    color: ${theme.colors.black};
    font-weight: bold;
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const FormWrapper = styled(Form)`
  ${({ theme }) => css`
    ${Description} {
      margin: 0;
      margin-top: 0.2rem;
      margin-bottom: -1rem;
    }
  `}
`;

export const TitleTotal = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.medium};
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const EstimateBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    margin-bottom: 2rem;
    width: 100%;


    ${Description} {
      text-align: left;
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      margin: 1.6rem 2rem;
      padding 0;
      font-size: ${theme.font.sizes.small};


      &:last-child {
        font-size: 1rem;
        padding-bottom: 1rem;
      }
    }
  `}
`;

export const ModalMain = styled.div`
  ${({ theme }) => css`
    ${Title} {
      font-weight: normal;
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.1rem;
      text-align: center;
      padding: 0;

      &:first-child {
        margin-top: 3rem;
      }
    }

    ${Description} {
      font-weight: bold;
      line-height: 2rem;
      letter-spacing: 0.2rem;
      width: 90%;
      margin: 0 auto;

      &:after,
      &:before {
        content: '';
        display: block;
        width: 100%;
        margin: 2rem 0;
        height: 0.1rem;
        background-color: ${theme.colors.secondGray};
      }
    }
  `}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    a,
    button {
      margin: 1rem;
      border: 1px solid ${theme.colors.secondGray};
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.medium};
      margin-top: 2rem;
    }
  `}
`;

export const ModalSecondMain = styled(ModalMain)`
  ${({ theme }) => css`
    ${Title} {
      font-size: ${theme.font.sizes.xxlarge};
      font-family: ${theme.font.family.LoraRegular};
      font-weight: bold;
      letter-spacing: 0.3rem;
    }

    ${Description} {
      margin: 2rem auto;
      text-align: center;
      font-family: ${theme.font.family.LoraRegular};
      font-weight: normal;
      font-size: 1.3rem;
      width: 100%;

      &:after,
      &:before {
        display: none;
      }
    }

    a,
    button {
      font-size: 1.5rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      letter-spacing: 0.1rem;
      width: 100%;
    }
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.green};
    width: 5.5rem;
    margin: 0 auto;
    padding: 2rem 0;
  `}
`;

export const BoxInfoDelivery = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    margin-top: 5rem;
  `}
`;

export const BgForm = styled.div`
  ${({ theme }) => css`
    padding: 2rem;
    background-color: ${theme.colors.white};
  `}
`;

export const InfoStock = styled.p`
  ${({ theme }) => css`
    color: red;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.1rem;
    padding: 0 1rem;
  `}
`;

export const InfoStockBottom = styled.p`
  ${({ theme }) => css`
    text-align: left;
    color: red;
    font-family: ${theme.font.family.YaldeviRegular};
    margin: 0 2rem;
    padding-bottom: 1rem;
    font-size: ${theme.font.sizes.small};
  `}
`;
