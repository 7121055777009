import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Content = styled.div`
  ${media.greaterThan('small')`
      button, a {
        max-width: 30%;
      }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: medium;
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const Options = styled.div`
  ${({ theme }) => css`
    margin: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

type OptionProps = {
  selected?: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ theme, selected }) => css`
    border: 1px solid ${theme.colors.fourthGray};
    width: 300px;
    padding: 1.5rem 0;
    margin: 1rem 0;
    font-family: ${theme.font.family.YaldeviRegular};

    background: ${!!selected && `${theme.colors.fourthGray}`};
    color: ${selected ? `${theme.colors.white}` : `${theme.colors.fourthGray}`};

    text-align: center;
    letter-spacing: 0.15rem;
  `}
`;
