import {
  PerfumsState,
  PerfumsAction,
  PerfumsRanking,
  PerfumsDetails,
} from './types';

const initialState: PerfumsState = {
  loading: false,
  error: false,
  perfumsRanking: {} as PerfumsRanking,
  perfumsDetails: {} as PerfumsDetails,
  reviewOrder: {
    perfum: {},
    shipping: {},
  },
  errorApi: {},
  modalFinishSuccess: false,
  modalFinishFailure: false,
  modalFinishKit: false,
  responseConfirmPerfumSuccess: {} as any,
};

export default function auth(
  state = initialState,
  action: PerfumsAction
): PerfumsState {
  switch (action.type) {
    case '@ranking/RANKING_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@ranking/RANKING_SUCCESS':
      return {
        ...state,
        loading: false,
        perfumsRanking: action.payload.data,
      };
    case '@ranking/RANKING_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case '@ranking/PERFUM_DETAILS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@ranking/PERFUM_DETAILS_SUCCESS':
      return {
        ...state,
        loading: false,
        perfumsDetails: action.payload.data,
      };
    case '@ranking/PERFUM_DETAILS_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case '@ranking/REVIEW_ORDER_REQUEST':
      return {
        ...state,
        loading: true,
        responseConfirmPerfumSuccess: {},
      };
    case '@ranking/REVIEW_ORDER_SUCCESS':
      return {
        ...state,
        loading: false,
        reviewOrder: action.payload.data,
      };
    case '@ranking/REVIEW_ORDER_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case '@ranking/CONFIRM_ORDER_REQUEST':
      return {
        ...state,
        loading: true,
        errorApi: false,
        modalFinishSuccess: false,
        modalFinishFailure: false,
        modalFinishKit: false,
        responseConfirmPerfumSuccess: {},
      };
    case '@ranking/CONFIRM_ORDER_SUCCESS':
      return {
        ...state,
        loading: false,
        modalFinishSuccess: true,
        responseConfirmPerfumSuccess: action.payload,
      };
    case '@ranking/CONFIRM_ORDER_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        errorApi: action.payload.error,
        modalFinishFailure: true,
      };
    case '@ranking/CLOSE_MODAL_SUCCESS':
      return {
        ...state,
        modalFinishSuccess: false,
      };

    case '@ranking/CLOSE_MODAL_FAILURE':
      return {
        ...state,
        modalFinishFailure: false,
      };

    case '@ranking/OPEN_MODAL_KIT':
      return {
        ...state,
        modalFinishKit: true,
        modalFinishSuccess: false,
      };

    case '@ranking/CLOSE_MODAL_KIT':
      return {
        ...state,
        modalFinishKit: false,
      };
    default:
      return state;
  }
}
