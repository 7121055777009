import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import dashboard from './dashboard/sagas';
import olfactoryJourney from './olfactoryJourney/sagas';
import historic from './historic/sagas';
import perfums from './perfums/sagas';
import account from './account/sagas';
import curiosityFacts from './curiosityFacts/sagas';

export default function* rootSaga(): Generator {
  return yield all([
    auth,
    dashboard,
    olfactoryJourney,
    historic,
    perfums,
    account,
    curiosityFacts,
  ]);
}
