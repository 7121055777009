import { ReactSVG } from 'react-svg';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const StepFeedback = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const Vote = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin: 2rem 0;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.gray};
      margin-bottom: 1rem;
    }
  `}
`;

const tagModifiers = {
  naogostei: (theme: DefaultTheme) => css`
    transform: translateX(-57%);
  `,
  gosteipouco: (theme: DefaultTheme) => css`
    transform: translateX(-20%);
  `,
  gostei: (theme: DefaultTheme) => css`
    transform: translateX(4%);
  `,
  gosteimuito: (theme: DefaultTheme) => css`
    transform: translateX(45%);
  `,
  amei: (theme: DefaultTheme) => css`
    transform: translateX(110%);
  `,
};

export type TagProps = {
  note: 'naogostei' | 'gosteipouco' | 'gostei' | 'gosteimuito' | 'amei';
};

export const Tag = styled.div<TagProps>`
  ${({ theme, note }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.secondBlack};

    p {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;

      &:before {
        content: '';
        display: block;
        position: fixed;
        top: -5px;
        left: 40%;
        width: 1rem;
        height: 1rem;
        transform: rotate(135deg);
        background-color: ${theme.colors.secondBlack};
      }
    }
    ${!!note && tagModifiers[note](theme)}
  `}
`;

export const Svg = styled(ReactSVG)`
  width: 3rem;
  height: 3rem;
  margin: 1rem;
`;

export const WrapperButton = styled.div`
  height: 40vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  ${media.greaterThan('small')`
    > button, a {
      width: 25%;
    }
  `}
`;

export const Details = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.maxwidthMobile};
    margin-top: 4rem;
  `}
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;

  ${Description} {
    text-align: left;
    font-size: 1.1rem;
    color: #494c4f;
    margin-left: 0;
  }
`;

export const Image = styled.img``;
