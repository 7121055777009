import { action } from 'typesafe-actions';
import { PerfumsDetails, PerfumsRanking, ReviewOrder, Shipping } from './types';

export function perfumsRequest(userId: string | null, journeyId: number) {
  return action('@ranking/RANKING_REQUEST', { userId, journeyId });
}

export function perfumsSuccess({ data }: { data: PerfumsRanking }) {
  return action('@ranking/RANKING_SUCCESS', { data });
}

export function perfumsFailure() {
  return action('@ranking/RANKING_FAILURE');
}

export function perfumsDetailsRequest(
  userId: string | null,
  journeyId: number,
  id: number
) {
  return action('@ranking/PERFUM_DETAILS_REQUEST', { userId, journeyId, id });
}

export function perfumsDetailsSuccess({ data }: { data: PerfumsDetails }) {
  return action('@ranking/PERFUM_DETAILS_SUCCESS', { data });
}

export function perfumsDetailsFailure() {
  return action('@ranking/PERFUM_DETAILS_FAILURE');
}

export function reviewOrderRequest(
  userId: string | null,
  journeyId: number,
  id: number
) {
  return action('@ranking/REVIEW_ORDER_REQUEST', { userId, journeyId, id });
}

export function reviewOrderSuccess({ data }: { data: ReviewOrder }) {
  return action('@ranking/REVIEW_ORDER_SUCCESS', { data });
}

export function reviewOrderFailure() {
  return action('@ranking/REVIEW_ORDER_FAILURE');
}

export function confirmPerfumRequest(
  id: number,
  data: Shipping,
  userId: string | null,
  journeyId: number
) {
  return action('@ranking/CONFIRM_ORDER_REQUEST', {
    id,
    data,
    userId,
    journeyId,
  });
}

export function confirmPerfumSuccess(responseConfirm: any) {
  return action('@ranking/CONFIRM_ORDER_SUCCESS', responseConfirm);
}

export function confirmPerfumFailure(error: any) {
  return action('@ranking/CONFIRM_ORDER_FAILURE', { error });
}

export function closeModalSuccess() {
  return action('@ranking/CLOSE_MODAL_SUCCESS');
}

export function closeModalFailure() {
  return action('@ranking/CLOSE_MODAL_FAILURE');
}

export function openModalKit() {
  return action('@ranking/OPEN_MODAL_KIT');
}

export function closeModalKit() {
  return action('@ranking/CLOSE_MODAL_KIT');
}
