import React, { useEffect, useState } from 'react';

import * as S from './styles';

type IModal = {
  children: React.ReactNode;
  open: boolean;
  closeModal: () => void;
};

export default function Modal({ children, open, closeModal }: IModal) {
  return (
    <S.Wrapper open={open}>
      <S.Main>
        <S.CloseButton onClick={closeModal}>X</S.CloseButton>
        {children}
      </S.Main>
    </S.Wrapper>
  );
}
