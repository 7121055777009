import { DashboardState, DashboardAction } from './types';

const initialState: DashboardState = {
  user: {
    User_name: '',
    Progress_journey: '0',
    journey_status: '',
  },
  product: {
    Product_type: '',
    Product_msg: '',
    Choice_avaliable: true,
    experience_full: false,
  },
  error: false,
  loading: false,
};

export default function dashboard(
  state = initialState,
  action: DashboardAction
): DashboardState {
  switch (action.type) {
    case '@dashboard/DASHBOARD_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@dashboard/DASHBOARD_SUCCESS':
      return {
        ...state,
        loading: false,
        user: action.payload.data.user,
        product: action.payload.data.product,
      };
    case '@dashboard/DASHBOARD_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
