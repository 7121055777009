import Footer from 'components/Footer';
import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import { useState } from 'react';
import Listen from './Listen';
import Read from './Read';
import * as S from './styles';
import Watch from './Watch';

function CuriosityFacts() {
  const [tab, setTab] = useState('read');

  return (
    <S.Wrapper>
      <Navbar />
      <NavBack title="curiosidade e fatos" link="/" />
      <S.Tabs>
        <S.Option active={tab === 'read'} onClick={() => setTab('read')}>
          LER
        </S.Option>
        <S.Option active={tab === 'watch'} onClick={() => setTab('watch')}>
          ASSISTIR
        </S.Option>
        <S.Option active={tab === 'listen'} onClick={() => setTab('listen')}>
          ESCUTAR
        </S.Option>
      </S.Tabs>
      <S.Content>
        {tab === 'read' && <Read />}
        {tab === 'watch' && <Watch />}
        {tab === 'listen' && <Listen />}
        <Footer />
      </S.Content>
    </S.Wrapper>
  );
}

export default CuriosityFacts;
