import Button from 'components/Button';
import Navbar from 'components/Navbar';
import * as S from './styles';

export default function SharePodiumSuccess() {
  return (
    <S.Wrapper>
      <Navbar />
      <S.Content>
        <S.Title>SALVO COM SUCESSO!</S.Title>
        <S.Description>
          A imagem do seu ranking foi salva no seu dispositivo para você
          compartilhar na rede social de sua preferência.
        </S.Description>

        <S.Box>
          <S.Description>
            Se você quiser aparecer nos nossos stories, é só marcar
            @amyi_perfumes no Instagram. ;)
          </S.Description>
        </S.Box>

        <Button name="OK" link to="/perfumes"></Button>
      </S.Content>
    </S.Wrapper>
  );
}
