import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import { historic } from 'services/historic';
import { ActionType } from 'typesafe-actions';

export function* getHistoricUser({
  payload,
}: ActionType<typeof actions.historicRequest>) {
  try {
    const { data } = yield call(
      historic.listAll,
      payload.userId,
      payload.journeyId
    );

    yield put(actions.historicSuccess(data));
  } catch (err) {
    yield put(actions.historicFailure());
  }
}

export default all([takeLatest('@historic/HISTORIC_REQUEST', getHistoricUser)]);
