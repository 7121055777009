import api from './api';
import { responseUser } from './../store/modules/olfactoryJourney/types';

const RESOURCE = '/perfums';

export const perfumes = {
  listAll: async (userId: string | null, journeyId: number) =>
    await api.get(RESOURCE + `?user_id=${userId}&journey_id=${journeyId}`),
  getQuestions: async (userId: string | null, journeyId: number, id: number) =>
    await api.get(
      RESOURCE +
        `/questions?user_id=${userId}&journey_id=${journeyId}&perfum_id=` +
        id
    ),
  responseUser: async ({ data, tags }: { data: responseUser; tags?: any }) =>
    await api.post(RESOURCE + `/questions`, { answers: data, tags }),
  listAllSecondNotes: async (
    userId: string | null,
    journeyId: number,
    perfums_ids: number[]
  ) =>
    await api.get(
      RESOURCE +
        '/second_rating?perfums_ids=' +
        perfums_ids.map((id) => id) +
        `&user_id=${userId}&journey_id=${journeyId}`
    ),
  shippingSecondNotes: async (second_notes: any) =>
    await api.post(RESOURCE + '/second_rating', { perfums: second_notes }),
  skinsTestListAll: async (
    userId: string | null,
    journeyId: number,
    perfum_id?: number
  ) =>
    await api.get(
      `${RESOURCE}/notification?user_id=${userId}&journey_id=${journeyId}&perfum_id=${perfum_id}`
    ),

  skinsTestSending: async (data: any) =>
    await api.post(`${RESOURCE}/notification`, data),
  listNotesBody: async (
    perfumsIds: number[],
    userId: string | null,
    journeyId: number
  ) =>
    await api.get(
      `${RESOURCE}/notes_body?perfums_ids=${perfumsIds.map(
        (item) => item + ','
      )}&user_id=${userId}&journey_id=${journeyId}`
    ),
};
