import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import StarsVote from 'components/StarsVote';
import * as S from './styles';
import checkCircle from '../../../assets/images/icones/check-circle-light.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  closeModalFinishSecondNotes,
  secondNotesRequest,
  shippingSecondNotesRequest,
} from 'store/modules/olfactoryJourney/actions';
import { StoreState } from 'store/createStore';
import { NotesFinallyContext } from 'components/StarsVote/notesFinallyContext';
import Modal from 'components/Modal';
import history from 'utils/history';
import LoadingRound from 'components/LoadingRound';
import SubNav from 'components/OlfactoryJourney/SubNav';

function SecondNotes() {
  const dispatch = useDispatch();

  const {
    second_notes,
    perfums_selected,
    modalFinishSecondNotes,
    loading,
  } = useSelector((state: StoreState) => state.journey);

  const { notesFinally } = useContext(NotesFinallyContext);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  useEffect(() => {
    dispatch(secondNotesRequest(userId, journeyId, perfums_selected));
  }, [perfums_selected, userId, journeyId, dispatch]);

  const handleSubmit = useCallback(() => {
    const perfums = {
      user_id: userId,
      journey_id: journeyId,
      question_id: '8',
      perfum_id: {
        0: notesFinally[0].perfum_id,
        1: notesFinally[1].perfum_id,
        2: notesFinally[2].perfum_id,
      },
      second_rating: {
        0: notesFinally[0].second_rating,
        1: notesFinally[1].second_rating,
        2: notesFinally[2].second_rating,
      },
    };

    dispatch(shippingSecondNotesRequest(perfums));
  }, [notesFinally, journeyId, userId, dispatch]);

  const closeModal = () => {
    dispatch(closeModalFinishSecondNotes());
  };

  const handleContinueFlux = () => {
    history.push('/notes/skintest');

    closeModal();
  };

  return (
    <S.Wrapper>
      <Modal open={modalFinishSecondNotes} closeModal={closeModal}>
        <S.WrapperModal>
          <S.Svg src={checkCircle} />
          <S.Title>Etapa concluída!</S.Title>
          <S.Description>
            Você viveu esse trio de perfumes com sucesso :) Parabéns!
          </S.Description>
          {loading ? (
            <LoadingRound />
          ) : (
            <Button name="CONTINUAR" onClick={() => handleContinueFlux()} />
          )}
        </S.WrapperModal>
      </Modal>

      <Navbar />
      <SubNav pass={4} />
      <S.Description>
        O perfume mudou.
        <br /> E a sua conexão com ele?
      </S.Description>
      <S.Info>Altere se necessário.</S.Info>

      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {second_notes.map((item) => (
            <S.Box key={item.perfum_id}>
              <S.Title>{item.perfum_name}</S.Title>
              <StarsVote info={item} />
            </S.Box>
          ))}
        </>
      )}

      <Button name="CONFIRMAR" onClick={handleSubmit} />
      <Footer />
    </S.Wrapper>
  );
}

export default SecondNotes;
