import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 100vh;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    max-width: 100%;
    width: ${theme.maxwidthMobile};

    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.greaterThan('small')`
      width: 100%;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    padding: 2rem 0;

    display: flex;
    align-items: center;
    flex-direction: column;

    > button,
    a {
      margin-top: 2rem;
      letter-spacing: 0.2rem;
    }
    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      height: 90vh;
      
      > button,
      a {
        width: 20%;
        margin-top: 0rem;
      }
    `}
  `}
`;

export const Image = styled.img`
  width: 6rem;
  max-width: 100%;
  padding: 2rem 0;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    max-width: 70%;
    margin: 0 auto;
    text-align: center;

    font-size: ${theme.font.sizes.extraLarge};
    padding-bottom: 4rem;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.5rem;
    font-weight: bold;

    ${media.greaterThan('small')`
      font-size: ${theme.font.sizes.xxextraLarge};
      padding-bottom: 0rem;
      margin-top: 2rem;
    `}
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    max-width: 80%;

    font-weight: medium;
    text-align: center;
    margin-bottom: 2rem;
    letter-spacing: 0.1rem;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.LoraRegular};
    line-height: 2rem;

    ${media.greaterThan('small')`
      padding-bottom: 0rem;
      margin-top: 2rem;
    `}
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  ${media.greaterThan('small')`
      margin-top: 2rem;

      > button, a {
        margin-top: 1.6rem;
      }
  `}
`;
