import { Button } from 'components/Button/styles';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 100vh;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    height: 100vh;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
    letter-spacing: 1px;

    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.small};
  `}
`;

export const DescriptionJourney = styled(Description)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    padding-top: 0;
    padding-bottom: 2rem;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondBlack};

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      max-width: 90%;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: bold;
    letter-spacing: 0.1rem;
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    width: 8rem;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    > a {
      font-weight: normal;
      border: 1px solid ${theme.colors.secondBlack};
      letter-spacing: 0.2rem;
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.2rem;
      max-width: 85%;
      text-align: center;
    }

    ${DescriptionJourney} {
      color: ${theme.colors.black};
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: 1.6rem;
      letter-spacing: 0.08em;
    }

    ${Title} {
      font-family: ${theme.font.family.LoraVariable};
      letter-spacing: 0.3rem;
      margin-bottom: 1.3rem;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};

      > button,a {
        width: 25%;
      }
    `}
  `}
`;

export const SectionPerfumes = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`;

export const Perfumes = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: 60rem;
    `}
  `}
`;

export const BoxPerfume = styled.div`
  ${({ theme }) => css`
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.colors.primary};

    ${Buttons} {
      gap: 0;
      margin-left: 4.3rem;
      margin-top: 2rem;

      a {
        width: 80%;
        font-size: 8px;
      }
    }

    ${media.greaterThan('small')`
      padding: 3rem;

      ${Buttons} {
        margin-left: 0;

        button {
         width: 13rem !important;
         height: 3rem;
        }
      }
    `}
  `}
`;

export const ContentBoxPerfume = styled.div<{ notFound?: boolean }>`
  ${({ notFound }) => css`
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    padding: 2rem 0;

    background: ${notFound && 'rgba(142, 142, 142, 0.2)'};
    box-shadow: ${notFound && '0px 1px 4px rgba(0, 0, 0, 0.11)'};
  `}
`;

export const Image = styled.img`
  max-width: 10rem;
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    ${Title} {
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.medium};
      font-family: ${theme.font.family.LoraVariable};
      letter-spacing: 0.3rem;
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      padding: 1rem 0 2rem 0;
      width: 100%;
    }
  `}
`;

export const Position = styled.h1`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: 3.6rem;
    font-family: ${theme.font.family.LoraVariable};
    letter-spacing: 0.1rem;
  `}
`;

export const Buttons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 1rem;

    > button,
    a {
      height: 3.5rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      width: 100%;
      font-size: 1.2rem;
    }

    button {
      font-family: ${theme.font.family.YaldeviMedium};
      background-color: #c3db43;
      height: 23px;
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      color: #0c0c0c;
    }
  `}
`;

export const Value = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.1rem;
    padding: 0 0 2rem 0;
  `}
`;

export const SectionInformation = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`;

export const Information = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Title} {
      font-size: ${theme.font.sizes.medium};
      color: ${theme.colors.black};
      font-family: ${theme.font.family.LoraVariable};
      font-weight: normal;
      letter-spacing: 0.2rem;
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      text-align: center;
      letter-spacing: 0.1rem;
      width: 90%;
    }

    > a {
      font-weight: normal;
      width: 60%;
      font-size: 1.4rem;
    }
  `}
`;

export const BoxRanking = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 120px);
    gap: 1rem;
    padding: 2rem 0;
    padding-top: 4rem;
    border-top: 1px solid ${theme.colors.secondGray};

    ${media.greaterThan('small')`
      grid-template-columns: repeat(3, 200px);
      gap: 0;
    `}
  `}
`;

type RankingProps = {
  notFound?: boolean;
};

export const Ranking = styled.div<RankingProps>`
  ${({ theme, notFound }) => css`
    background-color: ${notFound
      ? 'rgba(142, 142, 142, 0.2)'
      : theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);

    span {
      margin-left: 0.3rem;
    }

    &:nth-child(2) {
      transform: translateY(-2rem);
    }

    ${Title} {
      font-family: ${theme.font.family.LoraVariable};
      color: ${theme.colors.black};
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      padding-left: 1.5rem;
      margin-bottom: 0;
    }

    ${Buttons} {
      display: flex;
      flex-direction: column;

      > a {
        &:first-child {
          font-size: 0.9rem;
        }
      }

      > button {
        margin-top: 1rem;
        font-size: 0.9rem;
        height: 3rem;
      }
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      padding: 0;
      margin: 1rem 0 2rem 0;
      width: fit-content;
      max-width: fit-content;
      font-size: 9px;
      letter-spacing: 0.08em;
    }

    ${Image} {
      margin: 1rem 0;
    }

    ${Name} {
      font-family: ${theme.font.family.LoraVariable};
      font-size: 1.4rem;
      font-weight: 700;
      letter-spacing: 0.125em;
      margin-bottom: 0;
    }

    ${media.greaterThan('small')`
      width: 80%;
      margin: 0 auto;

      ${Name} {
        font-size: 1.6rem;
      }

      ${Description} {
        font-size: 1.2rem;
      }

      ${Buttons} {
        > button {
          br {
            display: none;
          }
        }

        ${NotFoundButton} {
          font-size: 1rem !important;
          height: 3rem !important;
        }
      }
    `}
  `}
`;

export const Name = styled.div``;

export const ContentPerfume = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BoxTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    width: 100%;

    span {
      margin-top: 0.5rem;
      margin-left: 0.2rem;
    }

    ${Title} {
      font-size: ${theme.font.sizes.medium};
      margin-top: 0.5rem;
    }

    ${Description} {
      margin: 1rem 0;
      padding: 0;
    }
  `}
`;

export const SharedResult = styled.div`
  ${({ theme }) => css`
    text-align: center;

    ${Title} {
      font-family: ${theme.font.family.LoraVariable};
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.125em;

      color: #404040;
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.08em;
      color: #494c4f;

      span {
        font-weight: 700;
      }
    }

    ${media.greaterThan('small')`
      ${Description} {
        width: 39.7rem;
        margin-bottom: 3rem;
      }
    `}
  `}
`;

export const BoxShare = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
    padding: 2rem 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);

    ${Title} {
      letter-spacing: 0.2rem;
      margin-bottom: 1.6rem;
      font-size: 21px;
      font-family: ${theme.font.family.LoraVariable};
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      padding: 0;
      text-align: center;
      margin: 0.2rem 0;
      font-size: 16px;
      letter-spacing: 0.1rem;
    }
  `}
`;

export const BoxHashTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  ${Description} {
    width: auto;
    margin: 0.2rem;
  }
`;

export const ShareButton = styled.button`
  ${({ theme }) => css`
    margin-top: 3rem;
    background-color: ${theme.colors.black};
    width: 100%;
    color: ${theme.colors.white};
    font-family: ${theme.font.family.YaldeviRegular};
    font-size: ${theme.font.sizes.medium};
    padding: 1rem 0;
    font-weight: normal;
    letter-spacing: 0.2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 1rem;
    }
  `}
`;

export const ModalMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      color: ${theme.colors.black};
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.3rem;
      width: 50%;
      margin: 0 auto;
      margin-top: 2rem;
      text-align: center;
      letter-spacing: 0.2rem;
    }

    ${Description} {
      color: ${theme.colors.black};
      font-family: ${theme.font.family.LoraRegular};
      max-width: 70%;
      text-align: center;
      margin: 0 auto;
      letter-spacing: 0.1rem;
      line-height: 2rem;
    }

    > button,
    a {
      font-weight: normal;
      font-size: 1.4rem;
      width: 70%;
      margin: 1rem 0 0 0;
    }
  `}
`;

export const ButtonsRanking = styled(Buttons)`
  ${({ theme }) => css`
    > button,
    a {
      height: 3.5rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      width: 100%;
      font-size: 1rem !important;

      &:last-child {
        height: 5rem;
      }
    }

    button {
      background-color: #c3db43;
      color: #0c0c0c;
      font-family: ${theme.font.family.YaldeviMedium};
      font-weight: 900;
    }
  `}
`;

export const ViewProductDetails = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.font.family.YaldeviMedium};
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    letter-spacing: 0.08em;
    text-decoration-line: underline;
    height: auto !important;

    color: #0c0c0c;
  `}
`;

export const NotFoundButton = styled.button`
  background: #494c4f !important;
  color: #ffffff !important;
  height: 40px !important;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DivisorArrow = styled.img`
  max-width: 100%;
  margin: 1.6rem;
`;
