import Navbar from 'components/Navbar';
import * as S from './styles';

import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import GraficPerfume from 'components/GraficPerfume';
import Button from 'components/Button';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import { perfumes } from 'services/journey';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import MediaMatch from 'components/MediaMatch';
import LoadingRound from 'components/LoadingRound';
import ArrowLeftIcon from 'assets/images/icones/arrow-left.svg';
import ArrowRightIcon from 'assets/images/icones/arrow-right.svg';
import SubNav from 'components/OlfactoryJourney/SubNav';

export type notesPerfumProps = {
  perfum_name: string;
  radar_desktop: string;
  radar_mobile: string;
};

function NotesPerfum() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([] as notesPerfumProps[]);
  const { perfums_selected } = useSelector(
    (state: StoreState) => state.journey
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const [tab, setTab] = useState(1);

  const nextTab = () => {
    if (tab < 3) {
      setTab((prevState) => ++prevState);
    }
  };

  const prevTab = () => {
    if (tab > 1) {
      setTab((prevState) => --prevState);
    }
  };

  async function getData() {
    setLoading(true);
    const { data } = await perfumes.listNotesBody(
      perfums_selected,
      userId,
      journeyId
    );
    setItems(data);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Navbar />
      <SubNav pass={4} />
      <S.Wrapper>
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <S.Title>Radar olfativo</S.Title>

            {/* <S.WrapperIframe>
            <S.Iframe
              src="https://player.vimeo.com/video/374423519"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></S.Iframe>
          </S.WrapperIframe> */}

            <S.Description>
              Veja abaixo a evolução das notas de cada perfume que você
              experimentou:
            </S.Description>

            <S.WrapperGrafic>
              <S.Tabs>
                <S.Option active={tab === 1} onClick={() => setTab(1)}>
                  {items[0]?.perfum_name}
                </S.Option>
                <S.Option active={tab === 2} onClick={() => setTab(2)}>
                  {items[1]?.perfum_name}
                </S.Option>
                <S.Option active={tab === 3} onClick={() => setTab(3)}>
                  {items[2]?.perfum_name}
                </S.Option>
              </S.Tabs>
              <S.Content>
                <S.Svg
                  src={ArrowLeftIcon}
                  onClick={prevTab}
                  disabled={tab === 1}
                />

                {tab === 1 && (
                  <>
                    <MediaMatch lessThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[0]?.radar_mobile
                        }
                      />
                    </MediaMatch>
                    <MediaMatch greaterThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[0]?.radar_desktop
                        }
                      />
                    </MediaMatch>
                  </>
                )}
                {tab === 2 && (
                  <>
                    <MediaMatch lessThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[1]?.radar_mobile
                        }
                      />
                    </MediaMatch>
                    <MediaMatch greaterThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[1]?.radar_desktop
                        }
                      />
                    </MediaMatch>
                  </>
                )}
                {tab === 3 && (
                  <>
                    <MediaMatch lessThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[2]?.radar_mobile
                        }
                      />
                    </MediaMatch>
                    <MediaMatch greaterThan="small">
                      <S.ImageGrafic
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          items[2]?.radar_desktop
                        }
                      />
                    </MediaMatch>
                  </>
                )}
                <S.Svg
                  src={ArrowRightIcon}
                  onClick={nextTab}
                  disabled={tab === 3}
                />
              </S.Content>
            </S.WrapperGrafic>
            <Button name="PRÓXIMO" link to="/notes/second" />
            <Footer />
          </>
        )}
      </S.Wrapper>
    </div>
  );
}

export default NotesPerfum;
