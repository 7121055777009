import axios from 'axios';
import { toast } from 'react-toastify';
import history from 'utils/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_AMYI,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      toast.error('Acesso negado, tente novamente.');
      document.location.href = 'https://amyi.co/login/';
      return Promise.reject(error);
    }
    if (error.response.status === 404) {
      toast.error(
        'Jornada não encontrada, selecione uma jornada e tente novamente'
      );
      history.push('/');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
