import { createGlobalStyle, css } from 'styled-components';
import EncodeSansLight from '../fonts/EncodeSans-Light.ttf';
import EncodeSansMedium from '../fonts/EncodeSans-Medium.ttf';
import LoraItalic from '../fonts/Lora-Italic-VariableFont_wght.ttf';
import LoraRegular from '../fonts/Lora-Regular.ttf';
import LoraVariable from '../fonts/Lora-VariableFont_wght.ttf';
import YaldeviMedium from '../fonts/yaldevi-colombo-medium.ttf';
import YaldeviSemiBold from '../fonts/yaldevi-colombo-semi-bold.ttf';
import YaldeviRegular from '../fonts/yaldevi.colombo-regular.ttf';

export default createGlobalStyle`
  
  @font-face {
    font-family: "EncodeSansLight";
    font-display: swap;
    src: url(${EncodeSansLight});
  }

  @font-face {
    font-family: "Encode-SansMedium";
    font-display: swap;
    src: url(${EncodeSansMedium});
  }

  @font-face {
    font-family: "LoraItalic";
    font-display: swap;
    src: url(${LoraItalic});
  }

  @font-face {
    font-family: "LoraRegular";
    font-display: swap;
    src: url(${LoraRegular});
  }

  @font-face {
    font-family: "LoraVariable";
    font-display: swap;
    src: url(${LoraVariable});
  }

  @font-face {
    font-family: "YaldeviMedium";
    font-display: swap;
    src: url(${YaldeviMedium});
  }

  @font-face {
    font-family: "YaldeviSemiBold";
    font-display: swap;
    src: url(${YaldeviSemiBold});
  }

  @font-face {
    font-family: "YaldeviRegular";
    font-display: swap;
    src: url(${YaldeviRegular});
  }

  * {
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }



  ${({ theme }) => css`
    html {
      scroll-behavior: smooth;
      font-size: 62.5%;
    }
    body,
    #root {
      font-family: 'Lora', serif;
      font-size: ${theme.font.sizes.medium};
      background: ${theme.colors.white};
    }

    h1,
    button,
    a {
      font-family: 'Encode Sans', sans-serif;
      font-weight: normal;
    }

    button {
      span,
      p {
        font-family: ${theme.font.family.YaldeviMedium}, serif;
      }
    }

    span,
    p,
    label,
    input {
      font-family: 'Lora', serif;
    }

    button {
      background: ${theme.colors.primary};
      border: none;
      border-radius: none;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }

    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    select,
    textarea {
      font-size: 16px;
      font-family: ${theme.font.family.LoraRegular};
    }
  `}

`;
