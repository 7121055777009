import * as S from './styles';
import fabio from '../../../assets/images/CuriosityFacts/fabio-navarro.jpg';
import { useEffect, useState } from 'react';
import formatMinutes from 'utils/formatMinutes';
import { useDispatch, useSelector } from 'react-redux';
import { videoRequest } from 'store/modules/curiosityFacts/actions';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import BoxPlayer from './Components/BoxPlayer';

function Watch() {
  const { videos, loading } = useSelector(
    (state: StoreState) => state.curiosityFacts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(videoRequest());
  }, []);

  return (
    <S.Wrapper>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {videos?.length && (
            <>
              {videos?.map((item) => (
                <BoxPlayer key={item.video_title} item={item} />
              ))}
            </>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

export default Watch;
