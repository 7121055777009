import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    height: 82vh;
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: 100%;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    height: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    ${media.greaterThan('small')`
      width: 100%;

      > button,
        a {
          width: 25%;
        }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: 2rem;
    margin: 3rem 0;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.2rem;
    font-weight: bold;
  `}
`;

export const Iframe = styled.iframe`
  margin: 2rem auto;

  ${media.greaterThan('small')`
    width: 600px;
    height: 330px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: 1.6rem;
    margin: 3rem 0;
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;
    width: 80%;

    ${media.greaterThan('small')`
      max-width: 70%;
    `}
  `}
`;
