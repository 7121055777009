import { JourneyState, JourneyAction } from './types';

const initialState: JourneyState = {
  Perfums: [],
  perfums_selected: [],
  questions: [],
  info_perfum: {
    perfum_name: '',
  },
  second_notes: [],
  error: false,
  loading: false,
  second_notes_user: [],
  modalFinishQuestions: false,
  step: 0,
  step_journey: 0,
  modalFinishSecondNotes: false,
  stepHistory: true,
  selectSkin: [],
  idSelectedSkin: 0,
};

export default function dashboard(
  state = initialState,
  action: JourneyAction
): JourneyState {
  switch (action.type) {
    case '@perfumes/PERFUMES_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case '@perfumes/PERFUMES_SUCCESS':
      return {
        ...state,
        loading: false,
        Perfums: action.payload.data.Perfums,
      };

    case '@perfumes/PERFUMES_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@perfumes/QUESTIONS_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case '@perfumes/QUESTIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        questions: action.payload.data.questions,
        info_perfum: action.payload.data.info_perfum,
      };

    case '@perfumes/QUESTIONS_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@perfumes/SECONDNOTES_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case '@perfumes/SECONDNOTES_SUCCESS':
      return {
        ...state,
        loading: false,
        second_notes: action.payload.data.second_notes,
      };

    case '@perfumes/SECONDNOTES_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@perfumes/SHIPPING_SECONDNOTES_REQUEST':
      return {
        ...state,
        loading: true,
        modalFinishSecondNotes: false,
      };

    case '@perfumes/SHIPPING_SECONDNOTES_SUCCESS':
      return {
        ...state,
        loading: false,
        modalFinishSecondNotes: true,
      };

    case '@perfumes/SHIPPING_SECONDNOTES_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
        modalFinishSecondNotes: false,
      };

    case '@perfumes/CLOSE_MODAL_SECONDNOTES':
      return {
        ...state,
        modalFinishSecondNotes: false,
      };

    case '@questions/RESPONSE_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case '@questions/RESPONSE_SUCCESS':
      return {
        ...state,
        step: state.step + 1,
        loading: false,
      };

    case '@questions/RESPONSE_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@perfumes/ADD_PERFUM':
      return {
        ...state,
        perfums_selected: action.payload.data,
      };

    case '@perfumes/RESET_STEP':
      return {
        ...state,
        step: 0,
      };

    case '@perfumes/NEXT_STEP':
      return {
        ...state,
        step: state.step + 1,
      };

    case '@perfumes/PREV_STEP':
      return {
        ...state,
        step: state.step - 1,
      };

    case '@perfumes/PROX_PERFUM':
      return {
        ...state,
        step_journey: state.step_journey + 1,
      };

    case '@perfumes/RESET_PERFUM':
      return {
        ...state,
        step_journey: 0,
      };

    case '@perfumes/OPEN_MODAL_FINISH':
      return {
        ...state,
        modalFinishQuestions: true,
      };

    case '@perfumes/CLOSE_MODAL_FINISH':
      return {
        ...state,
        modalFinishQuestions: false,
        step: 0,
      };

    case '@perfumes/HISTORY_JOURNEY_ACTIVE':
      return {
        ...state,
        stepHistory: true,
      };

    case '@perfumes/HISTORY_JOURNEY_DISABLED':
      return {
        ...state,
        stepHistory: false,
      };

    case '@perfumes/SKIN_TEST_REQUEST':
      return {
        ...state,
        loading: true,
      };

    case '@perfumes/SKIN_TEST_SUCCESS':
      return {
        ...state,
        selectSkin: action.payload,
        loading: false,
      };

    case '@perfumes/SKIN_TEST_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@perfumes/SELECTED_SKIN_TEST':
      return {
        ...state,
        idSelectedSkin: action.payload.id,
      };

    default:
      return state;
  }
}
