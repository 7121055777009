import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';
import Tooltip from 'react-simple-tooltip';
import informationIcon from '../../assets/images/icones/information-icon.svg';
import questionSvg from '../../assets/images/icones/question.svg';
import lightbuld from '../../assets/images/icones/lightbulb-on-light.svg';
import giftcard from '../../assets/images/icones/gift-card-light.svg';
import imageAmyi from '../../assets/images/home/amyi.png';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  creatingJourneyManualRequest,
  creatingJourneyRequest,
  getHomeDataRequest,
  selectJourneyId,
} from 'store/modules/account/actions';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import { Journey } from 'store/modules/account/types';
import history from 'utils/history';
import api from 'services/api';
import Input from 'components/InputText';
import NavBack from 'components/NavBack';
import { numberMask } from 'utils/numberMask';
import kitImage from 'assets/images/home/kit.png';
import InputText from 'components/InputText';
import { css } from 'styled-components';

export default function Index() {
  const [codStep, setCodStep] = useState(true);
  const [newJourney, setNewJourney] = useState(false);

  const [tab, setTab] = useState('journey');
  const [code, setCode] = useState('');
  const { journeys, loading, loadingCreatingManual } = useSelector(
    (store: StoreState) => store.account
  );

  const dispatch = useDispatch();
  const user_id = localStorage.getItem('user_id_amyi');

  useEffect(() => {
    dispatch(getHomeDataRequest(Number(user_id)));
  }, [user_id]);

  function handleInitJourney(item: Journey) {
    const data = {
      user_id,
      product_order_id: item.product_order_id,
      product_kit_id: item.product_kit_id,
    };

    dispatch(selectJourneyId(item.product_kit_journey_id));
    dispatch(creatingJourneyRequest(data));
  }

  function handleInitJourneyManual() {
    const data = {
      user_id,
      product_order_id: Number(code),
    };

    setCode('');
    dispatch(creatingJourneyManualRequest(data));
  }

  function handlePushDashboard(item: Journey) {
    dispatch(selectJourneyId(item.product_kit_journey_id));

    history.push('/dashboard');
  }

  return (
    <>
      <S.Wrapper>
        <Navbar />

        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <S.Header>
              <S.WrapperTitle>
                <S.Title>Olá, {journeys?.user_name}</S.Title>
                <S.LinkHeader to="/welcome">
                  <S.Svg src={informationIcon} />
                </S.LinkHeader>
              </S.WrapperTitle>
              <S.Description>
                Uma jornada olfativa espera por você!
                <br />
                Selecione a Experiência Amyi que você deseja viver agora.
              </S.Description>
            </S.Header>

            <S.Container>
              <S.Tabs>
                <S.Option
                  active={tab === 'journey'}
                  onClick={() => setTab('journey')}
                >
                  Minha Experiência Amyi
                </S.Option>
                {/* <S.Option
                  active={tab === 'club'}
                  onClick={() => setTab('club')}
                >
                  Clube amyi
                </S.Option> */}
              </S.Tabs>
              <S.Content>
                <>
                  {tab === 'journey' && (
                    <>
                      <div>
                        <S.Box>
                          <S.Image src={imageAmyi} />
                          <S.Infos>
                            <div>
                              <S.Title>INICIAR NOVA</S.Title>
                              <S.Description>
                                Insira o seu código para começar sua jornada.
                              </S.Description>
                            </div>

                            <S.CodeInsertWrapper>
                              <input
                                type="text"
                                placeholder="CÓDIGO DE ACESSO"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                              />
                              <Button
                                name="OK"
                                theme={code.length ? 'primary' : 'disabled'}
                                onClick={() => handleInitJourneyManual()}
                              />

                              <div className="info-tooltip">
                                <Tooltip
                                  customCss={S.CustomCssTooltip}
                                  arrow="0"
                                  placement="bottom"
                                  content="Você encontra o seu código de acesso na parte interna da tampa da caixa do seu kit de experimentação.  Em caso de dúvida, entre em contato pelo e-mail help@amyi.co"
                                >
                                  <S.Svg id="info-tooltip" src={questionSvg} />
                                </Tooltip>
                              </div>
                            </S.CodeInsertWrapper>
                          </S.Infos>
                        </S.Box>
                      </div>
                      {journeys?.journeys?.map((item) => (
                        <S.Box key={item.product_kit_journey_id}>
                          <S.Image
                            src={
                              process.env.REACT_APP_BASEURL_IMAGES +
                              item.product_kit_img
                            }
                          />
                          <S.Infos>
                            <S.Title>{item.product_kit_name}</S.Title>
                            {/* <S.Description>
                              {item.product_kit_msg}
                            </S.Description> */}
                            {item.product_kit_status === 'avaliable' && (
                              <Button
                                name="INICIAR JORNADA"
                                onClick={() => handleInitJourney(item)}
                              />
                            )}
                            {item.product_kit_status === 'pending' && (
                              <Button
                                name="CONTINUAR"
                                onClick={() => handlePushDashboard(item)}
                              />
                            )}
                            {item.product_kit_status === 'completed' && (
                              <Button
                                name="VER HISTÓRICO"
                                onClick={() => handlePushDashboard(item)}
                              />
                            )}
                          </S.Infos>
                        </S.Box>
                      ))}
                    </>
                  )}
                </>
                {tab === 'club' && (
                  <>
                    <S.BoxLink to="/clubamyi">
                      <S.Svg src={giftcard} />
                      <S.Infos>
                        <S.Title>Clube Amyi</S.Title>
                        <S.Description>
                          Você faz parte do nosso clube de benefícios e
                          vantagens especiais!
                        </S.Description>
                      </S.Infos>
                    </S.BoxLink>

                    <S.BoxLink to="/curiosityfacts">
                      <S.Svg src={lightbuld} />
                      <S.Infos>
                        <S.Title>Curiosidades e fatos</S.Title>
                        <S.Description>
                          Aprenda mais sobre o universo dos perfumes
                        </S.Description>
                      </S.Infos>
                    </S.BoxLink>
                  </>
                )}
              </S.Content>
              <Footer />
            </S.Container>
          </>
        )}
      </S.Wrapper>
    </>
  );
}
