import amyi_210 from 'assets/images/perfums_ranking/amyi_210.png';
import amyi_211 from 'assets/images/perfums_ranking/amyi_211.png';
import amyi_212 from 'assets/images/perfums_ranking/amyi_212.png';
import amyi_213 from 'assets/images/perfums_ranking/amyi_213.png';
import amyi_214 from 'assets/images/perfums_ranking/amyi_214.png';
import amyi_215 from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_i from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_ii from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_iii from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_iv from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_ix from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_v from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_vi from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_vii from 'assets/images/perfums_ranking/amyi_215.png';
import amyi_viii from 'assets/images/perfums_ranking/amyi_215.png';

export type nameImages =
  | 'amyi_210'
  | 'amyi_211'
  | 'amyi_212'
  | 'amyi_213'
  | 'amyi_214'
  | 'amyi_215'
  | 'amyi_i'
  | 'amyi_ii'
  | 'amyi_iii'
  | 'amyi_iv'
  | 'amyi_ix'
  | 'amyi_v'
  | 'amyi_vi'
  | 'amyi_vii'
  | 'amyi_viii';

export default function renderImageToPrint(name: nameImages) {
  const images = {
    amyi_210: amyi_210,
    amyi_211: amyi_211,
    amyi_212: amyi_212,
    amyi_213: amyi_213,
    amyi_214: amyi_214,
    amyi_215: amyi_215,
    amyi_i: amyi_i,
    amyi_ii: amyi_ii,
    amyi_iii: amyi_iii,
    amyi_iv: amyi_iv,
    amyi_ix: amyi_ix,
    amyi_v: amyi_v,
    amyi_vi: amyi_vi,
    amyi_vii: amyi_vii,
    amyi_viii: amyi_viii,
  };

  return images[name] || '';
}
