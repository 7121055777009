/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as S from './styles';

type InputProps = {
  label: string;
  type: string;
  required?: boolean;
  name: string;
};

const InputText = ({ label, type, required, name, ...rest }: InputProps) => {
  return (
    <S.Container>
      <S.Input
        name={name}
        required={required && required}
        type={type}
        {...rest}
        placeholder="nao remove esse place"
      />
      <S.Label>
        <span className="content__name">{label}</span>
      </S.Label>
    </S.Container>
  );
};

export default InputText;
