import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    height: 77vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-align: center;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraVariable};
    font-weight: medium;
    width: 85%;

    margin: 2rem 0;

    &:last-child {
      width: 85%;
    }
  `}
`;

export const Iframe = styled.iframe`
  margin: 0 auto;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.LoraRegular};

    width: 80%;
    text-align: center;
    /* margin-top: 2rem; */
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 90%;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > button {
      width: 100%;
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
    }

    ${Title} {
      margin: 0;
      font-size: ${theme.font.sizes.medium};
      text-align: left;
      width: 100%;
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      width: auto;
      text-align: left;
      margin: 1rem 0;
    }
  `}
`;

export const WrapperSelect = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.primary};
    border: none;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    color: ${theme.colors.secondBlack};

    display: flex;
    align-items: center;
  `}
`;

export const Select = styled.select`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.primary};
    border: none;
    padding-right: 20px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 5px;
    font-family: ${theme.font.family.YaldeviRegular};
    color: ${theme.colors.secondBlack};
  `}
`;
export const Option = styled.option``;

export const Time = styled.div`
  ${({ theme }) => css`
    width: 100%;
    ${Title} {
      color: ${theme.colors.secondBlack};
      font-size: ${theme.font.sizes.xsmall};
      letter-spacing: 0.1rem;
      font-weight: normal;
      text-align: left;
      width: 100%;

      span {
        color: ${theme.colors.secondOrange};
      }
    }
  `}
`;

export const BarProgress = styled.div`
  ${({ theme }) => css`
    height: 2.5rem;
    width: 100%;
    background-color: ${theme.colors.threeGray};
    margin-bottom: 1rem;

    &:before {
      content: '';
      display: block;
      z-index: 99;
      top: 0;
      left: 0;
      width: 4%;
      height: 2.5rem;
      background-color: ${theme.colors.orange};
    }
  `}
`;

export const LegendBar = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -0.8rem;
  `}
`;

export const BreakPoints = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondGray};
    font-weight: normal;
    font-size: 1rem;
    margin-right: 10.5px;
    transform: translateY(2.5rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      display: block;
      z-index: 99;
      margin-top: 0.2rem;
      width: 0.1rem;
      height: 3.5rem;
      border-left: 1px dotted ${theme.colors.secondGray};
    }
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    width: 1.5rem;
    margin-left: 0.3rem;
  `}
`;
