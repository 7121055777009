import styled, { css } from 'styled-components';
import {
  CircularInput,
  CircularTrack,
  CircularThumb,
  CircularProgress,
} from 'react-circular-input';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Colors = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.2rem;
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      text-align: center;
      margin: 0;
      margin-bottom: 0rem;
      font-family: ${theme.font.family.LoraVariable};
      font-weight: medium;
      &:after,
      &:before {
        display: none;
      }
    }

    .reinvented-color-wheel--sv-space {
      display: none;
    }

    .reinvented-color-wheel--sv-handle {
      display: none;
    }

    .reinvented-color-wheel--hue-handle,
    .reinvented-color-wheel--sv-handle {
      height: 32px !important;
      width: 32px !important;
      margin-top: -16px !important;
      margin-left: -16px !important;
      background-color: white;
      box-shadow: 0px 0px 8px #00000033;
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Image = styled.img`
  width: 20rem;
`;

export const StyledCircularInput = styled(CircularInput)`
  stroke-width: 1;
  transform: translateY(-20rem);
`;

export const StyledCircularTrack = styled(CircularTrack)`
  stroke-width: 10;
`;

export const StyledCircularThumb = styled(CircularThumb)`
  ${({ theme }) => css`
    r: 15;
    fill: white;
    stroke: ${theme.colors.primary};
  `}
`;

export const StyledCircularProgress = styled(CircularProgress)`
  stroke-width: 10;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

type OptionColor = {
  color?: string;
  img?: string;
};

export const Option = styled.div<OptionColor>`
  ${({ theme, color, img }) => css`
    margin: 0 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: ${color};
    border: 1px solid ${theme.colors.primary};

    background-image: url(${img});
    background-size: cover;
  `}
`;

export const AddColor = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #838384;

    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #838384;
    margin-right: 1rem;
  `}
`;

export const OptionCircle = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    ${Option} {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 13rem;
      height: 13rem;
    }

    ${media.greaterThan('small')`
      margin: 3rem 0;
    `}
  `}
`;

export const Information = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
    margin: 1rem 0;
    text-align: center;
    margin-top: 2%;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    text-align: center;
    font-family: ${theme.font.family.YaldeviRegular};
    height: 2rem;
    font-size: 16px;

    ${media.greaterThan('small')`
      margin: 2rem 0;
    `}
  `}
`;
