import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';

import { FiCheck } from 'react-icons/fi';

import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';

type CarouselProps = {
  data: Question;
};

type selectedProps = {
  url: string;
  legend: string;
};

const CarouselCheck = ({ data }: CarouselProps) => {
  const [selected, setSelected] = useState<selectedProps[]>([]);
  const [answersUser, setAnswersUser] = useState<any>([]);
  const { perfum_name, perfum_id } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { loading } = useSelector((state: StoreState) => state.journey);
  const dispatch = useDispatch();
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');
  const [otherOption, setOtherOption] = useState('');

  useEffect(() => {
    setAnswersUser(data.answers_user);
    setSelected(answersUser);
  }, []);

  function handleStep() {
    const answersUser = otherOption.length
      ? [...selected.map((item) => item.legend), otherOption]
      : [...selected.map((item) => item.legend)];

    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: answersUser,
      answers_url: selected.map((item) => item.url),
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  function handleSelectedItem(url: string, legend: string) {
    const alredySelected = selected.findIndex((item) => item.url === url);

    if (alredySelected >= 0) {
      const filteredItems = selected.filter((item) => item.url !== url);
      setSelected(filteredItems);
    } else {
      setSelected([...selected, { url, legend }]);
    }
  }

  // Settings Slide
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '80px',
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <S.StepCarrousel>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data.question_id} />
          <S.Description>{data.title}</S.Description>

          <S.Information>Selecione quantas alternativas quiser</S.Information>

          <S.WrapperCarousel>
            {answersUser.length > 0 ? (
              <Slider {...settings}>
                {data.answers_avaliable.map((item) => (
                  <>
                    <div>
                      <S.Image
                        src={process.env.REACT_APP_BASEURL_IMAGES + item.url}
                        active={answersUser.findIndex(
                          (select: any) => select.answer_url === item.url
                        )}
                      />

                      {answersUser.findIndex(
                        (select: any) => select.answer_url === item.url
                      ) !== -1 && (
                        <>
                          <S.Confirmed className="confirmed">
                            <FiCheck size={100} />
                          </S.Confirmed>
                        </>
                      )}
                    </div>
                    <S.Legend>{item.legend}</S.Legend>
                    <S.Options>
                      {answersUser.map((option: any) => (
                        <S.Option
                          key={option.legend}
                          img={
                            process.env.REACT_APP_BASEURL_IMAGES +
                            option.answer_url
                          }
                        ></S.Option>
                      ))}
                      <S.AddColor
                        onClick={() =>
                          handleSelectedItem(item.url, item.legend)
                        }
                      >
                        +
                      </S.AddColor>
                    </S.Options>
                  </>
                ))}
              </Slider>
            ) : (
              <Slider {...settings}>
                {data.answers_avaliable.map((item) => (
                  <>
                    <div>
                      <S.Image
                        src={process.env.REACT_APP_BASEURL_IMAGES + item.url}
                        onClick={() =>
                          handleSelectedItem(item.url, item.legend)
                        }
                        active={selected.findIndex(
                          (select) => select.url === item.url
                        )}
                      />

                      {selected.findIndex(
                        (select) => select.url === item.url
                      ) !== -1 && (
                        <>
                          <S.Confirmed
                            className="confirmed"
                            onClick={() =>
                              handleSelectedItem(item.url, item.legend)
                            }
                          >
                            <FiCheck size={100} />
                          </S.Confirmed>
                        </>
                      )}
                    </div>
                    <S.Legend>{item.legend}</S.Legend>
                    <S.Options>
                      {selected.map((option) => (
                        <S.Option
                          key={option.legend}
                          img={
                            process.env.REACT_APP_BASEURL_IMAGES + option.url
                          }
                        ></S.Option>
                      ))}
                      {/* <S.AddColor
                        onClick={() =>
                          handleSelectedItem(item.url, item.legend)
                        }
                      >
                        +
                      </S.AddColor> */}

                      <S.Input
                        placeholder="outro"
                        value={otherOption}
                        onChange={(e) => setOtherOption(e.target.value)}
                      />
                    </S.Options>
                  </>
                ))}
              </Slider>
            )}
          </S.WrapperCarousel>
        </>
      )}
      {selected.length >= 1 ||
      answersUser.length >= 1 ||
      otherOption.length >= 1 ? (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => handleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => handleStep()} />
          )}
        </>
      ) : (
        <Button name="ENVIAR" theme="disabled" />
      )}
    </S.StepCarrousel>
  );
};

export default CarouselCheck;
