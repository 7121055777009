import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.threeGray};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.xlarge};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const TitleTotal = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.LoraRegular};
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    margin: 2rem 0;
    font-size: ${theme.font.sizes.small};

    &:first-child,
    &:last-child {
      margin: 0;
    }
  `}
`;

export const Closed = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    text-align: right;
    margin: 1rem 1rem 0 0;
  }
`;

export const Details = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    box-shadow: 0px 0px 8px #00000029;
    padding: 2rem;
    margin-bottom: ${theme.spacings.small};

    ${Title} {
      font-size: ${theme.font.sizes.small};
      text-align: left;
      margin: 0;

      span {
        display: block;
        color: ${theme.colors.black};
        font-size: ${theme.font.sizes.small};
        font-family: ${theme.font.family.YaldeviRegular};
        font-weight: normal;
      }
    }

    ${TitleTotal} {
      font-size: ${theme.font.sizes.small};
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.2rem;
      font-weight: bold;
      text-align: left;
      margin: 0;
      color: ${theme.colors.green};
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.colors.gray};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    ${Title} {
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.2rem;
    }
  `}
`;

export const Value = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    font-size: ${theme.font.sizes.small};
  `}
`;

export const ValueTotal = styled.p`
  ${({ theme }) => css`
    letter-spacing: 0.1rem;
    color: ${theme.colors.green};
    font-weight: bold;
    font-size: ${theme.font.sizes.medium};
  `}
`;

export const BoxDescription = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    box-shadow: 0px 0px 8px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    padding: 1.5rem;
    width: 100%;
  `}
`;

export const ItemBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid ${theme.colors.gray};
    width: 100%;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    ${Title} {
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.2rem;
      display: block;
      font-family: ${theme.font.family.LoraRegular};

      > span {
        font-weight: 200;
        display: block;
        font-family: ${theme.font.family.YaldeviRegular};
        font-size: ${theme.font.sizes.small};
        text-align: left;
        color: ${theme.colors.secondBlack};
        letter-spacing: 0.1rem;
      }
    }
  `}
`;

export const Row = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    ${Title} {
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.1rem;
      display: block;
      text-align: left;

      > span {
        display: block;
        font-size: ${theme.font.sizes.xsmall};
        text-align: left;
        font-weight: 200;
        color: ${theme.colors.secondBlack};
        letter-spacing: 0.1rem;
      }

      &:after {
        content: '';
        display: block;
        width: 80%;
        height: 0.1rem;
        margin-top: 1rem;
        background-color: ${theme.colors.gray};
      }
    }
  `}
`;
