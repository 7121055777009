import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    margin-top: 1rem;

    ${media.greaterThan('small')`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
  `}
`;

export const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
`;

export const Avatar = styled.img`
  width: 3rem;
  border-radius: 50%;
`;

export const Name = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.3rem;
    font-family: ${theme.font.family.YaldeviMedium};
    font-weight: normal;
    margin-left: 1rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    letter-spacing: 0.1em;
    padding: 2rem 1.5rem;
    padding-top: 0;
    font-size: ${theme.font.sizes.small};
    line-height: 2rem;
    font-family: ${theme.font.family.LoraVariable};
    font-weight: normal;
  `}
`;

export const Player = styled(ReactPlayer)`
  width: 36rem;
  margin: 0 auto;

  ${media.greaterThan('small')`
    width: 60% !important;
    height: 450px !important;
  `}
`;

export const InfoPlayer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;

  ${media.greaterThan('small')`
    width: 60%;
  `}
`;

export const InitialPlayer = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraVariable};
  `}
`;
export const DurationPlayer = styled(InitialPlayer)``;
