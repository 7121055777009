import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    overflow: hidden;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.gray};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 2rem;
    font-family: ${theme.font.family.LoraRegular};
    text-align: center;
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: bold;
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-family: ${theme.font.family.LoraRegular};
    width: 80%;
    line-height: 2.5rem;
    margin: 2rem auto;
    letter-spacing: 0.1rem;
    margin-bottom: 4rem;
  `}
`;

export const Links = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    display: block;
    width: 80%;
    text-align: center;
    padding: 1rem 3rem;
    border: 1px solid ${theme.colors.secondBlack};
    margin: 2rem auto;
    letter-spacing: 0.1rem;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.YaldeviRegular};
  `}
`;
