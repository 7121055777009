import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import img1 from '../../../assets/images/olfactory/2-escrever.png';
import img2 from '../../../assets/images/olfactory/3-mergulhar.png';
import img3 from '../../../assets/images/olfactory/4-cheirar.png';
import img4 from '../../../assets/images/olfactory/5-dobrar.png';
import img5 from '../../../assets/images/olfactory/6-responder.png';
import banner from '../../../assets/images/olfactory/1-fitas.png';
import { useState } from 'react';
import { prevStep } from 'store/modules/olfactoryJourney/actions';

export default function GuideOlfactory() {
  const [introduction, setIntroduction] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  function next() {
    if (currentSlide < 3) {
      setCurrentSlide((oldState) => ++oldState);
    }
  }

  function prev() {
    if (currentSlide > 0) {
      setCurrentSlide((oldState) => --oldState);
    }

    if (currentSlide === 0) {
      setIntroduction(true);
    }
  }

  function updateCurrentSlide(index: number) {
    const slide = currentSlide;

    if (slide !== index) {
      setCurrentSlide(index);
    }
  }

  return (
    <>
      <S.Wrapper>
        <Navbar />
        {introduction ? (
          <S.Introduction>
            <S.Title>Usando as fitas olfativas</S.Title>
            <S.Image src={banner} />
            <S.Description width="277px">
              Antes de iniciar, entenda como manusear as fitas olfativas.
            </S.Description>
            <S.WrapperButton>
              <Button name="PRÓXIMO" onClick={() => setIntroduction(false)} />
              <S.GuideLink to="/olfactory-journey/primary">PULAR</S.GuideLink>
            </S.WrapperButton>
          </S.Introduction>
        ) : (
          <S.Container>
            <Carousel
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              useKeyboardArrows={false}
              autoPlay={false}
              infiniteLoop={false}
              emulateTouch={false}
            >
              <div>
                <S.Image className="img1" src={img1} />
                <S.Description width="277px">
                  Anote o número do perfume e aplique-o na fita olfativa.
                </S.Description>
              </div>
              <div>
                <S.Image className="img2" src={img2} />
                <S.Description width="330px">
                  Leve a fita ao nariz. Perceba os cheiros e sinta as primeiras
                  impressões.
                </S.Description>
              </div>
              <div>
                <S.Image className="img3" src={img3} />
                <S.Description width="233px">
                  Dobre a fita para que o perfume não encoste na mesa.
                </S.Description>
              </div>
              <div>
                <S.Image className="img4" src={img4} />
                <S.Description width="293px">
                  Com a fragrância em mente e olfato com você, responda as
                  perguntas.
                </S.Description>
              </div>
            </Carousel>
            {currentSlide === 3 ? (
              <S.WrapperButton>
                <Button name="ENTENDI" link to="/olfactory-journey/primary" />
                <Button
                  name="VOLTAR"
                  theme="secondary"
                  onClick={() => prev()}
                />
              </S.WrapperButton>
            ) : (
              <S.WrapperButton>
                <Button name="PRÓXIMO" onClick={() => next()} />
                <Button
                  name="VOLTAR"
                  theme="secondary"
                  onClick={() => prev()}
                />
              </S.WrapperButton>
            )}
          </S.Container>
        )}
        <Footer />
      </S.Wrapper>
    </>
  );
}
