import { createStore, applyMiddleware, Middleware, Reducer } from 'redux';
import { AccountAction, AccountState } from './modules/account/types';

import { AuthAction, AuthState } from './modules/auth/types';
import { DashboardAction, DashboardState } from './modules/dashboard/types';
import { HistoricActions, HistoricState } from './modules/historic/types';
import { JourneyAction, JourneyState } from './modules/olfactoryJourney/types';
import { PerfumsAction, PerfumsState } from './modules/perfums/types';
import {
  CuriosityState,
  CuriosityAction,
} from './modules/curiosityFacts/types';

export interface StoreState {
  auth: AuthState;
  dashboard: DashboardState;
  journey: JourneyState;
  historic: HistoricState;
  perfums: PerfumsState;
  account: AccountState;
  curiosityFacts: CuriosityState;
}

export type StoreAction =
  | AuthAction
  | DashboardAction
  | JourneyAction
  | HistoricActions
  | PerfumsAction
  | AccountAction
  | CuriosityAction;

export default (
  reducers: Reducer<StoreState, StoreAction>,
  middlewares: Middleware[]
) => {
  const enhancer = applyMiddleware(...middlewares);

  return createStore(reducers, enhancer);
};
