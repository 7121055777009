import { ReactSVG } from 'react-svg';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      max-width: 100%;

      > button,a  {
        max-width: 30%;
      }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 2.8rem;
    font-family: ${theme.font.family.LoraRegular};
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin: 2rem 0;
  `}
`;

export const WrapperIframe = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Iframe = styled.iframe`
  ${media.greaterThan('small')`
    width: 70%;
    height: 400px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.2rem;
    font-size: ${theme.font.sizes.small};
    text-align: center;
    color: ${theme.colors.black};
    font-weight: medium;
    margin: 2rem auto;
    width: 46rem;

    ${media.lessThan('small')`
      width: 30rem;
      font-size: 12px;
      letter-spacing: 0.1rem;
    `}
  `}
`;

export const WrapperGrafic = styled.div`
  margin: 0 auto;
  margin-bottom: 4rem;

  ${media.greaterThan('small')`
    max-width: 80%;
  `}
`;

export const ImageGrafic = styled.img`
  max-width: 100%;

  ${media.greaterThan('small')`
    max-width: 50%;
    margin: 0 auto;
    display: block;
  `}
`;

export const Controls = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;

    ${Title} {
      font-size: ${theme.font.sizes.extraLarge};
    }
  `}
`;

export const BackArrow = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 50%;

    svg {
      color: ${theme.colors.secondBlack};
    }
  `}
`;

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    background-color: #c3db43;
    font-weight: bold;
    border: 2px solid #000;
    font-weight: 700;
  `,
};

export const Tabs = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.white};
    width: 60%;
    margin: 2rem auto;

    ${media.lessThan('small')`
      width: 100%;
      gap: 2rem;
    `}
  `}
`;

type OptionProps = {
  active?: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ theme, active }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.2rem;
    text-align: center;
    font-family: ${theme.font.family.YaldeviMedium};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 5rem;
    border: 1px solid #494c4f;

    ${media.lessThan('small')`
      height: 3rem;
    `}

    ${!!active && wrapperModifiers.active(theme)}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1rem 0;
    min-height: 40vh;
    display: flex;
    align-items: center;
  `}
`;

export const Svg = styled(ReactSVG)<{ disabled: boolean }>`
  ${({ disabled }) => css`
    path {
      stroke: ${disabled ? 'grey' : 'black'};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }

    ${media.lessThan('small')`
      svg {
        width: 35px;
        height: 35px; 
      }
    `}
  `}
`;
