import { combineReducers } from 'redux';

import { StoreState } from '../createStore';

import auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import journey from './olfactoryJourney/reducer';
import historic from './historic/reducer';
import perfums from './perfums/reducer';
import account from './account/reducer';
import curiosityFacts from './curiosityFacts/reducer';

export default combineReducers<StoreState>({
  auth,
  dashboard,
  journey,
  historic,
  perfums,
  account,
  curiosityFacts,
});
