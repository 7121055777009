import * as S from './styles';
import { useSelector, useDispatch } from 'react-redux';

import capa from '../../../assets/images/CuriosityFacts/capa-sound.jpg';
import playCircle from '../../../assets/images/icones/play-circle-solid.svg';

import { MdClose } from 'react-icons/md';
import { StoreState } from 'store/createStore';
import { useEffect } from 'react';
import { audioRequest } from 'store/modules/curiosityFacts/actions';

function Listen() {
  const { audios, loading } = useSelector(
    (state: StoreState) => state.curiosityFacts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(audioRequest());
  }, []);

  return (
    <S.Wrapper>
      {audios?.map((item) => (
        <div
          key={item.url}
          dangerouslySetInnerHTML={{ __html: item.url }}
        ></div>
      ))}

      {/* <S.Header>
        <S.Box>
          <S.Image src={capa} />
          <S.BoxTitle>
            <S.Title>O Sagrado mundo da Perfumaria</S.Title>
            <S.Description>Entre Amigos e Perfumes</S.Description>
          </S.BoxTitle>
        </S.Box>
        <S.Info>
          O episódio de número 1 é uma Ode ao Sagrado da perfumaria. Uma visão
          celestial de Baruch de Spinoza.
        </S.Info>
        <S.Controller>
          <S.Button>PAUSAR</S.Button>
          <S.BoxProgress>
            <S.Progress porcent={46} />
            <S.Description>1 min restante(s)</S.Description>
          </S.BoxProgress>
        </S.Controller>
      </S.Header>

      <S.List>
        <S.Image src={capa} />
        <S.BoxInfo>
          <S.Title>O mundo dos perfumes</S.Title>
          <S.Description>O gosto e a memória olfativa</S.Description>
          <S.PlayerBox>
            <S.Svg src={playCircle} />
            <S.Title>1 min restante(s)</S.Title>
          </S.PlayerBox>
        </S.BoxInfo>
      </S.List>

      <S.List>
        <S.Image src={capa} />
        <S.BoxInfo>
          <S.Title>Karen Bachini</S.Title>
          <S.Description>Perfumes!</S.Description>
          <S.PlayerBox>
            <S.Svg src={playCircle} />
            <S.Title>1 min restante(s)</S.Title>
          </S.PlayerBox>
        </S.BoxInfo>
      </S.List>

      <S.List>
        <S.Image src={capa} />
        <S.BoxInfo>
          <S.Title>Naturacast</S.Title>
          <S.Description>O gosto e a memória olfativa</S.Description>
          <S.PlayerBox>
            <S.Svg src={playCircle} />
            <S.Title>1 min restante(s)</S.Title>
          </S.PlayerBox>
        </S.BoxInfo>
      </S.List>

      <S.PlayContainer>
        <S.Progress porcent={50} />
        <S.PlayController>
          <S.Svg src={playCircle} />
          <S.Title>Now Playing: O Sagrado mundo da...</S.Title>
          <MdClose size={20} />
        </S.PlayController>
      </S.PlayContainer> */}
    </S.Wrapper>
  );
}

export default Listen;
