import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div``;

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      max-width: 80%;

      > button,a  {
        max-width: 190px;
      }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraVariable};
    font-size: 2.1rem;
    letter-spacing: 0.2rem;
    text-align: center;
    font-weight: bold;
    margin: 1rem 0;

    ${media.greaterThan('small')`
      font-size: 2.8rem;
    `}
  `}
`;

export const WrapperIframe = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Iframe = styled.iframe`
  ${media.greaterThan('small')`
    width: 50%;
    height: 400px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    font-size: ${theme.font.sizes.small};
    text-align: center;
    color: ${theme.colors.black};
    font-weight: medium;
    margin: 2rem auto;
    max-width: 45rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;

    ${media.lessThan('small')`
      max-width: 30rem;
    `}
  `}
`;

export const WrapperGrafic = styled.div`
  margin-bottom: 4rem;
`;

export const ImageGrafic = styled.img`
  max-width: 100%;

  ${media.greaterThan('small')`
    max-width: 50%;
    margin: 0 auto;
    display: block;
  `}
`;

export const Controls = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;

    ${Title} {
      font-size: ${theme.font.sizes.extraLarge};
    }
  `}
`;

export const BackArrow = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 50%;

    svg {
      color: ${theme.colors.secondBlack};
    }
  `}
`;

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      transform: translateY(5px);
      width: 8rem;
      height: 0.2rem;
      background-color: ${theme.colors.black};
      border-radius: 1rem;
      animation: hoverAnimation 0.2s forwards;
    }

    &:first-child {
      &:after {
        width: 6rem;
      }
    }

    @keyframes hoverAnimation {
      from {
        width: 0;
        left: 50%;
      }
      to {
        width: 8rem;
        left: 0;
      }
    }
  `,
};

export const Tabs = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.white};
    margin-top: 2rem;
  `}
`;

type OptionProps = {
  active?: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ theme, active }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: ${theme.font.family.YaldeviMedium};
    cursor: pointer;
    flex: 1;

    ${!!active && wrapperModifiers.active(theme)}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1rem 0;

    > a,
    button {
      margin-top: 5rem;
    }

    ${media.greaterThan('small')`
      > a,
      button {
        margin-top: 0;
      }
    `}
  `}
`;
