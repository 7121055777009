import { ProgressCircleProps } from './index';
import styled, { css } from 'styled-components';

export const Wrapper = styled.main<ProgressCircleProps>`
  ${({ theme, position }) => css`
    display: flex;

    span {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${theme.colors.gray};
      margin: 1rem 0.2rem;

      &:nth-child(${position}) {
        background-color: ${theme.colors.black};
      }
    }
  `}
`;
