import styled, { css } from 'styled-components';
import {
  CircularInput,
  CircularTrack,
  CircularThumb,
  CircularProgress,
} from 'react-circular-input';
import { ReactSVG } from 'react-svg';
import Modal from 'components/Modal';
import media from 'styled-media-query';

type ContentProps = {
  bg?: string;
};

export const Wrapper = styled.main``;

export const Container = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    ${media.greaterThan('small')`
      width: 100%;
    `}
  `}
`;

export const Content = styled.div<ContentProps>`
  ${({ theme, bg }) => css`
    background-image: url(${bg});
    background-size: 25%;
    background-position-x: right;
    background-repeat: no-repeat;
    background-color: ${theme.colors.primary};
    width: 100%;
    height: 80vh;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${media.greaterThan('small')`
      height: 83vh;
      background-size: 10%;
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.YaldeviSemiBold};
    font-weight: normal;
  `}
`;

type NumberProps = {
  number: string;
};

export const Number = styled.h1<NumberProps>`
  ${({ theme, number }) => css`
    color: #000;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: '${number}';
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${theme.font.family.LoraVariable};

      width: 5rem;
      height: 5rem;
      border-radius: 2.5rem;
      background-color: #c3db43;
    }
  `}
`;

export const ProductName = styled.h1`
  ${({ theme }) => css`
    font-weight: bold;
    letter-spacing: 0.3rem;
    text-align: center;
    color: ${theme.colors.black};

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${theme.font.family.LoraVariable};

    &:after {
      content: '';
      display: block;
      margin-top: 1rem;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Author = styled.h2`
  ${({ theme }) => css`
    text-align: center;
    margin-top: ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraVariable};
    font-weight: normal;
  `}
`;

export const Image = styled.img`
  height: 7rem;
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;
    font-family: ${theme.font.family.YaldeviRegular};

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 0.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.gray};
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const Top = styled.div``;
export const Middle = styled.div``;
export const Bottom = styled.div`
  ${({ theme }) => css`
    width: 100%;

    > button,
    a {
      margin-top: 1rem;
      font-weight: normal;
      letter-spacing: 0.2rem;
      font-family: ${theme.font.family.YaldeviRegular};
      background-color: #c3db43;
      color: #000;
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }

      ${Box} {
        width: 35%;
        margin: 0 auto;
      }
    `}
  `}
`;

export const Colors = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      text-align: center;
      margin: 2rem auto;
      margin-bottom: 4rem;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const StyledCircularInput = styled(CircularInput)`
  stroke-width: 1;
`;

export const StyledCircularTrack = styled(CircularTrack)`
  stroke-width: 10;
`;

export const StyledCircularThumb = styled(CircularThumb)`
  ${({ theme }) => css`
    r: 30;
    fill: white;
    stroke: ${theme.colors.primary};
  `}
`;

export const StyledCircularProgress = styled(CircularProgress)`
  stroke-width: 10;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
`;

type OptionColor = {
  value?: number;
  img?: string;
};

export const Option = styled.div<OptionColor>`
  ${({ theme, value, img }) => css`
    margin: 0 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: ${!!value && `hsl(${value * 100}, 100%, 50%)`};
    border: 1px solid ${theme.colors.primary};

    background-image: url(${img});
    background-size: cover;
  `}
`;

export const AddColor = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: ${theme.colors.primary};

    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid ${theme.colors.primary};
  `}
`;

// Step 2

export const StepImages = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const WrapperCompare = styled.div`
  width: 80%;
  margin: 2rem 0;
`;

export const DescriptionImg = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;
export const NameImageLeft = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.1rem;
  `}
`;

export const NameImageRight = styled(NameImageLeft)``;

// Step 03

export const StepCarrousel = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const WrapperCarousel = styled.div`
  width: 100%;
  margin-top: 2rem;

  img {
    max-width: 90%;
    margin: 0 auto;
  }

  .slick-slide {
    transition: 0.5s ease;
    opacity: 0.5;
  }

  .slick-active {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const StepThink = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const Span = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
    width: 80%;
    text-align: center;
    margin: 2rem 0;
    letter-spacing: 0.15rem;
  `}
`;

export const TextArea = styled.textarea`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border: none;
    resize: none;
    width: 80%;
    height: 15vh;
    margin-bottom: 2rem;
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.15rem;

    padding: 0.5rem;
  `}
`;

// step 5
export const StepFeeling = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${StyledCircularInput} {
      margin: 2rem 0;
    }

    ${StyledCircularThumb} {
      r: 20;
    }

    ${Image} {
      width: 15rem;
      height: 15rem;
      border-radius: 100%;
      margin-top: -19.5rem;
    }
  `}
`;

export const StepTips = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const Iframe = styled.iframe`
  margin: 2rem 0;
`;

export const GridInformations = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;

    width: ${theme.maxwidthMobile};
    max-width: 100%;
  `}
`;

export const Information = styled.div`
  ${({ theme }) => css`
    text-align: center;

    ${Image} {
      height: 3rem;
      margin: 0;
    }

    ${Title} {
      font-size: ${theme.font.sizes.xsmall};
    }

    ${Description} {
      font-size: ${theme.font.sizes.xsmall};
      max-width: 100%;
      margin: 0;
    }
  `}
`;

export const StepFeedback = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 2rem;
      font-size: ${theme.font.sizes.extraLarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;

export const Vote = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    border-top: 1px solid ${theme.colors.gray};
    margin: 2rem 0;
  `}
`;

export const Svg = styled(ReactSVG)`
  width: 3rem;
  height: 3rem;
  margin: 1rem;
`;

export const ModalFinish = styled(Modal)``;

export const ModalMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > main {
      min-height: 15rem;
    }

    ${Svg} {
      color: ${theme.colors.green};
      margin: 2rem 0;

      height: 6rem;
      width: 6rem;
    }

    ${Title} {
      color: ${theme.colors.black};
      font-weight: bold;
      font-size: ${theme.font.sizes.xlarge};
      font-family: ${theme.font.family.LoraRegular};
    }

    ${Description} {
      margin: 2rem 0;
      max-width: 90%;
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.LoraRegular};
      text-align: center;
      &:after,
      &:before {
        display: none;
      }
    }
  `}
`;
