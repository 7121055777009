import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import imgCheirar from '../../../assets/images/olfactory/4-cheirar.png';
import bgPlant from '../../../assets/images/olfactory/plant-cover.png';
import { useEffect, useState } from 'react';
import { getComponentFromData } from 'components/OlfactoryJourney/ComponentFromData';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/createStore';
import {
  initJourneyHistory,
  perfumsSelected,
  questionsRequest,
  resetStep,
} from 'store/modules/olfactoryJourney/actions';
import { useParams } from 'react-router';

export default function ResponseUser() {
  const dispatch = useDispatch();

  const [stepResponse, setStepResponse] = useState(0);

  const { questions, step, info_perfum } = useSelector(
    (state: StoreState) => state.journey
  );

  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  type paramsProps = {
    idPerfum: string;
  };

  const { idPerfum } = useParams<paramsProps>();

  useEffect(() => {
    dispatch(initJourneyHistory());
    dispatch(resetStep());
    dispatch(questionsRequest(userId, journeyId, Number(idPerfum)));
  }, []);

  return (
    <S.Wrapper>
      <Navbar />
      <S.Container>
        {stepResponse === 0 && (
          <S.Content bg={bgPlant}>
            <S.Top>
              <S.Title>JORNADA SENSORIAL E EDUCATIVA</S.Title>
              <S.Number number="1" />
            </S.Top>
            <S.Middle>
              <S.ProductName>{info_perfum?.perfum_name}</S.ProductName>
              <S.Author>por {info_perfum?.perfum_author}</S.Author>
            </S.Middle>
            <S.Bottom>
              <S.Box>
                <S.Image src={imgCheirar} />
                <S.Description>
                  Hora de usar a primeira fita olfativa
                </S.Description>
              </S.Box>
              <Button
                name="PRÓXIMO"
                theme="primary"
                onClick={() => setStepResponse((prevState) => ++prevState)}
              />
            </S.Bottom>
          </S.Content>
        )}

        {stepResponse >= 1 &&
          questions.map((question) => (
            <>
              {step + 1 === question.question_id &&
                getComponentFromData(question)}
            </>
          ))}
      </S.Container>
      <Footer />
    </S.Wrapper>
  );
}
