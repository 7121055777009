import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import { curiosityFacts } from 'services/curiosityFacts';
import { ActionType } from 'typesafe-actions';

export function* getArticles() {
  try {
    const { data } = yield call(curiosityFacts.findAllArticles);

    yield put(actions.articlesSuccess(data.articles));
  } catch (err) {
    yield put(actions.articlesFailure());
  }
}

export function* getDetailsArticle({
  payload,
}: ActionType<typeof actions.articleOneRequest>) {
  try {
    const { data } = yield call(curiosityFacts.findOneArticles, payload.id);

    yield put(actions.articleOneSuccess(data.article));
  } catch (err) {
    yield put(actions.articleOneFailure());
  }
}

export function* getVideos() {
  try {
    const { data } = yield call(curiosityFacts.findAllWatch);

    yield put(actions.videoSuccess(data.videos));
  } catch (err) {
    yield put(actions.videoFailure());
  }
}

export function* getAudios() {
  try {
    const { data } = yield call(curiosityFacts.findAudios);

    yield put(actions.audioSuccess(data.audios));
  } catch (err) {
    yield put(actions.audioFailure());
  }
}

export default all([
  takeLatest('@curiosityFacts/ARTICLES_REQUEST', getArticles),
  takeLatest('@curiosityFacts/ARTICLE_DETAILS_REQUEST', getDetailsArticle),
  takeLatest('@curiosityFacts/VIDEO_REQUEST', getVideos),
  takeLatest('@curiosityFacts/AUDIO_REQUEST', getAudios),
]);
