import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import * as S from './styles';

import img1 from '../../../../pages/OlfactoryJourney/JourneyPrimary/imagem1.jpg';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
type AgreeImageProps = {
  data: Question;
};

const AgreeImage = ({ data }: AgreeImageProps) => {
  const [checked, setChecked] = useState(false);
  const [reponseUser, setResponseUser] = useState('');
  const [answersUser, setAnswersUser] = useState<any>([]);
  const dispatch = useDispatch();
  const { perfum_id, perfum_name } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { loading } = useSelector((state: StoreState) => state.journey);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  function handleStep() {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: [reponseUser],
      answers_url: [data.answers_avaliable[0].url],
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  useEffect(() => {
    if (data.answers_user.length >= 1) {
      setAnswersUser(data.answers_user);
    }
  }, [data]);

  useEffect(() => {
    if (checked) {
      setResponseUser('concordo');
    } else {
      setResponseUser('discordo');
    }
  }, [checked]);

  return (
    <S.Wrapper>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data?.question_id} />
          <S.Description>{data?.title}</S.Description>

          <S.Image
            src={
              process.env.REACT_APP_BASEURL_IMAGES +
              data.answers_avaliable[0].url
            }
          />
          <S.SectionInput>
            {answersUser.length >= 1 ? (
              <S.SwitchComponent
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={() => console.log('')}
                checked={
                  answersUser[0]?.answer_user === 'discordo' ? false : true
                }
                mode={
                  answersUser[0]?.answer_user === 'discordo'
                    ? 'disabled'
                    : 'active'
                }
              />
            ) : (
              <S.SwitchComponent
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={() => setChecked(!checked)}
                checked={checked}
                mode={checked ? 'active' : 'disabled'}
              />
            )}

            <S.Box checked={checked}>
              <S.Description>Discordo</S.Description>
              <S.Description>Concordo</S.Description>
            </S.Box>
          </S.SectionInput>
        </>
      )}

      {data.answers_user.length >= 1 ? (
        <Button name="CONTINUAR" onClick={() => handleStep()} />
      ) : (
        <Button name="ENVIAR" onClick={() => handleStep()} />
      )}
    </S.Wrapper>
  );
};

export default AgreeImage;
