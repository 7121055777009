import { Route } from 'react-router';
import { toast } from 'react-toastify';
import { StoreState } from 'store/createStore';
import { useSelector } from 'react-redux';
import api from 'services/api';

export default function CustomRoute({
  isPrivate,
  path,
  exact,
  component,
}: {
  isPrivate?: boolean;
  path: string;
  exact: boolean;
  component: any;
}) {
  const { token } = useSelector((state: StoreState) => state.auth);
  api.defaults.headers.Authorization = `Bearer ${token}`;

  // if (isPrivate && token === null) {
  //   toast.error('Acesso negado, tente novamente.');
  //   document.location.href = 'https://amyi.co/login/';
  // }

  return <Route path={path} exact={exact} component={component} />;
}
