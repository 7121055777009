import { useState } from 'react';
import { Video } from 'store/modules/curiosityFacts/types';
import formatMinutes from 'utils/formatMinutes';
import * as S from './styles';

type Props = {
  item: Video;
};

export default function BoxPlayer({ item }: Props) {
  const [second, setSecond] = useState('');
  const [initial, setInitial] = useState('00:00');

  return (
    <S.Wrapper>
      <S.Box key={item.author}>
        <S.ProfileBox>
          <S.Avatar src={item.author_img} />
          <S.Name>{item.author}</S.Name>
        </S.ProfileBox>
        <S.Description>{item.video_title}</S.Description>
        <S.Player
          onProgress={(ev: any) =>
            setInitial(formatMinutes(ev.playedSeconds.toFixed()))
          }
          onDuration={(ev: number) => setSecond(formatMinutes(ev))}
          width="375px"
          height="200px"
          url={item.video_url}
        />
        <S.InfoPlayer>
          <S.InitialPlayer>{initial}</S.InitialPlayer>
          <S.DurationPlayer>{second}</S.DurationPlayer>
        </S.InfoPlayer>
      </S.Box>
    </S.Wrapper>
  );
}
