import * as S from './styles';

import {
  articleOneRequest,
  articlesRequest,
} from 'store/modules/curiosityFacts/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import Navbar from 'components/Navbar';
import { AiOutlineClose } from 'react-icons/ai';
import Footer from 'components/Footer';

function Read() {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { articles, article, loadingModal, loading } = useSelector(
    (state: StoreState) => state.curiosityFacts
  );

  useEffect(() => {
    dispatch(articlesRequest());
  }, []);

  const handleDetails = (id: number) => {
    dispatch(articleOneRequest(id));
    setModal((prevState) => !prevState);
  };

  return (
    <S.Wrapper>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {articles !== undefined && (
            <>
              {articles.map((item) => (
                <S.Box
                  key={item.post_id}
                  onClick={() => handleDetails(item.post_id)}
                >
                  <S.Image src={item.thumb} />
                  <div>
                    <S.Title>{item.title}</S.Title>
                    <S.Author>Por {item.author}</S.Author>
                  </div>
                </S.Box>
              ))}
            </>
          )}

          {modal && (
            <S.ModalDetails>
              <Navbar />
              {loadingModal ? (
                <LoadingRound />
              ) : (
                <S.Content>
                  <S.CloseModal
                    onClick={() => setModal((prevState) => !prevState)}
                  >
                    <AiOutlineClose size={20} />
                  </S.CloseModal>
                  <S.Image src={article[0].thumb} />
                  <S.Title>{article[0].title}</S.Title>
                  <S.Author>Por {article[0].author}</S.Author>
                  <S.Text
                    dangerouslySetInnerHTML={{ __html: article[0].content }}
                  />
                </S.Content>
              )}
              <Footer />
            </S.ModalDetails>
          )}
        </>
      )}
    </S.Wrapper>
  );
}

export default Read;
