import { useContext, useEffect, useState } from 'react';
import history from '../../../utils/history';

import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import imgCheirar from '../../../assets/images/olfactory/4-cheirar.png';
import bgPlant from '../../../assets/images/olfactory/plant-cover.png';
import Button from 'components/Button';

import checkCircle from '../../../assets/images/icones/check-circle-light.svg';
import SubNav from 'components/OlfactoryJourney/SubNav';

import { getComponentFromData } from 'components/OlfactoryJourney/ComponentFromData';

import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from 'store/createStore';
import {
  questionsRequest,
  nextStep,
  resetStep,
  closeModalFinish,
  resetPerfum,
  nextPerfum,
} from 'store/modules/olfactoryJourney/actions';
import Feedback from 'components/OlfactoryJourney/Questions/Feedback';
import LoadingRound from 'components/LoadingRound';

import { NotesFinallyContext } from 'components/StarsVote/notesFinallyContext';

function JourneyPrimary() {
  const {
    questions,
    step,
    modalFinishQuestions,
    info_perfum,
    perfums_selected,
    step_journey,
    loading,
  } = useSelector((state: StoreState) => state.journey);
  const dispatch = useDispatch();
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  useEffect(() => {
    step_journey > perfums_selected.length - 1
      ? history.push('/selectPerfumCorrect')
      : dispatch(
          questionsRequest(userId, journeyId, perfums_selected[step_journey])
        );
  }, [step_journey]);

  function proxStep() {
    dispatch(nextStep());
  }

  const closeModal = () => {
    dispatch(resetStep());
    dispatch(nextPerfum());
    dispatch(closeModalFinish());
  };

  return (
    <S.Wrapper>
      {/* Modal Finish Flux */}
      <S.ModalFinish open={modalFinishQuestions} closeModal={closeModal}>
        <S.ModalMain>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Svg src={checkCircle} />
              <S.Title>{info_perfum?.perfum_name} concluído!</S.Title>
              <S.Description>
                Você se entregou e vivenciou esse perfume com sucesso!
              </S.Description>
              <Button name="CONTINUAR" onClick={closeModal} />
            </>
          )}
        </S.ModalMain>
      </S.ModalFinish>

      <Navbar />
      {step !== 0 && <SubNav pass={step_journey + 1} />}

      {step === 0 && (
        <S.Container>
          {loading ? (
            <LoadingRound />
          ) : (
            <S.Content>
              <S.Top>
                <S.Title>
                  jornada sensorial <br />e educativa
                </S.Title>
                <S.Number number={String(step_journey + 1)} />
              </S.Top>
              <S.Middle>
                <S.ProductName>{info_perfum?.perfum_name}</S.ProductName>
                <S.Author>por {info_perfum?.perfum_author}</S.Author>
              </S.Middle>
              <S.Bottom>
                <S.Box>
                  <S.Image src={imgCheirar} />
                  <S.Description>
                    Agora é hora de molhar uma fita olfativa no perfume indicado
                    acima.
                  </S.Description>
                </S.Box>
                <Button name="PRÓXIMO" theme="primary" onClick={proxStep} />
              </S.Bottom>
            </S.Content>
          )}
        </S.Container>
      )}

      {questions.map((question) => (
        <>{step === question.question_id && getComponentFromData(question)}</>
      ))}

      {!loading && <Footer />}
    </S.Wrapper>
  );
}

export default JourneyPrimary;
