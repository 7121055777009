import * as S from './styles';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import Slider from 'react-slick';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import { useEffect, useRef, useState } from 'react';
import { FiCheck } from 'react-icons/fi';

type MusicProps = {
  data: Question;
};

const Music = ({ data }: MusicProps) => {
  const { info_perfum } = useSelector((state: StoreState) => state.journey);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');
  const [otherOption, setOtherOption] = useState('');

  const { loading } = useSelector((state: StoreState) => state.journey);
  const [selectedLegend, setSelectedLegend] = useState('');
  const [selectedUrl, setSelectedUrl] = useState('');

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setSelectedLegend(data.answers_avaliable[0].legend);
  //   setSelectedUrl(data.answers_avaliable[0].url);
  // }, []);

  const handleSelect = (legend: string, url: string) => {
    if (selectedUrl === url) {
      setSelectedUrl('');
      setSelectedLegend('');
    } else {
      setSelectedLegend(legend);
      setSelectedUrl(url);
    }
  };

  const [indexSlide, setIndexSlide] = useState<any>({
    oldSlide: 1,
    activeSlide: 0,
  });

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '80px',
    slidesToShow: 1,
    speed: 500,
    arrows: true,
    beforeChange: (current: number, next: number) =>
      setIndexSlide({ oldSlide: current, activeSlide: next }),
    afterChange: () => handlePause(),
  };

  function handleStep() {
    const answersUser = otherOption.length
      ? [selectedLegend, otherOption]
      : [selectedLegend];

    const answersUrl = otherOption.length
      ? [
          selectedUrl,
          'https://journeyapi.amyi.co/storage/img/perfums_questions/other_img/music/outro.jpg',
        ]
      : [selectedUrl];

    const responseUser = {
      user_id: userId,
      perfum_id: info_perfum.perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: answersUser,
      answers_url: answersUrl,
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  function handlePause() {
    const audioController1 = document
      .getElementsByClassName(`slide${indexSlide.oldSlide}`)[0]
      ?.querySelector('audio');

    const audioController2 = document
      .getElementsByClassName(`slide${indexSlide.activeSlide}`)[0]
      ?.querySelector('audio');

    audioController1?.pause();
    audioController2?.pause();
  }

  return (
    <S.Wrapper>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <S.Title>{info_perfum.perfum_name}</S.Title>
          <ProgressCircles position={data.question_id} />
          <S.Description>{data.title}</S.Description>

          <S.WrapperCarousel>
            {data.answers_user.length >= 1 ? (
              <Slider {...settings} onSwipe={() => handlePause()}>
                {data.answers_user.map((music, index) => (
                  <div key={music.answer_url}>
                    {music.cover && (
                      <S.Image
                        selected={selectedUrl === music.answer_url}
                        src={process.env.REACT_APP_BASEURL_IMAGES + music.cover}
                      />
                    )}
                    <AudioPlayer
                      className={String(`slide${index}`)}
                      showJumpControls={false}
                      showSkipControls={false}
                      src={
                        process.env.REACT_APP_BASEURL_IMAGES + music.answer_url
                      }
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <Slider {...settings} onSwipe={() => handlePause()}>
                {data.answers_avaliable.map((music, index) => (
                  <div key={music.cover}>
                    <S.WrapperImage>
                      <S.Image
                        selected={selectedUrl === music.url}
                        onClick={() => handleSelect(music.legend, music.url)}
                        src={process.env.REACT_APP_BASEURL_IMAGES + music.cover}
                      />
                      {selectedUrl === music.url && (
                        <S.ImageBox
                          onClick={() => handleSelect(music.legend, music.url)}
                        >
                          <FiCheck size={50} color="white" />
                        </S.ImageBox>
                      )}
                    </S.WrapperImage>
                    <AudioPlayer
                      className={String(`slide${index}`)}
                      showJumpControls={false}
                      showSkipControls={false}
                      src={process.env.REACT_APP_BASEURL_IMAGES + music.url}
                    />
                  </div>
                ))}

                <div>
                  <S.WrapperImage>
                    <S.Image src="https://journeyapi.amyi.co/storage/img/perfums_questions/other_img/music/outro.jpg" />
                  </S.WrapperImage>
                  <S.Input
                    placeholder="outro"
                    value={otherOption}
                    onChange={(e) => setOtherOption(e.target.value)}
                  />
                </div>
              </Slider>
            )}
          </S.WrapperCarousel>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => handleStep()} />
          ) : selectedUrl !== '' || otherOption.length >= 1 ? (
            <Button name="ENVIAR" onClick={() => handleStep()} />
          ) : (
            <Button name="ENVIAR" theme="disabled" />
          )}
        </>
      )}
    </S.Wrapper>
  );
};

export default Music;
