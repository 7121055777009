import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  overflow-x: hidden;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: ${theme.maxwidthMobile};
    max-width: 100%;

    ${media.greaterThan('small')`
      width: 100%;

      max-width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
    font-size: ${theme.font.sizes.extraLarge};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: 400;
    letter-spacing: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 2rem;

    h1 {
      font-family: ${theme.font.family.LoraVariable};
      font-weight: bold;
      margin-top: 1rem;
      font-size: 32px;

      ${media.lessThan('medium')`
        font-size: 20px;
      `}
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    max-width: 90%;
    margin: 0 auto;
    font-size: 1.3rem;
    font-family: ${theme.font.family.LoraRegular};
    line-height: 1.6rem;

    font-weight: medium;
    text-align: center;

    letter-spacing: 0.2rem;

    ${media.lessThan('medium')`
      max-width: 280px;

      br {display: none}
    `}
  `}
`;

export const Image = styled.img`
  max-width: 500px;
  height: 340px;

  ${media.lessThan('medium')`
    max-width: 300px !important;
    height: 200px;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    padding: 2rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    > button,
    a {
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
      font-size: 1.6rem;
      letter-spacing: 0.2rem;
      width: 190px;

      ${media.lessThan('medium')`
        width: 300px;
      `}
    }

    .text1 {
      letter-spacing: 0;
      font-size: 1.6rem;
    }

    .desc1 {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    .desc3 {
      margin-top: 1rem;
    }

    .desc2 {
      margin-top: 1rem;
    }

    .control-dots {
      top: -10px;
    }

    .carousel .control-dots .dot {
      box-shadow: none;
      background-color: ${theme.colors.secondGray};
      margin: 0 5px;

      &:hover {
        background-color: ${theme.colors.black};
      }
    }

    .dot.selected {
      background-color: ${theme.colors.black} !important;
    }

    .carousel .slide {
      background-color: transparent;
      width: 70%;
    }

    .img1 {
      /* transform: translateX(4.1rem); */
      margin-bottom: 1rem;
    }

    .img3 {
      margin: 0.1rem 0;
      /* max-width: 33rem; */
      /* height: 15rem; */
    }

    .img4 {
      margin: 1rem 0;
      /* max-width: 27rem; */
      /* height: 15rem; */
    }

    .img5 {
      margin: 1rem 0;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
