import { action } from 'typesafe-actions';
import { HistoricState } from './types';

export function historicRequest(userId: string | null, journeyId: number) {
  return action('@historic/HISTORIC_REQUEST', { userId, journeyId });
}

export function historicSuccess(data: HistoricState) {
  return action('@historic/HISTORIC_SUCCESS', {
    data,
  });
}

export function historicFailure() {
  return action('@historic/HISTORIC_FAILURE');
}
