import * as S from './styles';

const Loading = () => (
  <S.Wrapper>
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </S.Wrapper>
);

export default Loading;
