import {
  Billing,
  CreateJourneyManual,
  ProfileEditing,
  ShippingAccount,
} from 'store/modules/account/types';
import api from './api';

const RESOURCE = '/orders';
const RESOURCEPROFILE = '/profile';

export const account = {
  listOrders: async (userId: string | null) =>
    await api.get(RESOURCE + `?user_id=${userId}`),
  listHome: async (user_id: number) => await api.post('/home', { user_id }),
  creatingJourney: async (data: any) =>
    await api.post('/home/create_journey', { data: data }),
  creatingManual: async (data: CreateJourneyManual) =>
    await api.post('/home/create_manual', { data: data }),
  detailsOrders: async (id: number) =>
    await api.get(RESOURCE + `=?order_id=${id}`),
  detailsAddress: async (userId: string | null, journeyId: number) =>
    await api.get(RESOURCEPROFILE + `/address?user_id=${userId}`),
  detailsAccount: async (userId: string | null) =>
    await api.get(RESOURCEPROFILE + `?user_id=${userId}`),
  editingProfile: async (data: ProfileEditing, userId: string | null) =>
    await api.put(RESOURCEPROFILE + '/change', {
      profile: data,
      user_id: userId,
    }),
  editingAddress: async (
    userId: string | null,
    data: ShippingAccount | Billing
  ) =>
    await api.put(RESOURCEPROFILE + '/address/change', {
      address: data,
      user_id: userId,
    }),
};
