import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.threeGray};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.xlarge};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    width: 100%;
    padding: 2rem;
    margin-bottom: ${theme.spacings.xsmall};
    background-color: ${theme.colors.white};

    > tr > th {
      text-align: left;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      color: ${theme.colors.secondBlack};
      letter-spacing: 0.2rem;
      font-size: ${theme.font.sizes.medium};
    }

    > tr > td {
      font-weight: bold;
      font-family: ${theme.font.family.LoraRegular};
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.medium};
      letter-spacing: 0.2rem;
      font-weight: normal;
      padding-top: 1rem;
    }

    tr {
      &:nth-child(2) {
        td {
          font-weight: bold;
          padding-top: 0rem;
        }
      }
    }
  `}
`;

export const BoxLegends = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.YaldeviRegular};
    margin: 2rem 0;
    letter-spacing: 0.1rem;
    font-size: ${theme.font.sizes.small};

    &:first-child,
    &:last-child {
      margin: 0;
    }
  `}
`;

export const Closed = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    text-align: right;
    margin: 1rem 1rem 0 0;
  }
`;
