import { action } from 'typesafe-actions';
import { Article, Audio, Video } from './types';

export function articlesRequest() {
  return action('@curiosityFacts/ARTICLES_REQUEST');
}

export function articlesSuccess(data: Article[]) {
  return action('@curiosityFacts/ARTICLES_SUCCESS', {
    data,
  });
}

export function articlesFailure() {
  return action('@curiosityFacts/ARTICLES_FAILURE');
}

export function articleOneRequest(id: number) {
  return action('@curiosityFacts/ARTICLE_DETAILS_REQUEST', { id });
}

export function articleOneSuccess(data: Article[]) {
  return action('@curiosityFacts/ARTICLE_DETAILS_SUCCESS', {
    data,
  });
}

export function articleOneFailure() {
  return action('@curiosityFacts/ARTICLE_DETAILS_FAILURE');
}

export function videoRequest() {
  return action('@curiosityFacts/VIDEO_REQUEST');
}

export function videoSuccess(data: Video[]) {
  return action('@curiosityFacts/VIDEO_SUCCESS', {
    data,
  });
}

export function videoFailure() {
  return action('@curiosityFacts/VIDEO_FAILURE');
}

export function audioRequest() {
  return action('@curiosityFacts/AUDIO_REQUEST');
}

export function audioSuccess(data: Audio[]) {
  return action('@curiosityFacts/AUDIO_SUCCESS', {
    data,
  });
}

export function audioFailure() {
  return action('@curiosityFacts/AUDIO_FAILURE');
}
