import LoadingRound from 'components/LoadingRound';
import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import { useEffect, useState } from 'react';
import { WatchService } from 'services/watch';
import * as S from './styles';
import ToggleWatch from './ToggleWatch';

type VideoProps = {
  id: number;
  title: string;
  perfumist_name: string;
  video_url: string;
};

export default function WatchPage() {
  const [data, setData] = useState([] as VideoProps[]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const { data } = await WatchService.get();

      setData(data);
      setLoading(false);
    }
    getData();
  }, []);

  return (
    <S.Wrapper>
      <Navbar />
      <NavBack link="/dashboard" title="Vídeos" />

      <S.Content>
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            {data.map(({ id, title, video_url }) => (
              <ToggleWatch key={id} title={title} url={video_url} />
            ))}
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}
