import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  div {
    max-width: 80%;
    margin: 0 auto;
  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.secondBlack};
    margin-top: -2rem;
  `}
`;

export const Image = styled.img`
  max-width: 9rem;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 2.5rem 1rem;
`;

export const BoxTitle = styled.div`
  margin-left: 2rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.large};
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.LoraVariable};
    width: 80%;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    font-size: ${theme.font.sizes.small};
    margin-top: 2rem;
    letter-spacing: 0.2rem;
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.YaldeviRegular};
    margin: 0 1rem;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    margin: 2rem 1rem;
    background: none;
    border: 1px solid ${theme.colors.threeGray};
    color: ${theme.colors.threeGray};
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    font-size: ${theme.font.sizes.medium};
    letter-spacing: 0.1rem;
    padding: 0.8rem 3.5rem;
  `}
`;

export const Controller = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BoxProgress = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Description} {
      margin-top: 0.5rem;
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

type ProgressBar = {
  porcent?: number;
};

export const Progress = styled.div<ProgressBar>`
  ${({ theme, porcent }) => css`
    background-color: ${theme.colors.secondGray};
    width: 11rem;
    height: 0.8rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;

      background-color: ${theme.colors.green};
      height: 1rem;
      width: ${porcent}%;
    }
  `}
`;

export const List = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 2rem 0;
    border-bottom: 1px solid ${theme.colors.gray};

    ${Image} {
      max-width: 8rem;
      max-height: 8rem;
      margin-right: 2rem;
    }
  `}
`;

export const BoxInfo = styled.div`
  ${({ theme }) => css`
    ${Title} {
      color: ${theme.colors.secondBlack};
      width: 100%;
      font-size: ${theme.font.sizes.medium};
      letter-spacing: 0.1rem;
      font-family: ${theme.font.family.YaldeviRegular};
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      margin-top: 0.5rem;
      font-size: ${theme.font.sizes.medium};
      font-family: ${theme.font.family.LoraRegular};
    }
  `}
`;

export const PlayerBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    ${Title} {
      letter-spacing: 0.1rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
    }
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    width: 2.5rem;
    color: ${theme.colors.secondBlack};
    margin-right: 1rem;
    transform: translateY(2px);
  `}
`;

export const PlayContainer = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    width: 100%;

    ${Progress} {
      width: 100%;
      height: 1rem;
    }
  `}
`;

export const PlayController = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem 2rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${Title} {
      color: ${theme.colors.secondBlack};
      font-size: ${theme.font.sizes.xsmall};
      letter-spacing: 0.1rem;
    }

    ${Svg} {
      color: ${theme.colors.black};
      width: 3.5rem;
    }
  `}
`;
