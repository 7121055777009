import styled, { css } from 'styled-components';
import {
  CircularInput,
  CircularTrack,
  CircularThumb,
  CircularProgress,
} from 'react-circular-input';
import media from 'styled-media-query';
export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

type ImageProps = {
  disable?: boolean;
};

export const Image = styled.img<ImageProps>`
  ${({ disable }) => css`
    height: 7rem;
    display: ${disable ? 'none' : 'hidden'};
  `}
`;

export const StyledCircularInput = styled(CircularInput)`
  stroke-width: 1;
`;

export const StyledCircularTrack = styled(CircularTrack)`
  stroke-width: 10;
`;

export const StyledCircularThumb = styled(CircularThumb)`
  ${({ theme }) => css`
    r: 30;
    fill: white;
    stroke: ${theme.colors.primary};
  `}
`;

export const StyledCircularProgress = styled(CircularProgress)`
  stroke-width: 10;
  stroke-dashoffset: 0;
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

type OptionColor = {
  value?: number;
  img?: string;
};

export const Option = styled.div<OptionColor>`
  ${({ theme, value, img }) => css`
    margin: 0 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: ${!!value && `hsl(${value * 100}, 100%, 50%)`};
    border: 1px solid ${theme.colors.primary};

    background-image: url(${img});
    background-size: cover;
  `}
`;

export const AddColor = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #838384;

    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid #838384;
    margin-right: 0.6rem;
  `}
`;

export const StepFeeling = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 75%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${StyledCircularInput} {
      margin: 2rem 0;
    }

    ${StyledCircularThumb} {
      r: 20;
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const BoxCircular = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    height: 150px !important;
    margin: 1rem 0 !important;
  }

  ${Image} {
    border-radius: 100%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 10rem;
    height: 10rem;
  }

  ${media.greaterThan('small')`
    svg {
      height: 250px !important;
      margin: 0 !important;
    }

    ${Image} {
      width: 15rem;
      height: 15rem;
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.YaldeviMedium};
    letter-spacing: 0.2rem;
  `}
`;

export const Information = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
    margin: 1rem 0;
    text-align: center;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    text-align: center;
    font-family: ${theme.font.family.YaldeviRegular};
    height: 2.8rem;
    font-size: 1.6rem;
  `}
`;
