import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    > a {
      font-size: 1.4rem;
      font-family: ${theme.font.family.YaldeviRegular};
      max-width: 70%;
      margin-top: 4rem;
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-align: center;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraVariable};
    font-weight: medium;
    color: #494c4f;

    margin: 2rem 0;

    &:last-child {
      width: 85%;
    }

    ${media.lessThan('small')`
      width: 25rem;
    `}
  `}
`;

export const Image = styled.img`
  ${media.lessThan('small')`
    width: 29rem;
  `}
`;

export const Iframe = styled.iframe`
  margin: 0 auto;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-size: 2.4rem;
    font-family: ${theme.font.family.LoraRegular};

    width: 80%;
    text-align: center;
    margin-top: 2rem;
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 90%;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > button {
      width: 100%;
      font-size: 1.4rem;
      font-family: ${theme.font.family.YaldeviRegular};
    }

    ${Title} {
      margin: 0;
      font-size: ${theme.font.sizes.medium};
      text-align: left;
      width: 100%;
    }

    ${Description} {
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      width: auto;
      text-align: left;
      margin: 1rem 0;
    }
  `}
`;
