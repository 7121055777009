import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';

import giftCode from '../../assets/images/icones/gift-solid.svg';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Modal from 'components/Modal';
import { useState } from 'react';
import { maskTelefone } from 'utils/maskTelefone';

function GiftCode() {
  const [openModal, setOpenModal] = useState(false);
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [errorCode, setErrorCode] = useState(false);
  const [validateCod, setValidateCod] = useState(false);

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  const modalOpen = () => {
    setOpenModal(true);
  };

  const handleActiveCode = () => {
    if (code === '12345') {
      setValidateCod(true);
    } else {
      setValidateCod(false);
      setErrorCode(false);
    }
  };

  return (
    <S.Wrapper>
      <S.RecoveryModal open={openModal} closeModal={closeModal}>
        {errorCode ? (
          <S.ModalMainError>
            <S.Title>Recuperar Código de Presente</S.Title>
            <S.Description>
              O número de telefone não possui nenhum código disponivel. entre em
              contato com a pessoa que lhe presenteou ou envie um e-mail para{' '}
              <a href="mailto:hello@amyi.com">hello@amyi.com</a>
            </S.Description>
            <Button name="ENVIAR E-MAIL" />
          </S.ModalMainError>
        ) : (
          <S.ModalMain>
            <S.Title>Recuperar Código de Presente</S.Title>
            <S.Description>
              O número de telefone do seu cadastro não possui nenhum código
              disponivel. Informe o número da pessoa que lhe presenteou:
            </S.Description>
            <S.InputCod
              placeholder="Número de telefone"
              value={phone}
              onChange={({ target }) => setPhone(maskTelefone(target.value))}
            />
            <Button name="ENVIAR MENSAGEM" />
          </S.ModalMain>
        )}
      </S.RecoveryModal>
      <Navbar />
      <NavBack title="Ganhei de presente" />
      <S.Content>
        <S.Title>
          Para realizar o pedido, insira o código que você recebeu pelo número
          do seu celular:
        </S.Title>

        <S.Box>
          <S.Svg src={giftCode} />
          <S.InputCod
            placeholder="Código do presente"
            value={code}
            onChange={({ target }) => setCode(target.value)}
          />
          {validateCod ? (
            <S.Title>Código validado com sucesso!</S.Title>
          ) : (
            <S.ButtonBox>
              <S.Button onClick={modalOpen}>Esqueci o código</S.Button>
              <Button name="ADICIONAR" onClick={handleActiveCode} />
            </S.ButtonBox>
          )}
        </S.Box>

        {validateCod && (
          <S.MessageBox>
            <S.Description>
              Você possui um perfume em 100ml incluso nessa jornada.
            </S.Description>
          </S.MessageBox>
        )}

        {validateCod ? (
          <S.ButtonLink to="/revieworder">CONTINUAR</S.ButtonLink>
        ) : (
          <S.ButtonContinue disabled>CONTINUAR</S.ButtonContinue>
        )}
        <Footer />
      </S.Content>
    </S.Wrapper>
  );
}

export default GiftCode;
