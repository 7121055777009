import Navbar from 'components/Navbar';
import * as S from './styles';

import { IoIosArrowDown } from 'react-icons/io';
import { GrFormClose } from 'react-icons/gr';
import { useState } from 'react';

import instructions from './instructions.json';
import Footer from 'components/Footer';
import { Link } from 'react-router-dom';

function Instructions() {
  const [clicked, setClicked] = useState<null | number>(null);

  const toggle = (id: number) => {
    if (clicked === id) {
      return setClicked(null);
    }

    setClicked(id);
  };

  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Title>
          Instruções{' '}
          <Link to="/olfactory-journey/primary">
            <GrFormClose size={27} />
          </Link>
        </S.Title>

        <S.Description>
          Está com alguma dúvida?
          <br /> A gente te ajuda.
        </S.Description>
        {instructions.map((instruction) => (
          <S.Box key={instruction.id} onClick={() => toggle(instruction.id)}>
            <S.Header active={clicked === instruction.id}>
              <S.Title>{instruction.title}</S.Title>
              <IoIosArrowDown />
            </S.Header>
            {clicked === instruction.id && (
              <S.Body>
                {instruction.description.map((description) => (
                  <S.Description key={instruction.id}>
                    {description.text}
                  </S.Description>
                ))}
                {/* {instruction?.video && (
                  <S.Iframe
                    src={instruction.video}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></S.Iframe>
                )} */}
              </S.Body>
            )}
          </S.Box>
        ))}
      </S.Wrapper>
      <Footer />
    </>
  );
}

export default Instructions;
