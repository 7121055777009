import Navbar from 'components/Navbar';
import * as S from './styles';

import illustrationImage from 'assets/images/illustration.png';
import Button from 'components/Button';
import SubNav from 'components/OlfactoryJourney/SubNav';

export default function AlterPerfums() {
  return (
    <S.Wrapper>
      <Navbar />
      <SubNav pass={4} />
      <S.Title>Reavaliação</S.Title>
      <S.Content>
        <S.Description>
          Cheire novamente as fitas olfativas e confira a evolução de cada
          perfume.
        </S.Description>

        <S.Image src={illustrationImage} />

        <S.Description>
          Dica: aplique o perfume em uma nova fita olfativa e compare as fitas.
        </S.Description>

        <Button name="PRÓXIMO" link to="/notes/perfums" />
      </S.Content>
    </S.Wrapper>
  );
}
