import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    min-height: 100vh;

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    max-width: 80%;
    text-align: center;
    color: ${theme.colors.black};
    margin: 2rem auto;
    font-family: ${theme.font.family.LoraVariable};
    font-size: 2.8rem;
    font-weight: 700;
    letter-spacing: 0.2rem;

    ${media.lessThan('small')`
      font-size: 2.1rem;
    `}
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    text-align: center;
    font-size: 1.6rem;
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: white;
    max-width: 100%;
    width: ${theme.maxwidthMobile};
    margin: 2rem auto;
    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
    font-weight: bold;
    font-size: ${theme.font.sizes.extraLarge};
    text-align: center;
    margin-bottom: 2rem;

    &:after {
      content: '';
      display: block;
      margin-top: 1.6rem;

      width: 25rem;
      height: 0.1rem;
      background-color: #000;
    }
  `}
`;

export const WrapperModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${Title} {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-family: ${theme.font.family.LoraRegular};

      &:after {
        background: none;
        box-shadow: 0px 0px 8px #00000029;
      }
    }

    ${Description} {
      margin-top: 0;
      font-family: ${theme.font.family.LoraRegular};
      font-size: ${theme.font.sizes.small};
      max-width: 100%;
    }
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.green};
    width: 5rem;
    margin: 2rem;
  `}
`;
