export default function formatMinutes(time: number, with_seg = false) {
  let hours: string | number = Math.floor(time / 3600);
  let minutes: string | number = Math.floor((time % 3600) / 60);
  let seconds: string | number = time % 60;

  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  hours = hours < 10 ? '0' + hours : hours;

  if (with_seg) {
    return hours + ':' + minutes + ':' + seconds;
  }

  return minutes + ':' + seconds;
}
