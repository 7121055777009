import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 20vh;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 1.6rem;
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: bold;
    letter-spacing: 0.3rem;
    font-family: ${theme.font.family.YaldeviRegular};

    svg {
      position: absolute;
      right: 7rem;
      transform: translateX(5rem) rotate(0deg);
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    text-align: center;
    width: 80%;
    margin: 2rem auto;
    color: ${theme.colors.black};
    font-weight: medium;
    letter-spacing: 0.1rem;
  `}
`;

export const Box = styled.div`
  margin: 2rem 0;
`;

type HeaderProps = {
  active: boolean;
};

export const Header = styled.div<HeaderProps>`
  ${({ theme, active }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;

    svg {
      position: absolute;
      right: 7rem;
      transition: 0.2s ease;

      transform: ${active
        ? 'translateX(5rem) rotate(180deg)'
        : 'translateX(5rem) rotate(0deg)'};
    }

    border: 1px solid ${theme.colors.secondGray};
    padding: 1rem 0;

    ${Title} {
      width: 100%;
      text-align: left;
      padding-left: 1rem;
      color: ${theme.colors.secondBlack};
      font-size: 12px;
      margin: 0;
    }
  `}
`;

export const Body = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.threeGray};
    padding: 1rem 0rem;

    ${Description} {
      text-align: left;
      margin-top: 1rem;
      color: ${theme.colors.secondBlack};
      letter-spacing: 0.1rem;
      font-size: ${theme.font.sizes.small};
    }
  `}
`;

export const Iframe = styled.iframe`
  width: 90%;

  ${media.greaterThan('small')`
    width: 70%;
    height: 70vh;
    padding: 2rem;
  `}
`;
