import media from 'styled-media-query';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;
    font-size: ${theme.font.sizes.xlarge};
    max-width: 50%;
    margin: 2rem auto;
    font-family: ${theme.font.family.LoraRegular};
    font-weight: 700;

    letter-spacing: 0.1rem;
  `}
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 2rem;
  padding: 1rem 0;

  > a {
    margin-top: 3rem;
  }

  ${media.greaterThan('small')`
      > a {
        width: 25%;
      }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;
    max-width: 75%;
    margin: 0 auto;
    font-family: ${theme.font.family.YaldeviRegular};
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: ${theme.colors.secondBlack};
  `}
`;

export const Image = styled.img`
  height: 100%;
  width: 90%;
  margin: 2rem auto;
  display: block;

  ${media.greaterThan('small')`
    width: 30%;
  `}
`;
