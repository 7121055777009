import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';

import hourglass from '../../assets/images/icones/hourglass-half-regular.svg';
import Footer from 'components/Footer';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import ReactStars from 'react-rating-stars-component';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import { useEffect } from 'react';
import { historicRequest } from 'store/modules/historic/actions';
import LoadingRound from 'components/LoadingRound';

function History() {
  const dispatch = useDispatch();
  const { perfums, loading } = useSelector(
    (state: StoreState) => state.historic
  );

  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  useEffect(() => {
    dispatch(historicRequest(userId, journeyId));
  }, []);

  return (
    <S.Wrapper>
      <Navbar />
      <NavBack title="histórico" />
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {perfums !== undefined ? (
            <>
              <S.History>
                <S.Title>
                  Aqui estão suas jornada olfativas. Você pode retomar
                  experiências, ou relembrá-las!
                </S.Title>

                <S.WrapperAvaliations>
                  {perfums.map((item) => (
                    <>
                      <S.Box key={item.perfum_id}>
                        <S.Title>{item.perfum_name}</S.Title>
                        <S.Description>{item.perfum_ingredients}</S.Description>
                        <S.Validation>
                          <S.Title>1ª Avaliação:</S.Title>
                          {item.perfum_first_rating ? (
                            <div style={{ pointerEvents: 'none' }}>
                              <ReactStars
                                count={5}
                                value={Number(item.perfum_first_rating)}
                                activeColor="#000"
                                color="#EBEBEB"
                                size={18}
                                filledIcon={AiFillStar}
                                emptyIcon={AiOutlineStar}
                              />
                            </div>
                          ) : (
                            <p> --</p>
                          )}
                        </S.Validation>
                        <S.Validation>
                          <S.Title>2ª Avaliação:</S.Title>
                          {item.perfum_second_rating ? (
                            <div style={{ pointerEvents: 'none' }}>
                              <ReactStars
                                count={5}
                                value={Number(item.perfum_second_rating)}
                                activeColor="#000"
                                color="#EBEBEB"
                                size={18}
                                filledIcon={AiFillStar}
                                emptyIcon={AiOutlineStar}
                              />
                            </div>
                          ) : (
                            <p> --</p>
                          )}
                        </S.Validation>
                        {item.perfum_status === 'completed' ? (
                          <Button
                            name="VER RESPOSTAS"
                            theme="history"
                            link
                            to={`/journeyResponse/${item.perfum_id}`}
                          />
                        ) : (
                          <Button
                            name="RETOMAR JORNADA"
                            link
                            to="/olfactory-journey"
                          />
                        )}
                      </S.Box>
                    </>
                  ))}
                </S.WrapperAvaliations>
              </S.History>
            </>
          ) : (
            <S.Content>
              <S.Svg src={hourglass} />
              <S.Title>Você não tem experiências iniciadas</S.Title>
            </S.Content>
          )}
        </>
      )}

      <Footer />
    </S.Wrapper>
  );
}

export default History;
