import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';

type ButtonProps = {
  btn?: 'primary' | 'secondary' | 'disabled' | 'history' | 'journey';
};

const wrapperModifiers = {
  primary: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
  `,

  secondary: (theme: DefaultTheme) => css`
    background-color: transparent;
    border: 1px solid ${theme.colors.secondBlack};
    color: ${theme.colors.secondBlack};
  `,

  disabled: (theme: DefaultTheme) => css`
    /* background-color: ${theme.colors.gray}; */
    border: 1px solid ${theme.colors.gray};
    font-weight: normal;
    font-family: ${theme.font.family.YaldeviRegular};

    color: ${theme.colors.secondGray};
    letter-spacing: 0.2rem;
  `,

  journey: (theme: DefaultTheme) => css`
    font-weight: normal;
    font-family: ${theme.font.family.YaldeviRegular};
    width: 80%;

    color: ${theme.colors.secondGray};
    letter-spacing: 0.2rem;
  `,

  history: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary};
    font-weight: normal;
    letter-spacing: 0.1rem;
    color: ${theme.colors.black};
  `,
};

export const Button = styled.button<ButtonProps>`
  ${({ theme, btn }) => css`
    width: 90%;
    margin: 0 auto;
    display: block;

    border: none;
    border-radius: none;

    font-weight: normal;
    letter-spacing: 0.1rem;

    font-size: ${theme.font.sizes.medium};

    height: 3.8rem;

    ${!!btn && wrapperModifiers[btn](theme)};
  `}
`;

export const Navlink = styled(Link)<ButtonProps>`
  ${({ theme, btn }) => css`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    border: none;
    border-radius: none;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.YaldeviRegular};

    font-weight: normal;
    letter-spacing: 0.1rem;

    height: 3.8rem;

    ${!!btn && wrapperModifiers[btn](theme)};
  `}
`;
