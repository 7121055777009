import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Content = styled.main`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: bold;
    font-size: ${theme.font.sizes.xlarge};
    letter-spacing: 0.2rem;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    margin-top: ${theme.spacings.medium};
    display: flex;
    justify-content: center;
    align-items: center;

    &:after,
    &:before {
      content: '';
      display: inline-block;
      height: 0.1rem;
      margin: 1rem;
      width: 6rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const boxLogin = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};

    width: ${theme.maxwidthMobile};
    max-width: 100%;
    padding: 3rem 0;
    margin-top: ${theme.spacings.small};

    box-shadow: 0px 0px 8px ${theme.colors.gray};
  `}
`;

export const Form = styled.form`
  width: 90%;
  margin: 0 auto;

  > button,
  a {
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
`;

export const LinkForm = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    text-decoration: underline;
    margin-top: ${theme.spacings.xsmall};
    letter-spacing: 0.1rem;
  `}
`;
