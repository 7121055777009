import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { StoreState } from 'store/createStore';
import { Question } from 'store/modules/olfactoryJourney/types';
import { perfumes } from 'services/journey';
import LoadingRound from 'components/LoadingRound';

type ActivitiesProps = {
  data: Question;
};

const Activities = ({ data }: ActivitiesProps) => {
  const [selected, setSelected] = useState('');
  const [answerUser, setAnswerUser] = useState<any>();
  const { perfum_name, perfum_id } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { loading } = useSelector((state: StoreState) => state.journey);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const dispatch = useDispatch();
  const questionId = data.question_id;
  const userId = localStorage.getItem('user_id_amyi');

  function selection(ev: any) {
    const select = ev.target.innerText;
    if (data.answers_user.length <= 0) {
      setSelected(select);
    }
  }

  useEffect(() => {
    setSelected(
      data.answers_user.length >= 1 ? data.answers_user?.[0].answer_user : ''
    );
  }, []);

  function handleStep() {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: questionId,
      answers_user: [selected],
      answers_url: [],
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  return (
    <S.Content>
      <S.Wrapper>
        <S.Title>{perfum_name}</S.Title>
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <ProgressCircles position={data.question_id} />
            <S.Description>{data.title}</S.Description>
            <S.Options>
              {data.answers_avaliable.map((option) => (
                <S.Option
                  key={option.legend}
                  selected={selected === option.legend}
                  onClick={(ev) => selection(ev)}
                >
                  {option.legend}
                </S.Option>
              ))}
            </S.Options>
          </>
        )}
      </S.Wrapper>
      {selected !== '' ? (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => handleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => handleStep()} />
          )}
        </>
      ) : (
        <Button name="ENVIAR" theme="disabled" />
      )}
    </S.Content>
  );
};

export default Activities;
