import GlobalStyles from '../styles/global';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import Routes from '../routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer autoClose={7000} />
          <ThemeProvider theme={theme}>
            <Routes />
            <GlobalStyles />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
