import api from './api';
import { responseUser } from './../store/modules/olfactoryJourney/types';
import { Shipping } from 'store/modules/perfums/types';
import apiCep from './cep';

const RESOURCE = '/ranking';

export const perfumesRanking = {
  listAll: async (userId: string | null, journeyId: number) =>
    await api.get(RESOURCE + `?user_id=${userId}&journey_id=${journeyId}`),
  findOne: async (userId: string | null, journeyId: number, id: number) =>
    await api.get(
      RESOURCE +
        `/details?user_id=${userId}&journey_id=${journeyId}&perfum_id=` +
        id
    ),
  listReviewOrder: async (
    userId: string | null,
    journeyId: number,
    id: number
  ) =>
    await api.get(
      RESOURCE +
        `/choice_address?user_id=${userId}&journey_id=${journeyId}&perfum_id=` +
        id
    ),
  confirmPerfum: async (
    userId: string | null,
    journeyId: number,
    perfumNumber: number
  ) =>
    await api.post(RESOURCE + `/confirm_choice`, {
      perfum: {
        perfum_choice_id: perfumNumber,
      },
      user_id: userId,
      journey_id: journeyId,
    }),
  alterAddress: async (data: Shipping, userId: string | null) =>
    await api.put(RESOURCE + `/choice_address/confirm`, {
      shipping: data,
      user_id: userId,
    }),

  searchCep: async (cep: string) => await apiCep.get(`${cep}/json/`),
};
