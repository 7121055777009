import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const StepImages = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      text-align: center;
      max-width: 60%;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${media.greaterThan('small')`
      > button, a {
        margin-top: 30vh;
        width: 30%;
      }
    `}
  `}
`;

export const WrapperCompare = styled.div`
  width: 80%;
  margin: 2rem 0;

  > div:first-child {
    height: 240px !important;
  }

  ${media.greaterThan('small')`
    width: 30%;
  `}
`;

export const DescriptionImg = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const DescriptionImgAnswerUser = styled(DescriptionImg)`
  justify-content: center;
`;

type Position = {
  position: number;
};

export const NameImageLeft = styled.p<Position>`
  ${({ theme, position }) => css`
    color: ${position > 0.5
      ? `${theme.colors.secondBlack}`
      : `${theme.colors.gray}`};
    letter-spacing: 0.1rem;
  `}
`;

export const NameImageAnswerUser = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.1rem;
  `}
`;

export const NameImageRight = styled.p<Position>`
  ${({ theme, position }) => css`
    color: ${position < 0.5
      ? `${theme.colors.secondBlack}`
      : `${theme.colors.gray}`};
    letter-spacing: 0.1rem;
  `}
`;
