import Button from 'components/Button';
import Footer from 'components/Footer';
import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';

import spraySvg from '../../assets/images/icones/spray-can-light.svg';
import ProgressSkin from 'components/ProgressSkin';

import { MdKeyboardArrowDown } from 'react-icons/md';
import SelectSkin from 'components/SelectSkin';
import {
  perfumesRequest,
  skinTestsRequest,
  skinTestsSendingRequest,
} from 'store/modules/olfactoryJourney/actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import womenImage from 'assets/images/help.png';
import SubNav from 'components/OlfactoryJourney/SubNav';
import Loading from 'components/Loading';

function SkinTestFlux() {
  const dispatch = useDispatch();
  const {
    info_perfum,
    selectSkin,
    idSelectedSkin,
    Perfums,
    loading,
  } = useSelector((state: StoreState) => state.journey);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  useEffect(() => {
    dispatch(skinTestsRequest(userId, journeyId, info_perfum?.perfum_id));
  }, [userId, journeyId, info_perfum, dispatch]);

  useEffect(() => {
    dispatch(perfumesRequest(userId, journeyId));
  }, [dispatch, journeyId, userId]);

  const handleSelect = () => {
    if (idSelectedSkin !== undefined) {
      dispatch(skinTestsSendingRequest(userId, journeyId, idSelectedSkin));
    }
  };

  const isSevenPerfumesStatusCompleted = useMemo(() => {
    return (
      Perfums?.filter((perfum) => perfum.perfum_status === 'completed')
        ?.length >= 6
    );
  }, [Perfums]);

  return loading ? (
    <Loading />
  ) : (
    <S.Wrapper>
      <Navbar />
      <SubNav pass={4} />
      <S.Content>
        <S.Title>DICA FINAL</S.Title>
        <S.Description>
          Amou algum dos perfumes que você vivenciou?
        </S.Description>
        <S.Description>
          Então teste na pele e observe como ele irá evoluir com você ao longo
          das horas.
        </S.Description>
        <S.Image src={womenImage} />
      </S.Content>
      <Button
        name="PRÓXIMO"
        link
        to={
          isSevenPerfumesStatusCompleted ? '/dashboard' : '/olfactory-journey'
        }
      />
      <Footer />
    </S.Wrapper>
  );
}

export default SkinTestFlux;
