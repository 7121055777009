import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import { useSelector, useDispatch } from 'react-redux';

import butterfly from '../../assets/images/welcome/Butterfly.png';
import { dashboardRequest } from 'store/modules/dashboard/actions';
import { useEffect } from 'react';
import { StoreState } from '../../store/createStore';
import LoadingRound from 'components/LoadingRound';

export default function Welcome() {
  const { user, loading } = useSelector((state: StoreState) => state.dashboard);
  const dispatch = useDispatch();

  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  useEffect(() => {
    dispatch(dashboardRequest(userId, journeyId));
  }, []);

  return (
    <>
      <S.Wrapper>
        <Navbar />
        {loading ? (
          <LoadingRound />
        ) : (
          <S.Container>
            <S.Content>
              <S.Image src={butterfly} />
              <S.Title>Boas vindas, {user?.User_name}!</S.Title>
              <S.Description>
                Você está prestes a começar sua Jornada Olfativa.
              </S.Description>
              <S.Description>
                Para aproveitá-la melhor,<br></br> que tal aprender mais sobre a
                experiência e a plataforma?
              </S.Description>
              <S.ButtonWrapper>
                <Button link={true} to="/guide" name="INICIAR" />
                <Button
                  theme="secondary"
                  link={true}
                  to="/dashboard"
                  name="VER DEPOIS"
                />
              </S.ButtonWrapper>
            </S.Content>
          </S.Container>
        )}
        <Footer />
      </S.Wrapper>
    </>
  );
}
