import Navbar from 'components/Navbar';
import * as S from './styles';

import Button from 'components/Button';
import Footer from 'components/Footer';
import { useState } from 'react';
import LoadingRound from 'components/LoadingRound';
import SubNav from 'components/OlfactoryJourney/SubNav';

export type notesPerfumProps = {
  perfum_name: string;
  radar_desktop: string;
  radar_mobile: string;
};

function SelectPerfumCorrect() {
  const [loading, setLoading] = useState(false);

  return (
    <S.Wrapper>
      <Navbar />
      <SubNav pass={4} />
      <S.Container>
        {loading ? (
          <LoadingRound />
        ) : (
          <S.Content>
            <S.Title>Hora de reavaliar</S.Title>

            <S.Description>
              Saiba por que é tão importante você dedicar mais um tempinho para
              observar novamente cada perfume:
            </S.Description>

            <S.WrapperIframe>
              <S.Iframe
                src="https://player.vimeo.com/video/574616394"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></S.Iframe>
            </S.WrapperIframe>

            <Button name="PRÓXIMO" link to="/alterPerfums" />
            <Footer />
          </S.Content>
        )}
      </S.Container>
    </S.Wrapper>
  );
}

export default SelectPerfumCorrect;
