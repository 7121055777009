import styled, { css } from 'styled-components';
import media from 'styled-media-query';

type WrapperProps = {
  open: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ open }) => css`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: ${open ? '1' : '0'};
    pointer-events: ${open ? 'all' : 'none'};

    z-index: 999;
  `}
`;

export const Main = styled.div`
  ${({ theme }) => css`
    min-height: 30vh;
    width: 80vw;
    background-color: ${theme.colors.primary};
    padding: 2rem;
    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.greaterThan('small')`
      width: 30vw;
    `}
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  border: 0;
  background: none;
  color: black;

  font-size: 15px;
  font-weight: bold;
  padding: 15px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 20px;
    text-align: center;
    color: ${theme.colors.white};
    margin-top: ${theme.spacings.small};
  `}
`;
