import { Question } from '../../../store/modules/olfactoryJourney/types';
import Activities from '../Questions/Activities';
import AgreeImage from '../Questions/AgreeImage';
import CarouselCheck from '../Questions/CarouselCheck';
import ColorCircle from '../Questions/ColorCircle';
import Comment from '../Questions/Comment';
import DescVideo from '../Questions/DescVideo';
import Feedback from '../Questions/Feedback';
import ImageCircle from '../Questions/ImageCircle';
import ImageCompare from '../Questions/ImageCompare';
import ImageGrid from '../Questions/ImageGrid';
import Music from '../Questions/Music';

export function getComponentFromData(data: Question) {
  const types = {
    ColorCircle: <ColorCircle data={data} />,
    ImageCompare: <ImageCompare data={data} />,
    CarouselCheck: <CarouselCheck data={data} />,
    Comment: (
      <Comment
        data={data}
        subdescription="Pode ser que venha uma afirmação sobre você, uma pergunta, uma
        lembrança, um lugar... escreva o que vier, vale tudo!"
      />
    ),
    ImageCircle: <ImageCircle data={data} />,
    DescVideo: <DescVideo data={data} />,
    FeedBack: <Feedback data={data} />,
    Activities: <Activities data={data} />,
    AgreeImage: <AgreeImage data={data} />,
    Music: <Music data={data} />,
    ImageGrid: (
      <ImageGrid
        data={data}
        information="(Selecione quantas alteranativas quiser)"
      />
    ),
  };

  return types[data.component_name] || null;
}
