import Button from 'components/Button';
import Footer from 'components/Footer';
import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';

import spraySvg from '../../assets/images/icones/spray-can-light.svg';
import ProgressSkin from 'components/ProgressSkin';

import { MdKeyboardArrowDown } from 'react-icons/md';
import SelectSkin from 'components/SelectSkin';
import {
  skinTestsRequest,
  skinTestsSendingRequest,
} from 'store/modules/olfactoryJourney/actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';

function SkinTest() {
  const dispatch = useDispatch();
  const { info_perfum, selectSkin, idSelectedSkin } = useSelector(
    (state: StoreState) => state.journey
  );
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  useEffect(() => {
    dispatch(skinTestsRequest(userId, journeyId, info_perfum?.perfum_id));
  }, [userId, journeyId, info_perfum, dispatch]);

  const handleSelect = () => {
    if (idSelectedSkin !== undefined) {
      dispatch(skinTestsSendingRequest(userId, journeyId, idSelectedSkin));
    }
  };

  return (
    <S.Wrapper>
      <Navbar />
      <NavBack title="teste na pele" />
      <S.Content>
        {/* <S.Description>
          Entenda como sentir as diferenças das notas do perfume em contato com
          a sua pele ao longo do tempo:
        </S.Description>
        <S.Iframe
          src="https://player.vimeo.com/video/374423519"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></S.Iframe> */}
        <S.Title>Amou algum perfume? Teste na pele!</S.Title>
        <S.Description>
          Ative o Alerta Amyi para receber mensagens na hora certa de sentir
          novamente cada perfume.
        </S.Description>
        <S.Description>
          Lembraremos você em 3 momentos diferentes: após 30 minutos, 3 horas e
          6 horas.
        </S.Description>
        <S.Box>
          <S.Title>Fazer teste na pele</S.Title>
          <S.Description>
            Recomendamos fazer o Teste na Pele com dois perfumes por vez. Você
            poderá retornar para inciar novos testes.
          </S.Description>
          <SelectSkin items={selectSkin} />

          {/* <ProgressSkin progress={4} /> */}
          <Button
            name="ATIVAR LEMBRETE"
            theme="history"
            onClick={() => handleSelect()}
          />
        </S.Box>
      </S.Content>
      <Footer />
    </S.Wrapper>
  );
}

export default SkinTest;
