import media from 'styled-media-query';
import styled, { css } from 'styled-components';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Iframe = styled.iframe`
  margin: 2rem 0;

  ${media.greaterThan('small')`
    width: 600px !important;  
    height: 300px !important;  
  `}
`;

export const GridInformations = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;

    width: ${theme.maxwidthMobile};
    max-width: 100%;
  `}
`;

export const Image = styled.img`
  height: 7rem;
`;

export const StepTips = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${media.greaterThan('small')`
      button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Information = styled.div`
  ${({ theme }) => css`
    text-align: center;

    ${Image} {
      height: 3rem;
      margin: 0;
    }

    ${Title} {
      font-size: ${theme.font.sizes.xsmall};
      font-family: ${theme.font.family.YaldeviMedium};
      font-weight: normal;
      margin-top: 1rem;
      letter-spacing: 0.1rem;
    }

    ${Description} {
      font-size: ${theme.font.sizes.xsmall};

      font-family: ${theme.font.family.YaldeviMedium};
      max-width: 100%;
      margin: 0;
    }
  `}
`;
