import styled, { css, DefaultTheme } from 'styled-components';

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      transform: translateY(5px);
      width: 8rem;
      height: 0.2rem;
      background-color: ${theme.colors.black};
      border-radius: 1rem;
      animation: hoverAnimation 0.2s forwards;
    }

    &:first-child {
      &:after {
        width: 6rem;
      }
    }

    @keyframes hoverAnimation {
      from {
        width: 0;
        left: 50%;
      }
      to {
        width: 8rem;
        left: 0;
      }
    }
  `,
};

export const Wrapper = styled.div``;

export const Tabs = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.white};
    margin-top: 2rem;
  `}
`;

type OptionProps = {
  active?: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ theme, active }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: ${theme.font.family.YaldeviMedium};
    cursor: pointer;
    flex: 1;

    ${!!active && wrapperModifiers.active(theme)}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: 2rem 0;
  `}
`;
