import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.nav`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    margin: 0 auto;
    height: 6rem;
    max-width: 100%;
  `}
`;

export const ContentNav = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    height: 6rem;
    width: ${theme.maxwidthMobile};
    max-width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const Logo = styled.img`
  width: 8rem;
  margin-top: 1rem;
`;

export const MenuLinks = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.primary};
  `}
`;

type secondMenuProps = {
  open: boolean;
};

export const SecondMenu = styled.div<secondMenuProps>`
  ${({ theme, open }) => css`
    transition: opacity 0.5s ease;
    opacity: ${open ? 1 : 0};
    pointer-events: ${open ? 'all' : 'none'};
    position: absolute;
    top: 6rem;
    height: 91vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  `}
`;

export const Content = styled.div<secondMenuProps>`
  ${({ theme, open }) => css`
    position: absolute;
    opacity: ${open ? 1 : 0};
    pointer-events: ${open ? 'all' : 'none'};
    transition: opacity 0.5s ease;

    top: 0.1rem;
    right: 0.2rem;
    width: 70%;
    background-color: ${theme.colors.white};

    ${media.greaterThan('small')`
      width: 20%;
      right: 9rem;
    `}
  `}
`;

export const LinkMenu = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;
    color: ${theme.colors.secondBlack};
    display: block;
    padding: 1rem 2rem;

    &:hover {
      background-color: ${theme.colors.secondBlack};
      color: ${theme.colors.white};
    }

    &:last-child {
      color: red;
    }
  `}
`;
