import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

import * as S from './styles';

import Button from 'components/Button';
import Amyi1 from '../../assets/images/perfumes/amyi1_on.png';
import { useEffect, useState } from 'react';
import NavBack from 'components/NavBack';
import { useSelector, useDispatch } from 'react-redux';
import {
  perfumesRequest,
  perfumsSelected,
  resetPerfum,
  resetStep,
} from 'store/modules/olfactoryJourney/actions';
import { StoreState } from 'store/createStore';

import checkCircle from '../../assets/images/icones/check-circle-light.svg';
import pendingSvg from '../../assets/images/icones/info.svg';
import Loading from 'components/Loading';
import { Perfum } from 'store/modules/olfactoryJourney/types';
import history from 'utils/history';

export default function OlfactoryJourney() {
  const dispatch = useDispatch();

  const { Perfums, loading, step } = useSelector(
    (state: StoreState) => state.journey
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  const [selected, setSelected] = useState<number[]>([]);
  const [perfumsCompleted, setPerfumesCompleted] = useState(false);

  useEffect(() => {
    dispatch(perfumesRequest(userId, journeyId));
    dispatch(resetPerfum());
    dispatch(resetStep());
  }, []);

  useEffect(() => {
    dispatch(perfumsSelected(selected));
  }, [selected]);

  function handleSelectedItem(id: number, status: string) {
    const alredySelected = selected.findIndex((item) => item === id);
    const isLenghtMax = selected.length < 3;

    if (alredySelected >= 0) {
      const filteredItems = selected.filter((item) => item !== id);
      setSelected(filteredItems);
    } else {
      if (isLenghtMax && status !== 'completed') {
        setSelected([...selected, id]);
      }
    }
  }

  useEffect(() => {
    const arrayPerfums = Perfums?.filter((perfum) => {
      return perfum.perfum_status === 'completed';
    });

    if (arrayPerfums.length >= 3) {
      setPerfumesCompleted(true);
    } else {
      setPerfumesCompleted(false);
    }

    if (Perfums.length === arrayPerfums.length) {
      history.push('/dashboard');
    }
  }, [Perfums]);

  return (
    <>
      <S.Wrapper>
        <Navbar />
        <NavBack title="jornada sensorial e educativa" />
        <S.Container>
          {loading ? (
            <Loading />
          ) : (
            <>
              <S.Main>
                <S.SubTitle>
                  Selecione 3 perfumes do seu kit para iniciar
                </S.SubTitle>

                <S.Info>
                  Recomendamos viver um trio por dia para não saturar o seu
                  olfato e<br /> garantir uma experiência prazerosa.
                </S.Info>

                <S.Divider />

                <S.Grid>
                  {Perfums !== undefined && (
                    <>
                      {Perfums.map((perfume) => (
                        <S.Box
                          status={perfume.perfum_status}
                          key={perfume.perfum_number}
                          onClick={() =>
                            handleSelectedItem(
                              perfume.perfum_number,
                              perfume.perfum_status
                            )
                          }
                          selected={selected.includes(perfume.perfum_number)}
                        >
                          <S.Title>{perfume.perfum_name}</S.Title>
                        </S.Box>
                      ))}
                    </>
                  )}
                </S.Grid>

                <S.ButtonWrapper>
                  {selected.length === 3 ? (
                    <Button
                      name="INICIAR"
                      to={
                        perfumsCompleted
                          ? '/olfactory-journey/playeducational'
                          : '/olfactory-journey/guide'
                      }
                      link
                    />
                  ) : (
                    <>
                      <Button name="INICIAR" theme="disabled" />
                      <S.Info>Selecione três perfumes para iniciar</S.Info>
                    </>
                  )}
                </S.ButtonWrapper>
              </S.Main>
            </>
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
}
