import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import { useState } from 'react';
import * as S from './styles';

import { RiArrowUpSLine } from 'react-icons/ri';

import playSvg from 'assets/images/icones/play-circle-light.svg';
import { ItemBox } from 'pages/Requests/Details/styles';

type ToggleWatchProps = {
  title?: string;
  url?: string;
};

export default function ToggleWatch({
  title = 'Insira um Título',
  url,
}: ToggleWatchProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <S.Wrapper>
      <S.Header onClick={handleOpen}>
        <S.Title>{title}</S.Title>
        {!open && <S.Svg src={playSvg} />}
      </S.Header>
      {open && (
        <S.Body>
          <S.Iframe src={url}></S.Iframe>
          <RiArrowUpSLine size={20} color="#8E8E8E" onClick={handleOpen} />
        </S.Body>
      )}
    </S.Wrapper>
  );
}
