import Navbar from 'components/Navbar';
import * as S from './styles';
import { GrFormClose } from 'react-icons/gr';

import amyi1 from '../../../assets/images/perfumes/amyi1.png';
import familia from '../../../assets/images/perfumes/details/familia.png';
import intensidade from '../../../assets/images/perfumes/details/intensidade.png';
import inovacao from '../../../assets/images/perfumes/details/inovacao.png';
import Footer from 'components/Footer';

import ProgressSkin from 'components/ProgressSkin';
import GraficPerfume from 'components/GraficPerfume';
import { useSelector, useDispatch } from 'react-redux';
import { perfumsDetailsRequest } from 'store/modules/perfums/actions';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import MediaMatch from 'components/MediaMatch';

const JourneyResponses = () => {
  const experience = true;
  const { perfumsDetails, loading } = useSelector(
    (state: StoreState) => state.perfums
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  interface IdParams {
    id: string;
  }
  const { id } = useParams<IdParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(perfumsDetailsRequest(userId, journeyId, Number(id)));
  }, [id]);

  return (
    <S.Wrapper>
      <Navbar />

      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <S.Closed to="/history">
            <GrFormClose size={28} />
          </S.Closed>
          {perfumsDetails.perfum_img && (
            <S.Image
              src={
                process.env.REACT_APP_BASEURL_IMAGES + perfumsDetails.perfum_img
              }
            />
          )}
          <S.Title>{perfumsDetails?.perfum_name}</S.Title>
          <S.SubTitle>{perfumsDetails?.perfum_ingredients}</S.SubTitle>
          <S.Description>{perfumsDetails?.perfum_description}</S.Description>

          <S.Infos>
            <S.Box>
              <S.Image src={familia} />
              <S.Title>Família olfativa:</S.Title>
              <S.Description>{perfumsDetails?.perfum_family}</S.Description>
            </S.Box>

            <S.Box>
              <S.Image src={intensidade} />
              <S.Title>Intensidade:</S.Title>
              <S.Description>{perfumsDetails?.perfum_intensity}</S.Description>
            </S.Box>

            <S.Box>
              <S.Image src={inovacao} />
              <S.Title>Inovação:</S.Title>
              <S.Description>{perfumsDetails?.perfum_inovation}</S.Description>
            </S.Box>
          </S.Infos>
          <S.Text>{perfumsDetails?.perfum_type}</S.Text>

          {perfumsDetails.exist_answers && (
            <S.ExperienceBox>
              <S.Title>Primeira impressão</S.Title>

              {perfumsDetails.answers && (
                <>
                  {perfumsDetails.answers.map((item) => (
                    <S.Box key={item.title}>
                      <S.Title>{item.title}</S.Title>
                      {item.color ? (
                        <S.BoxColor>
                          {item.answers.map((item) => (
                            <S.Color key={item} cor={item} />
                          ))}
                        </S.BoxColor>
                      ) : (
                        <>
                          {item.answers.map((item) => (
                            <S.Description key={item}>{item}</S.Description>
                          ))}
                        </>
                      )}
                    </S.Box>
                  ))}
                </>
              )}
            </S.ExperienceBox>
          )}

          <S.OlfactoryEvolution>
            <S.Title>Evolução olfativa:</S.Title>
            <S.Description>
              Confira como as principais notas desse perfume vão se dissipar ao
              longo do tempo. A cada momento, novos cheiros vão se revelar a
              você!
            </S.Description>

            <MediaMatch lessThan="small">
              <S.GraficImage
                src={
                  process.env.REACT_APP_BASEURL_IMAGES +
                  perfumsDetails.radar_mobile
                }
              />
            </MediaMatch>
            <MediaMatch greaterThan="small">
              <S.GraficImage
                src={
                  process.env.REACT_APP_BASEURL_IMAGES +
                  perfumsDetails.radar_desktop
                }
              />
            </MediaMatch>

            {/* <GraficPerfume /> */}
          </S.OlfactoryEvolution>
          <S.PlusPerfume>
            <S.Title>Mais sobre o perfume:</S.Title>
            <S.Description>
              Conheça a inspiração do perfumista e a combinação de ingredientes
            </S.Description>
            <S.Iframe
              src={perfumsDetails.perfum_video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></S.Iframe>
          </S.PlusPerfume>
          <Footer />
        </>
      )}
    </S.Wrapper>
  );
};

export default JourneyResponses;
