import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import media from 'styled-media-query';

export const LinkHeader = styled(Link)``;

export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.secondGray};
    background-color: ${theme.colors.white};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    width: 60rem;
    margin: 0 auto;
    background-color: ${theme.colors.white};
    padding: 1rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    ${media.lessThan('small')`
      width: 100%;
    `}
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    width: 1.5rem;
    height: 1.5rem;
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.large};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    text-align: center;
    width: 100%;
    letter-spacing: 0.2rem;
  `}
`;
