import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    .disabled {
      width: 1rem;
      height: 1rem;
      background-color: ${theme.colors.gray};

      ${media.lessThan('small')`
        width: 0.8rem;
        height: 0.8rem;
      `}
    }

    .active {
      span {
        text-align: center;
      }
    }

    .center {
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      height: 4rem;
    `}
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraVariable};
    margin-right: 0.5rem;
    font-weight: bold;

    ${media.lessThan('small')`
      font-size: 1.2rem;
    `}
  `}
`;

export const BoxLeft = styled.div`
  width: 80%;
  display: flex;
  gap: 20rem;

  ${media.lessThan('small')`
    gap: 0;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
  `}
`;

export const Circles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  ${media.lessThan('small')`
    justify-content: flex-start;
    gap: 3rem;
  `}
`;

type CircleProps = {
  pass?: number;
};

export const Circle = styled.div<CircleProps>`
  ${({ theme, pass }) => css`
    background-color: ${theme.colors.white};
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin: 0 0.5rem;

    font-weight: bold;
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:first-child {
      &:before {
        display: none;
      }
    }

    &:before {
      content: '';

      position: absolute;
      width: 9rem;
      transform: ${pass === 4 || pass === 2 || pass === 3
        ? 'translateX(-6rem)'
        : 'translateX(-5rem)'};
      height: 0.1rem;
      background-color: ${theme.colors.gray};
    }

    span {
      transform: ${pass === 2 && `translateX(-1px);`};
      transform: ${pass === 3 && `translateX(-1px);`};
      transform: ${pass === 4 && `translateX(-1px);`};
    }

    ${media.lessThan('small')`
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.2rem;

      &:before {
        width: 4rem;
        transform: ${
          pass === 4 || pass === 2 || pass === 3
            ? 'translateX(-3rem)'
            : 'translateX(-2rem)'
        };
      }

      span {
        transform: translateX(0);
      }
    `}
  `}
`;

export const BoxRight = styled.div`
  margin-right: 1rem;
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    width: 2rem;
    height: 2.5rem;
    color: ${theme.colors.secondBlack};
  `}
`;
