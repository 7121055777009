import * as S from './styles';
import ReactStars from 'react-rating-stars-component';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { useContext, useEffect, useState } from 'react';
import { NotesFinallyContext } from './notesFinallyContext';

type infoProps = {
  first_rating: string;
  perfum_id: number;
  perfum_name: string;
};

type StarsProps = {
  info: infoProps;
};

const StarsVote = ({ info }: StarsProps) => {
  const [rating, setRating] = useState(0);
  const [nameRating, setNameRating] = useState('');
  const [answersRating, setAnswersRating] = useState<number>(0);
  const [showComponent, setShowComponent] = useState(false);

  const { addNotes } = useContext(NotesFinallyContext);

  const ratingChanged = (newRating: any) => {
    setRating(newRating);
    addNotes(info.perfum_id, newRating);
  };

  useEffect(() => {
    setRating(Number(info.first_rating));
  }, [info]);

  useEffect(() => {
    setShowComponent(true);
  }, []);

  type Notes = {
    note: 'naogostei' | 'gosteipouco' | 'gostei' | 'gosteimuito' | 'amei';
  };

  const notes: Notes[] = [
    {
      note: 'naogostei',
    },
    {
      note: 'gosteipouco',
    },
    {
      note: 'gostei',
    },
    {
      note: 'gosteimuito',
    },
    {
      note: 'amei',
    },
  ];

  useEffect(() => {
    switch (answersRating) {
      case 1:
        setNameRating('não gostei');
        break;
      case 2:
        setNameRating('não é pra mim');
        break;
      case 3:
        setNameRating('gostosinho');
        break;
      case 4:
        setNameRating('delícia');
        break;
      case 5:
        setNameRating('amei');
        break;
    }
  }, [answersRating]);

  useEffect(() => {
    switch (rating) {
      case 1:
        setNameRating('não gostei');
        break;
      case 2:
        setNameRating('não é pra mim');
        break;
      case 3:
        setNameRating('gostosinho');
        break;
      case 4:
        setNameRating('delícia');
        break;
      case 5:
        setNameRating('amei');
        break;
    }
  }, [rating]);

  return (
    <S.Wrapper>
      <S.Vote>
        {showComponent && (
          <ReactStars
            count={5}
            onChange={ratingChanged}
            activeColor="#000"
            value={rating}
            color="#EBEBEB"
            size={30}
            filledIcon={AiFillStar}
            emptyIcon={AiOutlineStar}
          />
        )}
        {rating !== 0 && (
          <S.Tag note={notes[rating - 1]?.note}>
            <p>{nameRating}</p>
          </S.Tag>
        )}
      </S.Vote>
    </S.Wrapper>
  );
};

export default StarsVote;
