import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import playCircle from '../../assets/images/icones/play-circle-light.svg';
import iconHeart from '../../assets/images/icones/heart-light.svg';
import iconHistory from '../../assets/images/icones/hourglass-half-light.svg';
import bookSolid from '../../assets/images/icones/book-solid.svg';
import informationIcon from '../../assets/images/icones/information-icon.svg';
import giftcard from '../../assets/images/icones/gift-card-light.svg';
import perfumSelected from '../../assets/images/icones/perfumSelected.png';
import videoPlayTwo from '../../assets/images/icones/videos.png';
import ArrowLeft from 'assets/images/icones/left-arrow.svg';

import { useSelector, useDispatch } from 'react-redux';

import { useEffect, useState } from 'react';
import { StoreState } from 'store/createStore';
import { dashboardRequest } from 'store/modules/dashboard/actions';
import LoadingRound from 'components/LoadingRound';

export type newBoxProps = {
  boxStatus?: 'initial' | 'medium' | 'completed';
};

export default function Dashboard() {
  const { user, product, loading } = useSelector(
    (state: StoreState) => state.dashboard
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const [progress, setProgress] = useState(0);
  const [statusProgress, SetStatusProgress] = useState<newBoxProps>({
    boxStatus: 'initial',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardRequest(userId, journeyId));
  }, []);

  useEffect(() => {
    setProgress(Number(user?.Progress_journey));

    progress < 95 && SetStatusProgress({ boxStatus: 'initial' });
    progress >= 95 && SetStatusProgress({ boxStatus: 'completed' });
  }, [progress, user]);

  return (
    <>
      <S.Wrapper>
        <Navbar />
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <S.Header>
              <S.WrapperTitle>
                <S.Title>Olá, {user?.User_name}</S.Title>
                <S.LinkHeader to="/guide">
                  <S.Svg src={informationIcon} />
                </S.LinkHeader>
              </S.WrapperTitle>

              <S.Description>Chegou a hora, vamos começar?</S.Description>
            </S.Header>

            <S.Container>
              <S.NewBox
                to={progress === 100 ? '#' : '/olfactory-journey'}
                boxStatus={statusProgress.boxStatus}
              >
                <div>
                  <S.BoxSvg>
                    <S.Svg src={playCircle} />
                  </S.BoxSvg>
                  <S.Infos>
                    <S.Title>Jornada sensorial e educativa</S.Title>
                    <S.Description>
                      Experimente seus perfumes com a ajuda de um roteiro guiado
                    </S.Description>
                  </S.Infos>
                </div>
                <S.WrapperProgress>
                  <S.Title>{product?.Product_type}</S.Title>
                  <div>
                    <S.ProgressBar progress={progress} />
                    <S.Title>{progress}%</S.Title>
                  </div>
                </S.WrapperProgress>
              </S.NewBox>

              <S.Box
                to="/perfumes"
                boxStatus={
                  !product.Choice_avaliable && product?.experience_full
                    ? 'completed'
                    : 'none'
                }
                borderActive={
                  (progress === 100 && product.Choice_avaliable) ||
                  (progress === 100 && !product?.experience_full)
                }
              >
                {!product.Choice_avaliable && product?.experience_full ? (
                  <S.IconPerfum src={perfumSelected} />
                ) : (
                  <S.Svg src={iconHeart} />
                )}

                <S.Infos>
                  <S.Title>Escolha seu perfume</S.Title>
                  <S.Description
                    dangerouslySetInnerHTML={{ __html: product?.Product_msg }}
                  ></S.Description>
                </S.Infos>
              </S.Box>

              {progress === 100 &&
                !product.Choice_avaliable &&
                product?.experience_full && (
                  <>
                    {/* <S.Box to="/curiosityfacts">
                    <S.Svg src={lightbuld} />
                    <S.Infos>
                      <S.Title>Curiosidades e fatos</S.Title>
                      <S.Description>
                        Aprenda mais sobre o universo dos perfumes
                      </S.Description>
                    </S.Infos>
                  </S.Box> */}

                    <S.Box to="/clubamyi" borderActive>
                      <S.Svg src={giftcard} />
                      <S.Infos>
                        <S.Title>Clube Amyi</S.Title>
                        <S.Description>
                          Você faz parte do nosso clube de benefícios e
                          vantagens especiais!
                        </S.Description>
                      </S.Infos>
                    </S.Box>
                  </>
                )}

              {/* <S.Box to="/skintest">
                <S.Svg src={iconSpray} />

                <S.Infos>
                  <S.Title>teste na pele</S.Title>
                  <S.Description>
                    Ative os lembretes do relógio olfativo amyi
                  </S.Description>
                </S.Infos>
              </S.Box> */}

              <S.Box to="/history">
                <S.Svg src={iconHistory} style={{ width: '1.5rem' }} />

                <S.Infos>
                  <S.Title>Histórico</S.Title>
                  <S.Description>
                    Veja como você se conectou com cada perfume
                  </S.Description>
                </S.Infos>
              </S.Box>

              <S.Box to="/watch">
                <S.IconPerfum src={videoPlayTwo} />

                <S.Infos>
                  <S.Title>Vídeos</S.Title>
                  <S.Description>
                    Assista os vídeos dos perfumistas quando quiser
                  </S.Description>
                </S.Infos>
              </S.Box>

              <S.MenuPrincipal to="/">
                <S.Svg src={ArrowLeft} />
                menu principal
              </S.MenuPrincipal>
              <Footer />
            </S.Container>
          </>
        )}
      </S.Wrapper>
    </>
  );
}
