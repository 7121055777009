import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import * as S from './styles';
import ReactStars from 'react-rating-stars-component';

import fiveStar from 'assets/images/icones/5star.png';
import fourStar from 'assets/images/icones/4star.png';
import threeStar from 'assets/images/icones/3star.png';
import twoStar from 'assets/images/icones/2star.png';
import oneStar from 'assets/images/icones/1star.png';

import { useDispatch, useSelector } from 'react-redux';

import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import {
  finishJourneyHistory,
  nextPerfum,
  nextStep,
  openModalFinish,
  resetStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { StoreState } from 'store/createStore';
import { Question } from 'store/modules/olfactoryJourney/types';
import LoadingRound from 'components/LoadingRound';
import history from 'utils/history';

type FeedbackProps = {
  data: Question;
};

const Feedback = ({ data }: FeedbackProps) => {
  const [rating, setRating] = useState(1);
  const [showComponent, setShowComponent] = useState(false);
  const [answersRating, setAnswersRating] = useState<number>(1);
  const [nameRating, setNameRating] = useState('');
  const { perfum_id, perfum_name } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { loading, stepHistory } = useSelector(
    (state: StoreState) => state.journey
  );
  const dispatch = useDispatch();
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  const ratingChanged = (newRating: any) => {
    setRating(newRating);
  };

  const ratingSecond = (newRating: any) => {
    setRating(newRating);
  };

  const handleStepHistory = () => {
    dispatch(finishJourneyHistory());
    history.push('/history');
  };

  function handleStep() {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: [rating],
      answers_url: [],
    };

    data.answers_user.length >= 1
      ? dispatch(openModalFinish())
      : dispatch(responseUserRequest(responseUser)) &&
        dispatch(openModalFinish());
  }

  useEffect(() => {
    setAnswersRating(
      data.answers_user.length >= 1
        ? Number(data.answers_user?.[0]?.answer_user)
        : 0
    );
  }, [data, answersRating]);

  type Notes = {
    note: 'naogostei' | 'gosteipouco' | 'gostei' | 'gosteimuito' | 'amei';
  };

  const notes: Notes[] = [
    {
      note: 'naogostei',
    },
    {
      note: 'gosteipouco',
    },
    {
      note: 'gostei',
    },
    {
      note: 'gosteimuito',
    },
    {
      note: 'amei',
    },
  ];

  useEffect(() => {
    switch (rating) {
      case 1:
        setNameRating('não gostei');
        break;
      case 2:
        setNameRating('não é pra mim');
        break;
      case 3:
        setNameRating('gostosinho');
        break;
      case 4:
        setNameRating('delícia');
        break;
      case 5:
        setNameRating('amei');
        break;
    }
  }, [rating]);

  useEffect(() => {
    switch (answersRating) {
      case 1:
        setNameRating('não gostei');
        break;
      case 2:
        setNameRating('não é pra mim');
        break;
      case 3:
        setNameRating('gostosinho');
        break;
      case 4:
        setNameRating('delícia');
        break;
      case 5:
        setNameRating('amei');
        break;
    }
  }, [answersRating]);

  useEffect(() => {
    answersRating >= 1 && setShowComponent(true);
  }, [answersRating]);

  return (
    <>
      <S.StepFeedback>
        <S.Title>{perfum_name}</S.Title>
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <ProgressCircles position={data.question_id} />
            <S.Description>{data.title}</S.Description>

            <S.Vote>
              {data.answers_user.length >= 1 ? (
                <>
                  {showComponent && (
                    <div style={{ pointerEvents: 'none' }}>
                      <ReactStars
                        count={5}
                        activeColor="#000"
                        value={answersRating}
                        color="#EBEBEB"
                        size={30}
                        filledIcon={AiFillStar}
                        emptyIcon={AiOutlineStar}
                      />
                    </div>
                  )}
                  <S.Tag note={notes[answersRating - 1].note}>
                    <p>{nameRating}</p>
                  </S.Tag>
                </>
              ) : (
                <>
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    value={rating}
                    activeColor="#000"
                    color="#EBEBEB"
                    size={30}
                    filledIcon={AiFillStar}
                    emptyIcon={AiOutlineStar}
                  />
                  <S.Tag note={notes[rating - 1].note}>
                    <p>{nameRating}</p>
                  </S.Tag>
                </>
              )}
            </S.Vote>
            {/* <S.Details>
              <S.Line>
                <S.Description>Amei! Não vai dar pra viver sem!</S.Description>
                <S.Image src={fiveStar} />
              </S.Line>
              <S.Line>
                <S.Description>Nossa, que delicia de perfume!</S.Description>
                <S.Image src={fourStar} />
              </S.Line>
              <S.Line>
                <S.Description>Até que é gostosinho.</S.Description>
                <S.Image src={threeStar} />
              </S.Line>
              <S.Line>
                <S.Description>Hmmm, não é meu estilo.</S.Description>
                <S.Image src={twoStar} />
              </S.Line>
              <S.Line>
                <S.Description>
                  Realmente não gostei desse perfume.
                </S.Description>
                <S.Image src={oneStar} />
              </S.Line>
            </S.Details> */}
          </>
        )}
      </S.StepFeedback>
      <S.WrapperButton>
        {data.answers_user.length >= 1 ? (
          <>
            {stepHistory ? (
              <Button name="CONTINUAR" onClick={() => handleStep()} />
            ) : (
              <Button name="CONTINUAR" onClick={() => handleStep()} />
            )}
          </>
        ) : (
          <Button name="ENVIAR" onClick={() => handleStep()} />
        )}
      </S.WrapperButton>
    </>
  );
};

export default Feedback;
