import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import { FaSearchPlus } from 'react-icons/fa';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { GrFormClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import { useEffect, useState } from 'react';
import { accountOrdersRequest } from 'store/modules/account/actions';
import { Order } from 'store/modules/account/types';
import LoadingRound from 'components/LoadingRound';

function MyRequests() {
  const { orders, loading } = useSelector((state: StoreState) => state.account);

  const userId = localStorage.getItem('user_id_amyi');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountOrdersRequest(userId));
  }, []);

  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Content>
          <S.Closed to="/account">
            <GrFormClose size={28} />
          </S.Closed>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              {orders?.length ? (
                <>
                  <S.Title>Meus Pedidos</S.Title>
                  <S.WrapperOrders>
                    {orders.map((order) => (
                      <div key={order.order_number}>
                        <S.SubTitle>
                          Meu pedido #{order.order_number}
                        </S.SubTitle>
                        <S.BoxRequest>
                          <S.Grid>
                            <S.BoxGrid>
                              <S.Title>Total</S.Title>
                              <S.SubTitle>R$ {order.order_total}</S.SubTitle>
                            </S.BoxGrid>

                            <S.BoxGrid>
                              <S.Title>Data da Compra</S.Title>
                              <S.SubTitle>{order.order_created}</S.SubTitle>
                            </S.BoxGrid>

                            <S.BoxGrid>
                              <S.Title>Status</S.Title>
                              <S.SubTitle>
                                {order.order_status}
                                {/* <Link
                                  to={`requests/moviment/${order.order_number}`}
                                >
                                  <FaSearchPlus size={15} />
                                </Link> */}
                              </S.SubTitle>
                            </S.BoxGrid>

                            <S.BoxGrid>
                              <S.Title>Última Movimentação</S.Title>
                              <S.SubTitle>{order.order_updated}</S.SubTitle>
                            </S.BoxGrid>
                          </S.Grid>
                          <S.Description>
                            {order.order_status_msg}
                          </S.Description>
                          <Button
                            name="MAIS DETALHES"
                            link
                            theme="secondary"
                            to={`requests/details/${order.order_number}`}
                          />
                        </S.BoxRequest>
                      </div>
                    ))}
                  </S.WrapperOrders>
                </>
              ) : (
                <S.WrapperOrdersNull>
                  <S.Title>Nenhum pedido encontrado!</S.Title>
                </S.WrapperOrdersNull>
              )}
            </>
          )}
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}

export default MyRequests;
