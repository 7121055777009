import * as S from './styles';
import { AiOutlineLoading } from 'react-icons/ai';

const LoadingRound = () => (
  <S.Container>
    <AiOutlineLoading size={50} />
  </S.Container>
);

export default LoadingRound;
