import { createContext, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import { SecondNoteUser } from 'store/modules/olfactoryJourney/types';

type NotesFinallyProps = {
  children: ReactNode;
};

type NotesFinallyData = {
  notesFinally: SecondNoteUser[];
  addNotes: (id: number, newRating: number) => void;
};

export const NotesFinallyContext = createContext<NotesFinallyData>(
  {} as NotesFinallyData
);

export const NotesFinallyProvider = ({ children }: NotesFinallyProps) => {
  const [notesFinally, setNotesFinally] = useState<SecondNoteUser[]>([]);
  const { second_notes } = useSelector((state: StoreState) => state.journey);

  useEffect(() => {
    second_notes.length === 3 &&
      setNotesFinally([
        {
          perfum_id: second_notes[0].perfum_id,
          second_rating: Number(second_notes[0].first_rating),
        },
        {
          perfum_id: second_notes[1].perfum_id,
          second_rating: Number(second_notes[1].first_rating),
        },
        {
          perfum_id: second_notes[2].perfum_id,
          second_rating: Number(second_notes[2].first_rating),
        },
      ]);
  }, [second_notes]);

  function addNotes(id: number, newRating: number) {
    const existArray = notesFinally.findIndex((item) => item.perfum_id === id);
    if (existArray >= 0) {
      const filteredItems = notesFinally.findIndex((item) => {
        if (item.perfum_id === id) {
          return item;
        }
      });
      notesFinally.splice(filteredItems, 1);
      setNotesFinally([
        ...notesFinally,
        { perfum_id: id, second_rating: newRating },
      ]);
    } else {
      setNotesFinally([
        ...notesFinally,
        { perfum_id: id, second_rating: newRating },
      ]);
    }
  }

  return (
    <NotesFinallyContext.Provider value={{ notesFinally, addNotes }}>
      {children}
    </NotesFinallyContext.Provider>
  );
};
