import Button from 'components/Button';
import Modal from 'components/Modal';
import * as S from './styles';
import { ReactComponent as InformationSvg } from 'assets/images/icones/information.svg';
import { useEffect, useMemo, useState } from 'react';
import { ListPerfum, PerfumsRanking } from 'store/modules/perfums/types';
import api from 'services/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import history from 'utils/history';

type ModalProductNotFound = {
  open?: boolean;
  closeModal: () => void;
  product?: ListPerfum;
};

export const ModalProductNotFound = ({
  open = false,
  product,
  closeModal,
}: ModalProductNotFound) => {
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const { journeyId } = useSelector((state: StoreState) => state.account);

  const [formData, setFormData] = useState({
    client_name: '',
    client_email: '',
    client_phone: '',
  });

  const changeFormData = (key: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fieldsNotFilled = useMemo(() => {
    return (
      !formData.client_name || !formData.client_email || !formData.client_phone
    );
  }, [formData]);

  const closeModalSuccess = () => {
    setOpenModalSuccess(false);
    history.push('/perfumes');
  };

  const sendFormData = async () => {
    const userId = localStorage.getItem('user_id_amyi');
    try {
      await api.post('/waiting-list', {
        ...formData,
        user_id: userId,
        journey_id: journeyId,
        product_id: product?.perfum_id,
      });

      setOpenModalSuccess(true);
      closeModal();
    } catch (err: any) {
      toast.error(err?.data?.message);
    }
  };

  return (
    <>
      <Modal open={open} closeModal={() => closeModal()}>
        <S.ModalMain>
          <InformationSvg />
          <S.Title>
            O {product?.perfum_name} encontra-se
            <br /> esgotado no momento :(
          </S.Title>
          <S.Description>
            Deseja ser o primeiro a saber
            <br /> quando ele voltar?
            <br /> Preencha o formulário abaixo:
          </S.Description>

          <S.WrapperForm>
            <S.WrapperInput>
              <S.Label htmlFor="surName">Nome:</S.Label>
              <S.Input
                mask=""
                type="text"
                placeholder="Digite aqui..."
                onChange={(e) => changeFormData('client_name', e.target.value)}
                required
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <S.Label htmlFor="surName">Email:</S.Label>
              <S.Input
                mask=""
                type="text"
                placeholder="Digite aqui..."
                onChange={(e) => changeFormData('client_email', e.target.value)}
                required
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <S.Label htmlFor="surName">Telefone:</S.Label>
              <S.Input
                mask="(99)99999-9999"
                id="surName"
                type="text"
                placeholder="Digite aqui..."
                onChange={(e) => changeFormData('client_phone', e.target.value)}
                required
              />
            </S.WrapperInput>
          </S.WrapperForm>

          <S.Description>
            O seu prazo de escolha será estendido até que esse
            <br /> produto esteja disponível novamente. <br />
            Para encaminhar uma dúvida por e-mail{' '}
            <a href="mailto:hello@amyi.co">clique aqui.</a>
          </S.Description>
          <Button
            name="AVISE-ME QUANDO CHEGAR"
            theme={fieldsNotFilled ? 'disabled' : 'primary'}
            onClick={sendFormData}
          />
        </S.ModalMain>
      </Modal>

      <Modal open={openModalSuccess} closeModal={closeModalSuccess}>
        <S.ModalMain>
          <S.Title>
            Dados salvos
            <br /> com sucesso! ;)
          </S.Title>

          <S.Description>
            Prontinho! Agora você vai
            <br /> ser o primeiro a saber
            <br /> quando o seu perfume
            <br /> favorito voltar aos nossos
            <br /> estoques! Não se esqueça
            <br /> de checar seus e-mails e<br /> caixa de spam diariamente
            <br /> para não deixar passar! {'<3'}
          </S.Description>
        </S.ModalMain>
      </Modal>
    </>
  );
};
