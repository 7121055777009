import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 90vh;
  overflow-x: hidden;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 90%;
    padding: 2rem 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    ${Image} {
      max-width: 294px !important;
      width: 294px !important;
      margin: 5rem 0 0 0;
    }

    > button,
    a {
      font-size: 1.5rem;
      font-weight: normal;
      margin-top: 2rem;
      letter-spacing: 0.2rem;
    }

    .control-dots {
      top: -10px;
    }

    .carousel .control-dots .dot {
      box-shadow: none;
      background-color: ${theme.colors.secondGray};
      margin: 0 5px;

      &:hover {
        background-color: ${theme.colors.black};
      }
    }

    .dot.selected {
      background-color: ${theme.colors.black} !important;
    }

    .carousel .slide {
      background-color: transparent;
      width: 70%;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      > button, a {
        width: 190px;
        margin-top: 0;
      }

      ${Image} {
        max-width: 50rem !important;
        width: 50rem !important;
        margin: 4rem 0 0 0;
        height: 35rem;
      }

        .img1, .img2, .img3, .img4, .img5 {
          max-width: 50rem;
          width: 50rem;
          margin: 4rem 0 0 0;
          height: 35rem;
        }
    `}
  `}
`;

export const Image = styled.img`
  width: 1rem;
  max-width: 25rem;
  height: 20rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    font-size: ${theme.font.sizes.xxextraLarge};
    font-weight: bold;
    margin-top: 4rem;
  `}
`;

export const Description = styled.p<{ width?: string }>`
  ${({ theme, width }) => css`
    color: ${theme.colors.black};
    max-width: ${width};
    margin: 2rem auto;

    font-family: ${theme.font.family.LoraRegular};
    font-weight: medium;
    color: ${theme.colors.secondBlack};
    text-align: center;

    ${media.greaterThan('small')`
      max-width: 40%;
      font-size: 2rem; 
      margin: 2rem auto; 
    `}
  `}
`;

export const Introduction = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Title} {
      font-family: ${theme.font.family.LoraRegular};
      font-weight: bold;
      letter-spacing: 0.3rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    ${Image} {
      max-width: 294px;
      width: 294px;
      height: 20rem;
    }

    ${Description} {
      margin: 2rem 0;
      font-family: ${theme.font.family.LoraVariable};
      font-weight: medium;
      color: ${theme.colors.secondBlack};
    }

    > button {
      font-weight: normal;
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};

      ${Image} {
        max-width: 50rem;
        width: 50rem;
        height: 34rem;
        margin-top: 2.4rem;
      }

      ${Title} {
        font-size: 3.2rem;
      }

      ${Description} {
        color: ${theme.colors.secondBlack};
        font-size: 2rem;
        width: 40%;
        margin-bottom: 5rem;
      }

      > button {
        width: 25%;
      }
    `}
  `}
`;

export const GuideLink = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: 400;
    font-size: 15px;
    text-decoration-line: underline;
    color: ${theme.colors.secondBlack};
    cursor: pointer;
    text-align: center;
  `}
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  width: 100%;

  ${media.greaterThan('small')`
      width: 19rem;
  `}
`;
