import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    max-width: ${theme.maxwidthMobile};
    margin: 2rem auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6rem;
  width: 100%;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-size: ${theme.font.sizes.medium};
  `}
`;

export const Svg = styled(ReactSVG)`
  height: 2rem;
  width: 2rem;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Iframe = styled.iframe`
  background: transparent;
  border: none;
`;
