import Button from 'components/Button';
import Footer from 'components/Footer';
import InputText from 'components/InputText';
import Navbar from '../../components/Navbar';

import * as S from './styles';

export default function Login() {
  return (
    <>
      <S.Wrapper>
        <Navbar />
        <S.Content>
          <S.Title>Acessar minha conta</S.Title>
          <S.SubTitle>entrar com login e senha</S.SubTitle>

          <S.boxLogin>
            <S.Form>
              <InputText
                label="nome de usuário ou e-mail"
                name="usuario"
                type="email"
              />
              <InputText type="password" label="senha" name="senha" />

              <Button name="ENTRAR" link={true} to="/welcome" />
            </S.Form>

            <S.Links>
              <S.LinkForm>Esqueci minha senha</S.LinkForm>
              <S.LinkForm>Registre-se aqui</S.LinkForm>
            </S.Links>
          </S.boxLogin>
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}
