import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import { perfumes } from 'services/journey';
import { AnyAction } from 'redux';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

export function* getPerfums({
  payload,
}: ActionType<typeof actions.perfumesRequest>) {
  try {
    const { data } = yield call(
      perfumes.listAll,
      payload.userId,
      payload.journeyId
    );

    yield put(actions.perfumesSuccess(data));
  } catch (err) {
    yield put(actions.perfumesFailure());
  }
}

export function* getQuestion({
  payload,
}: ActionType<typeof actions.questionsRequest>) {
  try {
    const { data } = yield call(
      perfumes.getQuestions,
      payload.userId,
      payload.journeyId,
      payload.id
    );

    yield put(actions.questionsSuccess(data));
  } catch (err) {
    yield put(actions.questionsFailure());
  }
}

export function* responseUser(action: AnyAction) {
  try {
    const { data } = yield call(perfumes.responseUser, action.payload);

    yield put(actions.responseUserSuccess(data));
  } catch (err) {
    yield put(actions.questionsFailure());
  }
}

export function* getSecondNotes({
  payload,
}: ActionType<typeof actions.secondNotesRequest>) {
  try {
    const { data } = yield call(
      perfumes.listAllSecondNotes,
      payload.userId,
      payload.journeyId,
      payload.data
    );

    yield put(actions.secondNotesSuccess(data));
  } catch (err) {
    yield put(actions.secondNotesFailure());
  }
}

export function* shippingSecondNotes({
  payload,
}: ActionType<typeof actions.shippingSecondNotesRequest>) {
  try {
    const { data } = yield call(perfumes.shippingSecondNotes, payload);

    yield put(actions.shippingSecondNotesSuccess());
  } catch (err) {
    toast.error(err.response.data.msg);
    yield put(actions.shippingSecondNotesFailure());
  }
}

export function* skinTestList({
  payload,
}: ActionType<typeof actions.skinTestsRequest>) {
  try {
    const { data } = yield call(
      perfumes.skinsTestListAll,
      payload.userId,
      payload.journeyId,
      payload.perfum_id
    );

    yield put(actions.skinTestsSuccess(data.avaliable));
  } catch (err) {
    yield put(actions.skinTestsFailure());
  }
}

export function* skinTestSending({
  payload,
}: ActionType<typeof actions.skinTestsSendingRequest>) {
  try {
    const dataSending = {
      user_id: payload.userId,
      journey_id: payload.journeyId,
      perfum_id: payload.perfum_id,
    };

    const { data } = yield call(perfumes.skinsTestSending, dataSending);

    toast.success(data.msg);
    yield put(actions.skinTestsSendingSuccess());
  } catch (err) {
    toast.success(err.response.data.msg);
    yield put(actions.skinTestsSendingFailure());
  }
}

export default all([
  takeLatest('@perfumes/PERFUMES_REQUEST', getPerfums),
  takeLatest('@perfumes/QUESTIONS_REQUEST', getQuestion),
  takeLatest('@questions/RESPONSE_REQUEST', responseUser),
  takeLatest('@perfumes/SECONDNOTES_REQUEST', getSecondNotes),
  takeLatest('@perfumes/SHIPPING_SECONDNOTES_REQUEST', shippingSecondNotes),
  takeLatest('@perfumes/SKIN_TEST_REQUEST', skinTestList),
  takeLatest('@perfumes/SKIN_TEST_SENDING_REQUEST', skinTestSending),
]);
