import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.main`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const Image = styled.img`
  width: 15rem;

  ${media.greaterThan('small')`
    width: 30rem;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-weight: bold;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    font-family: ${theme.font.family.LoraRegular};
  `}
`;

export const SubTitle = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.medium};
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.YaldeviRegular};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    padding: 2rem 0;
    text-align: center;
    font-size: 1.4rem;
    font-family: ${theme.font.family.YaldeviRegular};
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
    line-height: 2rem;
    width: 85%;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    padding: 3rem 0;
    color: ${theme.colors.black};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;
  `}
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 1rem;
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1rem;

    ${Image} {
      width: 3rem;
    }

    ${Title} {
      width: 100%;
      font-weight: normal;
      font-size: 2rem;
      font-family: ${theme.font.family.YaldeviRegular};
      letter-spacing: 0.1rem;
      padding: 1rem 0 0 0;
    }

    ${Description} {
      padding: 0;
      font-size: 1.6rem;
      font-family: ${theme.font.family.YaldeviSemiBold};
      font-weight: 700;
      color: ${theme.colors.black};
      width: 100%;
    }
  `}
`;

export const Closed = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    text-align: right;
  }
`;

export const OlfactoryEvolution = styled.div`
  ${({ theme }) => css`
    ${Title} {
      text-align: center;
      font-size: 2.8rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
    }

    ${Description} {
      max-width: 450px;
      margin: 0 auto;
      padding: 1rem 0;
      color: ${theme.colors.black};
    }

    ${media.lessThan('small')`
      ${Title} {
        font-size: 2rem;
      }

      ${Description} {
        max-width: 250px;
      }
    `}
  `}
`;

export const Notes = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    ${Title} {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;

      &:after {
        content: '';
        display: block;
        width: 0.1rem;
        height: 2rem;
        transform: translateX(1rem);
        background-color: ${theme.colors.secondGray};
      }
    }

    ${Infos} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;

      ${Title} {
        font-weight: normal;
        &:after {
          display: none;
        }
      }
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

type InfoProps = {
  color: string;
};

export const Info = styled.div<InfoProps>`
  ${({ theme, color }) => css`
    ${Title} {
      color: ${theme.colors.secondBlack};
      font-size: ${theme.font.sizes.small};
      display: flex;
      justify-content: center;
      margin-right: 1rem;
      align-items: center;

      &:before {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-right: 0.2rem;
        background-color: ${color};
      }
    }
  `}
`;

export const PlusPerfume = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Title} {
      text-align: center;
      font-size: 2.8rem;
      font-family: ${theme.font.family.LoraRegular};
      font-weight: bold;
      letter-spacing: 0.3rem;
      margin: 0;
      padding: 0;
      margin-top: 2rem;
    }

    ${Description} {
      color: ${theme.colors.black};
      width: 100%;
      font-family: ${theme.font.family.LoraRegular};
      font-size: ${theme.font.sizes.small};
    }

    ${media.lessThan('small')`
      ${Title} {
        font-size: 2rem;
      }
    `}
  `}
`;

export const Iframe = styled.iframe`
  width: 90%;

  ${media.greaterThan('small')`
    width: 100%;
    height: 400px;
  `}
`;

export const ExperienceBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: 90%;
    padding: 2rem 0;
    padding-top: 1rem;
    margin-bottom: 2rem;

    ${Title} {
      text-align: center;
      font-size: ${theme.font.sizes.xlarge};
      font-family: ${theme.font.family.LoraVariable};
      font-weight: bold;
      letter-spacing: 0.3rem;
      max-width: 80%;
      margin: 0 auto;
    }

    ${Box} {
      &:after {
        margin-top: 2rem;
        margin-bottom: 1rem;
        content: '';
        display: block;
        width: 15rem;
        height: 0.1rem;
        background-color: ${theme.colors.secondGray};
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      ${Title} {
        margin: 0;
        width: 100%;
        font-size: 1.2rem;
        font-family: ${theme.font.family.LoraVariable};
        font-weight: 400;
        font-weight: normal;
        letter-spacing: 0rem;
      }

      ${Description} {
        width: 90%;
        font-size: 1.6rem;
        font-family: ${theme.font.family.YaldeviMedium};
        font-weight: bold;
        color: ${theme.colors.black};
        letter-spacing: 0.1rem;
        margin-top: 1rem;
      }
    }
  `}
`;

export const BarChatContainer = styled.div`
  > div {
    /* transform: rotate(90deg); */
  }
  margin: 5rem 0;
`;

export const BoxColor = styled.div`
  display: flex;
`;

type ColorItem = {
  cor: string;
};

export const Color = styled.div<ColorItem>`
  ${({ cor, theme }) => css`
    background-color: ${cor};
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin: 0 0.6rem;
    margin-top: 1rem;
  `}
`;

export const GraficImage = styled.img`
  max-width: 100%;

  ${media.greaterThan('small')`
    max-width: 50%;
    display: block;
    margin: 0 auto;
  `}
`;
