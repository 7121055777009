import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';
import { GrFormClose } from 'react-icons/gr';
import Button from 'components/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  detailsAccountRequest,
  editingAccountRequest,
} from 'store/modules/account/actions';
import { useEffect, useRef, useState } from 'react';
import { StoreState } from 'store/createStore';
import { InfoUser, ProfileEditing } from 'store/modules/account/types';
import LoadingRound from 'components/LoadingRound';

function Profile() {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [isEqualPassword, setIsEqualPassword] = useState<boolean>();

  const [alterPassword, setAlterPassword] = useState<boolean>(false);

  const { info_user, loading } = useSelector(
    (state: StoreState) => state.account
  );
  const userId = localStorage.getItem('user_id_amyi');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsAccountRequest(userId));
  }, [dispatch]);

  const formRef = useRef<any>();

  const handleSubmit = () => {
    if (formRef.current && isEqualPassword) {
      const {
        display_name,
        first_name,
        last_name,
        user_phone,
      } = formRef.current.values;

      const data: ProfileEditing = {
        display_name,
        first_name,
        last_name,
        billing_phone: user_phone,
      };

      const dataPassword: ProfileEditing = {
        display_name,
        first_name,
        last_name,
        billing_phone: user_phone,
        user_pass: newPassword,
      };

      isEqualPassword && newPassword.length >= 8
        ? dispatch(editingAccountRequest(dataPassword, userId))
        : dispatch(editingAccountRequest(data, userId));
    }
  };

  useEffect(() => {
    newPassword === newPasswordConfirm
      ? setIsEqualPassword(true)
      : setIsEqualPassword(false);
  }, [newPassword, newPasswordConfirm]);

  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Closed to="/account">
          <GrFormClose size={28} />
        </S.Closed>
        <S.Content>
          <S.Title>Meu Perfil</S.Title>
          <S.SubTitle>Dados pessoais</S.SubTitle>

          <S.Details>
            {loading ? (
              <LoadingRound />
            ) : (
              <S.FormContent
                innerRef={formRef}
                initialValues={info_user}
                onSubmit={() => console.log('')}
              >
                <S.FormWrapper>
                  <S.Label htmlFor="surName">Como podemos te chamar? *</S.Label>
                  <S.FieldContent
                    name="display_name"
                    render={({ field }: any) => (
                      <S.Input {...field} id="surName" type="text" required />
                    )}
                  />
                  <S.Description>
                    Este será seu nome no espaço Amyi :)
                  </S.Description>

                  <S.Label htmlFor="firstName">Nome *</S.Label>
                  <S.FieldContent
                    name="first_name"
                    render={({ field }: any) => (
                      <S.Input {...field} id="firstName" type="text" required />
                    )}
                  />

                  <S.Label htmlFor="firstName">Sobrenome *</S.Label>
                  <S.FieldContent
                    name="last_name"
                    render={({ field }: any) => (
                      <S.Input
                        {...field}
                        id="lastName"
                        name="last_name"
                        type="text"
                        required
                      />
                    )}
                  />

                  <S.Label htmlFor="firstName">Endereço de E-mail</S.Label>

                  <S.FieldContent
                    name="user_email"
                    render={({ field }: any) => (
                      <S.Input {...field} id="email" type="email" required />
                    )}
                  />

                  <S.Label htmlFor="phone">Telefone</S.Label>
                  <S.FieldContent
                    name="user_phone"
                    render={({ field }: any) => (
                      <S.Input
                        {...field}
                        mask="(99)99999-9999"
                        maskChar=""
                        id="phone"
                        type="text"
                        required
                      />
                    )}
                  />

                  <S.Label htmlFor="picked">Senha</S.Label>
                  <S.BoxInput>
                    <S.Radio
                      status={alterPassword ? 'active' : 'disabled'}
                      onClick={() =>
                        setAlterPassword((prevState) => !prevState)
                      }
                    />
                    <S.Label
                      htmlFor="picked"
                      onClick={() =>
                        setAlterPassword((prevState) => !prevState)
                      }
                    >
                      Desejo alterar minha senha
                    </S.Label>
                  </S.BoxInput>
                  <S.Label htmlFor="password" disabled={!alterPassword}>
                    Nova Senha *
                  </S.Label>

                  <S.FieldContent
                    name="password"
                    render={({ field }: any) => (
                      <S.Input
                        {...field}
                        id="password"
                        type="password"
                        disabled={!alterPassword}
                        value={newPassword}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                          setNewPassword(ev.target.value)
                        }
                      />
                    )}
                  />

                  {newPassword.length < 8 && alterPassword && (
                    <S.ErrorMsg>
                      A senha deve ter pelo menos 8 caracteres.
                    </S.ErrorMsg>
                  )}

                  <S.Label htmlFor="confirmPassword" disabled={!alterPassword}>
                    Confirmar Nova Senha *
                  </S.Label>
                  <S.FieldContent
                    name="confirmPassword"
                    render={({ field }: any) => (
                      <S.Input
                        {...field}
                        id="confirmPassword"
                        type="password"
                        disabled={!alterPassword}
                        value={newPasswordConfirm}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                          setNewPasswordConfirm(ev.target.value)
                        }
                      />
                    )}
                  />

                  {!isEqualPassword && (
                    <S.ErrorMsg>As senhas não coincidem</S.ErrorMsg>
                  )}
                </S.FormWrapper>
              </S.FormContent>
            )}
          </S.Details>

          <Button name="SALVAR ALTERAÇÕES" onClick={handleSubmit} />
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}

export default Profile;
