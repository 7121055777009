import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      text-align: center;
      margin-bottom: 0;
      max-width: 70%;
      &:after,
      &:before {
        display: none;
      }
    }

    > button {
      margin: 2rem 0;
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
        margin-top: 6rem;
      }
    `}
  `}
`;

export const Information = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
    margin: 2rem 0;
  `}
`;

export const GridImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: center;

  ${media.greaterThan('small')`
    grid-template-columns: repeat(3, 1fr); 
  `}
`;

export const BoxImage = styled.div`
  ${({ theme }) => css`
    ${Title} {
      color: ${theme.colors.secondBlack};
      font-size: ${theme.font.sizes.xsmall};
      letter-spacing: 0.1rem;
      text-align: center;
      font-weight: normal;
      margin: 0;
    }
  `}
`;

type ImageProps = {
  selected?: number;
};

export const Image = styled.img<ImageProps>`
  ${({ selected, theme }) => css`
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;

    opacity: ${selected !== -1 ? '0.6' : '1'};
    border: ${selected !== -1 ? `3px solid ${theme.colors.secondGray}` : ``};

    ${media.greaterThan('small')`
      width: 9rem;
      height: 9rem;
    `}
  `}
`;

export const WrapperImage = styled.div`
  position: relative;
`;

export const ImageBox = styled.div`
  position: absolute;
  top: 25%;
  right: 25%;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    text-align: center;
    font-family: ${theme.font.family.YaldeviRegular};
    height: 2.8rem;
    margin-top: 1.5rem;
    font-size: 16px;
  `}
`;
