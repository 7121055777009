import InputMask from 'react-input-mask';
import { Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.threeGray};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    > button {
      width: 100%;
      font-weight: normal;
      font-size: ${theme.font.sizes.small};
      margin-bottom: 1rem;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};

      > button { 
        width: 30%;
      }
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.xlarge};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const TitleTotal = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.medium};
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;
    font-family: ${theme.font.family.LoraRegular};
    font-weight: medium;
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.1rem;
    margin: 2rem 0;
    font-size: ${theme.font.sizes.small};

    &:first-child,
    &:last-child {
      margin: 0;
    }
  `}
`;

export const Closed = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    text-align: right;
    margin: 1rem 1rem 0 0;
  }
`;

export const Details = styled.div`
  ${({ theme }) => css`
    box-shadow: 0px 0px 8px #00000029;
    background-color: ${theme.colors.white};
    padding: 2rem;
    margin-bottom: ${theme.spacings.small};

    ${Title} {
      font-size: ${theme.font.sizes.small};
      text-align: left;
      margin: 0;

      span {
        display: block;
        color: ${theme.colors.black};
        font-size: ${theme.font.sizes.xsmall};
        font-weight: normal;
      }
    }

    ${TitleTotal} {
      font-size: ${theme.font.sizes.medium};
      font-weight: medium;
      text-align: left;
      margin: 0;
      color: ${theme.colors.green};
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.colors.gray};

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  `}
`;

export const Value = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    letter-spacing: 0.1rem;
  `}
`;

export const ValueTotal = styled.p`
  ${({ theme }) => css`
    letter-spacing: 0.1rem;
    color: ${theme.colors.green};
    font-weight: bold;
    font-size: ${theme.font.sizes.medium};
  `}
`;

export const FormContent = styled(Formik)``;

export const FormWrapper = styled(Form)`
  ${({ theme }) => css`
    ${Description} {
      margin: 0;
      margin-top: 0.2rem;
      margin-bottom: -1rem;
    }
  `}
`;

type LabelProps = {
  disabled?: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme, disabled }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;
    display: block;
    margin-top: 2rem;
    margin-bottom: 0.5rem;

    opacity: ${disabled && 0.3};

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 0;
    }
  `}
`;

export const FieldContent = styled(Field)`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid ${theme.colors.secondGray};
    padding-bottom: 1rem;
    display: block;
    width: 100%;

    color: ${theme.colors.black};
    font-weight: bold;
    letter-spacing: 0.2rem;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.LoraRegular};

    &:disabled {
      opacity: 0.5;
    }
  `}
`;

export const Input = styled(InputMask)`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid ${theme.colors.secondGray};
    padding-bottom: 1rem;
    display: block;
    width: 100%;

    color: ${theme.colors.black};
    font-weight: bold;
    letter-spacing: 0.2rem;
    font-size: ${theme.font.sizes.medium};
    font-family: ${theme.font.family.LoraRegular};

    &:disabled {
      opacity: 0.5;
    }
  `}
`;

export const BoxInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;

  input {
    width: auto;
  }

  label {
    margin-left: 2rem;
    margin-bottom: 0;
    letter-spacing: 0.1rem;
  }
`;

export const ErrorMsg = styled.span`
  ${({ theme }) => css`
    color: red;
    font-family: ${theme.font.family.YaldeviRegular};
    display: block;
    margin-top: 1rem;
  `}
`;

const radioModdifers = {
  active: (theme: DefaultTheme) => css`
    &:after {
      content: '';
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      background-color: ${theme.colors.secondBlack};
      border-radius: 50%;
    }
  `,
  disabled: (theme: DefaultTheme) => css``,
};

type radiosProps = {
  status: 'active' | 'disabled';
};

export const Radio = styled.div<radiosProps>`
  ${({ theme, status }) => css`
    border: 2px solid ${theme.colors.secondBlack};
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    ${!!status && radioModdifers[status](theme)}
  `}
`;
