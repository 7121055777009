import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  max-width: 100%;
  height: 5rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondGray};
  `}
`;
