import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import dashboard from 'services/dashboard';
import { ActionType } from 'typesafe-actions';

export function* getDataUser({
  payload,
}: ActionType<typeof actions.dashboardRequest>) {
  try {
    const { data } = yield call(
      dashboard.dataUser,
      payload.userId,
      payload.journeyId
    );

    yield put(actions.dashboardSuccess(data));
  } catch (err) {
    yield put(actions.dashboardFailure());
  }
}

export default all([takeLatest('@dashboard/DASHBOARD_REQUEST', getDataUser)]);
