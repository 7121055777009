import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: ${theme.colors.threeGray};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    margin: 0 auto;
    max-width: 100%;

    min-height: 85vh;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    margin-top: 0rem;
    text-align: center;
    font-size: ${theme.font.sizes.xlarge};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    text-align: left;
    max-width: 100%;
    margin: 2rem auto;
    letter-spacing: 0.2rem;
    margin-bottom: 4rem;
    font-size: ${theme.font.sizes.xsmall};
    font-family: ${theme.font.family.YaldeviRegular};
  `}
`;

export const BoxRequest = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem 1.5rem;
    padding-top: 0;
    margin-top: 1.6rem;

    ${Description} {
      margin: 2rem 0 0 0;
      width: 100%;
      text-align: left;
      font-weight: 200;
      color: ${theme.colors.secondBlack};
      font-size: 1.3rem;
    }

    a {
      margin-top: 2rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      letter-spacing: 0.2rem;
      width: 100%;
    }
  `}
`;

type SubTitleProps = {
  color?: 'Enviado' | 'Cancelado';
};

export const SubTitle = styled.p<SubTitleProps>`
  ${({ theme, color }) => css`
    text-align: center;
    margin-top: ${theme.spacings.xsmall};
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 1rem;
      color: ${theme.colors.secondBlack};
    }

    a {
      margin: 0;
    }
  `}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const BoxGrid = styled.div`
  ${({ theme }) => css`
    ${Title} {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.secondBlack};
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: 200;
      text-align: left;
      margin-top: 2rem;
      letter-spacing: 0.2rem;
    }

    ${SubTitle} {
      font-weight: bold;
      font-family: ${theme.font.family.LoraRegular};
      letter-spacing: 0.2rem;
      text-align: left;
      margin: 0;
      justify-content: flex-start;
    }
  `}
`;

export const Closed = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    text-align: right;
    margin: 1rem 1rem 0 0;
  }
`;

export const WrapperOrders = styled.div`
  ${media.greaterThan('small')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  `}
`;

export const WrapperOrdersNull = styled.div`
  height: 77vh;
  overflow-y: none;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Title} {
    font-size: 1.6rem;
  }
`;
