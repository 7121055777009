import media from 'styled-media-query';
import styled, { css } from 'styled-components';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    > button {
      margin-top: 2rem;

      ${media.greaterThan('small')`
        width: 35%;
      `}
    }

    .rhap_container {
      box-shadow: none;
    }

    .rhap_total-time {
      display: none;
    }

    .rhap_progress-container {
      width: 70%;
    }

    .rhap_progress-section {
      display: flex;
      flex-direction: column-reverse;
    }

    .rhap_controls-section {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rhap_volume-controls {
      display: none;
    }

    .rhap_additional-controls,
    .rhap_volume-container {
      display: none;
    }

    .rhap_stacked {
      flex-direction: column-reverse;
    }

    .rhap_main-controls-button {
      color: ${theme.colors.black};
      margin: 1rem 0;
      font-size: 50px;
      width: 50px;
      height: 50px;
    }

    .rhap_current-time {
      color: ${theme.colors.fourthGray};
      font-size: 1rem;
      letter-spacing: 0.1rem;
    }

    .rhap_progress-indicator,
    .rhap_progress-filled {
      background-color: ${theme.colors.fourthGray};
    }
  `}
`;

type ImageProps = {
  selected?: boolean;
};

export const Image = styled.img<ImageProps>`
  ${({ theme, selected }) => css`
    margin: 2rem 0;
    opacity: ${selected ? '0.6' : '1'};
  `}
`;

export const WrapperCarousel = styled.div`
  width: 100%;
  margin-top: 2rem;

  .slick-prev {
    background: transparent;
    left: 0rem;
    top: 35%;
    width: 32px;
    height: 40px;
    z-index: 9;
    padding: 0rem 1rem;
  }

  .slick-next {
    right: 2rem;
    top: 35%;
    width: 32px;
    height: 40px;
    padding: 0rem 3rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: gray;
    font-size: 40px;
  }

  img {
    max-width: 75%;
    margin: 0 auto;
  }

  .slick-slide {
    transition: 0.5s ease;
    opacity: 0.5;

    img {
      margin-top: 1rem;
    }

    .rhap_container {
      display: none;
    }
  }

  .slick-active {
    transition: 0.5s ease;
    opacity: 1;

    img {
      transition: scale 1s ease;
      transform: scale(1.1);
      margin-top: 1rem;
    }

    .rhap_container {
      display: block;
    }
  }

  ${media.greaterThan('small')`
    width: 40%;
  `}
`;

export const WrapperImage = styled.div`
  position: relative;
`;

export const ImageBox = styled.div`
  position: absolute;
  top: 35%;
  right: 38%;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    text-align: center;
    font-family: ${theme.font.family.YaldeviRegular};
    height: 2.8rem;
    margin: 4rem auto;
    font-size: 1.6rem;
    display: block;
  `}
`;
