import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 100vh;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.fourthGray};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;
    width: 50%;
    margin: 2rem auto;
    text-align: center;
    font-weight: 200;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    min-height: 75vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${Title} {
      font-family: ${theme.font.family.YaldeviRegular};
      letter-spacing: 0.2rem;
    }
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    width: 5rem;
    color: ${theme.colors.fourthGray};
  `}
`;

export const History = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    min-height: 70vh;

    ${Title} {
      text-align: center;
      font-family: ${theme.font.family.YaldeviRegular};
      width: 100%;
      margin: 3rem auto;
      font-weight: normal;
      font-size: 1.6rem;
      width: 24rem;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};

      ${Title} {
        width: 30%;
        text-align: center;
      }
    `}
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem;
    margin: 2rem auto;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;

    ${Title} {
      font-family: ${theme.font.family.LoraVariable};
      font-weight: bold;
      margin: 0;
      text-align: left;
      font-size: 2rem;
      color: ${theme.colors.black};
      letter-spacing: 0.3rem;
    }

    > button,
    a {
      margin-top: 2rem;
      font-weight: medium;
      font-size: 1.4rem;
      font-family: ${theme.font.family.YaldeviMedium};
      width: 100%;
    }

    ${media.greaterThan('small')`
      margin: 0rem;
      width: 100%;

      ${Title} {
        width: 100%;
        font-size: 2.4rem;
        text-align: left;
      }
    `}
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    font-size: ${theme.font.sizes.xsmall};
    letter-spacing: 0.1rem;
    margin: 0.5rem 0;
    margin-bottom: 1.5rem;
  `}
`;

export const Validation = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;

    ${Title} {
      font-weight: 700;
      color: ${theme.colors.black};
      font-family: ${theme.font.family.LoraVariable};
      width: auto;
      font-size: 1.4rem;
      letter-spacing: 0.006em;
    }

    span {
      transform: translateY(1px);
      margin-left: 0.4rem;
    }

    p {
      transform: translateY(-2px);
    }
  `}
`;

export const WrapperAvaliations = styled.div`
  ${media.greaterThan('small')`
    width: 70%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  `}
`;
