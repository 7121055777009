import { action } from 'typesafe-actions';

export function signInRequest(token: string) {
  return action('@auth/SIGN_IN_REQUEST', { token });
}

export function signInSuccess() {
  return action('@auth/SIGN_IN_SUCCESS');
}

export function signInFailure() {
  return action('@auth/SIGN_IN_FAILURE');
}
