import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';
import { GrFormClose } from 'react-icons/gr';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import { useEffect, useRef } from 'react';
import {
  detailsAddressRequest,
  editingAddressRequest,
} from 'store/modules/account/actions';
import LoadingRound from 'components/LoadingRound';
import { ShippingAccount, Billing } from 'store/modules/account/types';
import { Field } from 'formik';

function Address() {
  const { detailsAddress, loading } = useSelector(
    (state: StoreState) => state.account
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(detailsAddressRequest(userId, journeyId));
  }, []);

  const formRefBilling = useRef<any>();
  const formRefShipping = useRef<any>();

  const handleSubmit = () => {
    if (formRefBilling.current && formRefShipping.current) {
      const {
        billing_address,
        billing_city,
        billing_complement,
        billing_first_name,
        billing_last_name,
        billing_neighborhood,
        billing_number,
        billing_phone,
        billing_postcode,
        billing_state,
      } = formRefBilling.current.values;

      const {
        shipping_address,
        shipping_city,
        shipping_complement,
        shipping_first_name,
        shipping_last_name,
        shipping_neighborhood,
        shipping_number,
        shipping_postcode,
        shipping_state,
      } = formRefShipping.current.values;

      const data: ShippingAccount | Billing = {
        billing_address,
        billing_city,
        billing_complement,
        billing_first_name,
        billing_last_name,
        billing_neighborhood,
        billing_number,
        billing_phone,
        billing_postcode,
        billing_state,
        shipping_address,
        shipping_city,
        shipping_complement,
        shipping_first_name,
        shipping_last_name,
        shipping_neighborhood,
        shipping_number,
        shipping_postcode,
        shipping_state,
      };

      dispatch(editingAddressRequest(data, userId));
    }
  };

  console.log(detailsAddress.billing);

  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Closed to="/account">
          <GrFormClose size={28} />
        </S.Closed>
        <S.Content>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Title>Meus Endereços</S.Title>
              <S.SubTitle>Endereço de Entrega</S.SubTitle>

              <S.Details>
                <S.FormContent
                  innerRef={formRefBilling}
                  initialValues={
                    detailsAddress.billing ? detailsAddress.billing : {}
                  }
                  onSubmit={handleSubmit}
                >
                  <S.FormWrapper>
                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="name">Nome</S.Label>
                        <S.FieldContent
                          name="billing_first_name"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="name"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                      <div>
                        <S.Label htmlFor="lastname">Sobrenome</S.Label>
                        <S.FieldContent
                          name="billing_last_name"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="lastname"
                              name="billing_last_name"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                    </S.BoxInput>

                    <S.Label htmlFor="city">Cidade</S.Label>
                    <S.FieldContent
                      name="billing_city"
                      render={({ field }: any) => (
                        <S.Input {...field} id="city" type="text" required />
                      )}
                    />

                    <S.Label htmlFor="address">Endereço</S.Label>
                    <S.FieldContent
                      name="billing_address"
                      render={({ field }: any) => (
                        <S.Input {...field} id="address" type="text" required />
                      )}
                    />

                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="number">Nº</S.Label>
                        <S.FieldContent
                          name="billing_number"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="number"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>

                      <div>
                        <S.Label htmlFor="complement">Complemento</S.Label>
                        <S.FieldContent
                          name="billing_complement"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="complement"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                    </S.BoxInput>

                    <S.Label htmlFor="district">Bairro</S.Label>
                    <S.FieldContent
                      name="billing_neighborhood"
                      render={({ field }: any) => (
                        <S.Input
                          {...field}
                          id="district"
                          type="text"
                          required
                        />
                      )}
                    />

                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="cep">CEP</S.Label>
                        <S.FieldContent
                          name="billing_postcode"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="cep"
                              type="text"
                              mask="99999-999"
                              maskChar=""
                              required
                            />
                          )}
                        />
                      </div>

                      <div>
                        <S.Label htmlFor="city">Estado</S.Label>
                        <Field
                          as="select"
                          name="billing_state"
                          className="field-editing"
                        >
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </Field>
                      </div>
                    </S.BoxInput>
                  </S.FormWrapper>
                </S.FormContent>
              </S.Details>

              <S.SubTitle>Endereço de Faturamento</S.SubTitle>

              <S.Details>
                <S.FormContent
                  innerRef={formRefShipping}
                  initialValues={
                    detailsAddress.shipping ? detailsAddress.shipping : {}
                  }
                  onSubmit={handleSubmit}
                >
                  <S.FormWrapper>
                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="nameShipping">Nome</S.Label>
                        <S.FieldContent
                          name="shipping_first_name"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="nameShipping"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                      <div>
                        <S.Label htmlFor="nameShipping">Sobrenome</S.Label>
                        <S.FieldContent
                          name="shipping_last_name"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="nameShipping"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                    </S.BoxInput>

                    <S.Label htmlFor="cityShipping">Cidade</S.Label>
                    <S.FieldContent
                      name="shipping_city"
                      render={({ field }: any) => (
                        <S.Input
                          {...field}
                          id="cityShipping"
                          type="text"
                          required
                        />
                      )}
                    />

                    <S.Label htmlFor="addressShipping">Endereço</S.Label>
                    <S.FieldContent
                      name="shipping_address"
                      render={({ field }: any) => (
                        <S.Input
                          {...field}
                          id="addressShipping"
                          type="text"
                          required
                        />
                      )}
                    />

                    <S.Label htmlFor="districtShipping">Bairro</S.Label>
                    <S.FieldContent
                      name="shipping_neighborhood"
                      render={({ field }: any) => (
                        <S.Input
                          {...field}
                          id="districtShipping"
                          type="text"
                          required
                        />
                      )}
                    />

                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="numberShipping">Nº</S.Label>
                        <S.FieldContent
                          name="shipping_number"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="numberShipping"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                      <div>
                        <S.Label htmlFor="complementShipping">
                          Complemento
                        </S.Label>
                        <S.FieldContent
                          name="shipping_complement"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="complementShipping"
                              type="text"
                              required
                            />
                          )}
                        />
                      </div>
                    </S.BoxInput>

                    <S.BoxInput>
                      <div>
                        <S.Label htmlFor="cepShipping">CEP</S.Label>
                        <S.FieldContent
                          name="shipping_postcode"
                          render={({ field }: any) => (
                            <S.Input
                              {...field}
                              id="cepShipping"
                              type="text"
                              mask={'99999-999'}
                              maskChar=""
                              required
                            />
                          )}
                        />
                      </div>

                      <div>
                        <S.Label htmlFor="cityShipping">Estado</S.Label>
                        <Field
                          as="select"
                          name="shipping_state"
                          className="field-editing"
                        >
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </Field>
                      </div>
                    </S.BoxInput>

                    <S.Label htmlFor="phoneShipping">Contato</S.Label>
                    <S.FieldContent
                      name="billing_phone"
                      render={({ field }: any) => (
                        <S.Input
                          {...field}
                          id="phoneShipping"
                          type="text"
                          mask={'(99)99999-9999'}
                          maskChar=""
                          required
                        />
                      )}
                    />
                  </S.FormWrapper>
                </S.FormContent>
              </S.Details>
            </>
          )}

          <Button name="SALVAR ALTERAÇÕES" onClick={() => handleSubmit()} />
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}

export default Address;
