import { PerfumsState } from './types';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import { perfumesRanking } from 'services/perfums';
import { AnyAction } from 'redux';

export function* getRanking({
  payload,
}: ActionType<typeof actions.perfumsRequest>) {
  try {
    const { data } = yield call(
      perfumesRanking.listAll,
      payload.userId,
      payload.journeyId
    );

    yield put(actions.perfumsSuccess({ data }));
  } catch (err) {
    yield put(actions.perfumsFailure());
  }
}

export function* getDetailsPerfum({
  payload,
}: ActionType<typeof actions.perfumsDetailsRequest>) {
  try {
    const { data } = yield call(
      perfumesRanking.findOne,
      payload.userId,
      payload.journeyId,
      payload.id
    );

    yield put(actions.perfumsDetailsSuccess({ data }));
  } catch (err) {
    yield put(actions.perfumsDetailsFailure());
  }
}

export function* getReviewOrder({
  payload,
}: ActionType<typeof actions.reviewOrderRequest>) {
  try {
    const { data } = yield call(
      perfumesRanking.listReviewOrder,
      payload.userId,
      payload.journeyId,
      payload.id
    );

    yield put(actions.reviewOrderSuccess({ data }));
  } catch (err) {
    yield put(actions.reviewOrderFailure());
  }
}

export function* confirmOrder({
  payload,
}: ActionType<typeof actions.confirmPerfumRequest>): any {
  try {
    const response = yield call(
      perfumesRanking.alterAddress,
      payload.data,
      payload.userId
    );

    if (response.status === 200 || response.status === 201) {
      const responseConfirm = yield call(
        perfumesRanking.confirmPerfum,
        payload.userId,
        payload.journeyId,
        payload.id
      );
      yield put(actions.confirmPerfumSuccess(responseConfirm));
    }
  } catch (err) {
    yield put(actions.confirmPerfumFailure(err.response));
  }
}

export default all([
  takeLatest('@ranking/RANKING_REQUEST', getRanking),
  takeLatest('@ranking/PERFUM_DETAILS_REQUEST', getDetailsPerfum),
  takeLatest('@ranking/REVIEW_ORDER_REQUEST', getReviewOrder),
  takeLatest('@ranking/CONFIRM_ORDER_REQUEST', confirmOrder),
]);
