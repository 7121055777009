import * as S from './styles';

export type ProgressCircleProps = {
  position: number;
};

const ProgressCircles = ({ position }: ProgressCircleProps) => (
  <S.Wrapper position={position}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </S.Wrapper>
);

export default ProgressCircles;
