import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import history from 'utils/history';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { signInRequest } from 'store/modules/auth/actions';

export default function Auth() {
  const dispatch = useDispatch();
  interface Iparams {
    token: string;
  }

  localStorage.clear();

  const { token } = useParams<Iparams>();
  useEffect(() => {
    dispatch(signInRequest(token));
  }, []);

  const { user_id } = jwt_decode<any>(token);
  const getUserId = JSON.stringify(user_id);

  if (typeof getUserId == 'string') {
    localStorage.setItem('user_id_amyi', JSON.parse(user_id));
  } else {
    localStorage.setItem('user_id_amyi', JSON.stringify(user_id));
  }

  if (token) {
    history.push('/');
  }

  return <>{token}</>;
}
