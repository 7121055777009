import Navbar from 'components/Navbar';
import * as S from './styles';

import amyi1 from '../../assets/images/perfumes/amyi1.png';
import Button from 'components/Button';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Shipping } from 'store/modules/perfums/types';

import checkCircle from '../../assets/images/icones/check-circle-light.svg';
import {
  reviewOrderRequest,
  confirmPerfumRequest,
  closeModalSuccess,
  closeModalFailure,
  closeModalKit,
  openModalKit,
} from 'store/modules/perfums/actions';
import { StoreState } from 'store/createStore';
import { useParams } from 'react-router-dom';
import LoadingRound from 'components/LoadingRound';
import { Field } from 'formik';
import { perfumesRanking } from 'services/perfums';

function ReviewOrder() {
  const [openModal, setOpenModal] = useState(false);
  const [dataAddress, setDataAddress] = useState<Shipping>({} as Shipping);
  const [initialValues, setInitialValues] = useState({});

  interface IdParams {
    id: string;
  }

  const { id }: IdParams = useParams();

  const {
    reviewOrder,
    loading,
    errorApi,
    modalFinishSuccess,
    modalFinishFailure,
    modalFinishKit,
    responseConfirmPerfumSuccess,
  } = useSelector((state: StoreState) => state.perfums);

  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [numberHouse, setNumberHouse] = useState('');
  const [complement, setComplement] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reviewOrderRequest(userId, journeyId, Number(id)));
    dispatch(closeModalSuccess());
    dispatch(closeModalFailure());

    setInitialValues(reviewOrder.shipping ? reviewOrder.shipping : {});
  }, [id]);

  const modalOpen = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const modalOpenConfirm = () => {
    if (reviewOrder.perfum.perfum_number) {
      dispatch(
        confirmPerfumRequest(
          reviewOrder.perfum.perfum_number,
          dataAddress,
          userId,
          journeyId
        )
      );
    }
  };

  useEffect(() => {
    if (responseConfirmPerfumSuccess.data?.url) {
      dispatch(openModalKit());
      dispatch(closeModalSuccess());
    } else {
      dispatch(closeModalKit());
    }
  }, [responseConfirmPerfumSuccess]);

  function handleSubmit(data: any) {
    setDataAddress(data);
    setInitialValues({ ...data });
    modalOpen();
  }

  async function getCep(cep: string) {
    const clearCep = cep.replace('-', '');
    if (cep.length === 9) {
      const { data } = await perfumesRanking.searchCep(clearCep);

      setInitialValues({
        ...initialValues,
        shipping_first_name: name,
        shipping_last_name: lastname,
        shipping_number: numberHouse,
        shipping_complement: complement,
        billing_phone: phone,
        shipping_neighborhood: data.bairro,
        shipping_postcode: data.cep,
        shipping_city: data.localidade,
        shipping_state: data.uf,
        shipping_address: data.logradouro,
      });
    }
  }

  function handleRedirect(url: string) {
    document.location.href = url;
  }

  return (
    <S.Wrapper>
      <Modal open={openModal} closeModal={closeModal}>
        <S.ModalMain>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Title>Você escolheu:</S.Title>
              <S.Description>
                {reviewOrder.perfum.perfum_name} - <br></br>{' '}
                {reviewOrder.perfum.perfum_ingredients_modal}
              </S.Description>
              <S.Title>Você confirma a sua escolha?</S.Title>
              <S.ButtonContainer>
                <Button name="NÃO" theme="secondary" onClick={closeModal} />
                <Button name="SIM" onClick={modalOpenConfirm} />
              </S.ButtonContainer>
            </>
          )}
        </S.ModalMain>
      </Modal>

      <Modal
        open={modalFinishSuccess}
        closeModal={() => dispatch(closeModalSuccess())}
      >
        <S.ModalSecondMain>
          <S.Svg src={checkCircle} />
          <S.Title>Seu pedido foi recebido!</S.Title>
          <S.Description>
            Seu perfume Amyi favorito em 100ml foi solicitado com sucesso. Para
            verificar o seu pedido, você pode acessar a opção meus pedidos. :)
          </S.Description>
          <Button name="IR PARA A PÁGINA INICIAL" link to="/dashboard" />
        </S.ModalSecondMain>
      </Modal>

      <Modal
        open={modalFinishKit}
        closeModal={() => {
          dispatch(closeModalKit()) && dispatch(closeModalSuccess());
        }}
      >
        <S.ModalMain>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Title>Atenção!</S.Title>
              <S.Description>
                {responseConfirmPerfumSuccess?.data?.msg}
              </S.Description>
              <S.ButtonContainer>
                <Button
                  name="OK"
                  onClick={() =>
                    handleRedirect(responseConfirmPerfumSuccess?.data?.url)
                  }
                />
              </S.ButtonContainer>
            </>
          )}
        </S.ModalMain>
      </Modal>

      <Modal
        open={modalFinishFailure}
        closeModal={() => dispatch(closeModalFailure())}
      >
        <S.ModalMain>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Title>Ops!</S.Title>
              <S.Description>{errorApi?.data?.msg}</S.Description>
              <S.ButtonContainer>
                <Button
                  name="OK"
                  onClick={() => dispatch(closeModalFailure())}
                />
              </S.ButtonContainer>
            </>
          )}
        </S.ModalMain>
      </Modal>

      <Navbar />
      <S.Content>
        <S.Title>Revisar Dados</S.Title>
        {loading ? (
          <LoadingRound />
        ) : (
          <>
            <S.Description>
              Por favor, confirme seus dados antes de finalizar a sua escolha.
            </S.Description>
            <S.BoxPerfum>
              <S.DescriptionPerfum>
                {reviewOrder.perfum.perfum_img && (
                  <S.Image
                    src={
                      process.env.REACT_APP_BASEURL_IMAGES +
                      reviewOrder.perfum.perfum_img
                    }
                  />
                )}
                <div>
                  <S.Title>{reviewOrder.perfum.product_msg}</S.Title>
                  <S.Name>{reviewOrder.perfum.perfum_name}</S.Name>
                  <S.Description>
                    {reviewOrder.perfum.perfum_ingredients}
                  </S.Description>
                </div>
              </S.DescriptionPerfum>
              {/* <S.BoxInfo>{reviewOrder.perfum.product_msg}</S.BoxInfo> */}
              <S.InfoStock>
                {reviewOrder?.perfum.primary_text_stock}
              </S.InfoStock>
              <S.Button to="/perfumes">Escolher outro perfume</S.Button>
            </S.BoxPerfum>

            <S.SubTitle>Endereço de Entrega</S.SubTitle>
            <S.Details>
              <S.FormContent
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ values, setFieldValue }) => (
                  <S.FormWrapper>
                    <S.BgForm>
                      <S.BoxInput>
                        <div>
                          <S.Label htmlFor="shipping_first_name">Nome</S.Label>
                          <S.FieldContent
                            name="shipping_first_name"
                            render={({ field }: any) => (
                              <S.Input
                                {...field}
                                id="name"
                                type="text"
                                required
                                value={values.shipping_first_name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                  setFieldValue(
                                    'shipping_first_name',
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <S.Label htmlFor="shipping_last_name">
                            Sobrenome
                          </S.Label>
                          <S.FieldContent
                            name="shipping_last_name"
                            render={({ field }: any) => (
                              <S.Input
                                {...field}
                                id="name"
                                type="text"
                                required
                                value={values.shipping_last_name}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setFieldValue(
                                    'shipping_last_name',
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      </S.BoxInput>

                      <S.Label htmlFor="billing_phone">Telefone</S.Label>
                      <S.FieldContent
                        name="billing_phone"
                        render={({ field }: any) => (
                          <S.Input
                            {...field}
                            mask="(99)99999-9999"
                            maskChar=""
                            id="name"
                            type="text"
                            required
                            value={values.billing_phone}
                            onChange={(e) => {
                              console.log(e);
                              setPhone(e.target.value);
                              setFieldValue('billing_phone', e.target.value);
                            }}
                          />
                        )}
                      />

                      <S.Label htmlFor="cep">CEP</S.Label>
                      <S.FieldContent
                        name="shipping_postcode"
                        render={({ field }: any) => (
                          <S.Input
                            {...field}
                            mask="99999-999"
                            value={values.shipping_postcode}
                            onChange={(e) => {
                              getCep(e.target.value);
                              setFieldValue(
                                'shipping_postcode',
                                e.target.value
                              );
                            }}
                            maskChar=""
                            id="cep"
                            type="text"
                            required
                          />
                        )}
                      />

                      <S.Label htmlFor="address">Endereço de entrega</S.Label>
                      <S.FieldContent
                        name="shipping_address"
                        render={({ field }: any) => (
                          <S.Input id="name" {...field} type="text" required />
                        )}
                      />

                      <S.BoxInput>
                        <div>
                          <S.Label htmlFor="cep">Nº</S.Label>
                          <S.FieldContent
                            name="shipping_number"
                            render={({ field }: any) => (
                              <S.Input
                                id="name"
                                {...field}
                                type="text"
                                onChange={(e) => {
                                  setNumberHouse(e.target.value);
                                  setFieldValue(
                                    'shipping_number',
                                    e.target.value
                                  );
                                }}
                                required
                              />
                            )}
                          />
                        </div>
                        <div>
                          <S.Label htmlFor="complement">Complemento</S.Label>
                          <S.FieldContent
                            name="shipping_complement"
                            render={({ field }: any) => (
                              <S.Input
                                id="complement"
                                {...field}
                                type="text"
                                onChange={(e) => {
                                  setComplement(e.target.value);
                                  setFieldValue(
                                    'shipping_complement',
                                    e.target.value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      </S.BoxInput>

                      <S.Label htmlFor="shipping_state">Estado</S.Label>

                      <Field
                        as="select"
                        name="shipping_state"
                        className="field-editing"
                      >
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </Field>

                      <S.BoxInput>
                        <div>
                          <S.Label htmlFor="district">Bairro</S.Label>
                          <S.FieldContent
                            name="shipping_neighborhood"
                            render={({ field }: any) => (
                              <S.Input
                                id="district"
                                {...field}
                                type="text"
                                required
                              />
                            )}
                          />
                        </div>

                        <div>
                          <S.Label htmlFor="city">Cidade</S.Label>
                          <S.FieldContent
                            name="shipping_city"
                            render={({ field }: any) => (
                              <S.Input
                                id="city"
                                {...field}
                                type="text"
                                required
                              />
                            )}
                          />
                        </div>
                      </S.BoxInput>

                      <S.Label htmlFor="cpf">
                        CPF (Para emissão de nota fiscal)
                      </S.Label>
                      <S.FieldContent
                        name="cpf"
                        render={({ field }: any) => (
                          <S.Input
                            mask="999.999.999-99"
                            maskChar=""
                            id="cpf"
                            {...field}
                            type="text"
                            required
                          />
                        )}
                      />
                    </S.BgForm>

                    <S.BoxInfoDelivery>
                      <S.SubTitle>Estimativa de Entrega</S.SubTitle>

                      {reviewOrder.perfum.have_stock === 'true' ? (
                        <S.EstimateBox>
                          <S.Description>
                            Grande São Paulo: até 4 dias utéis
                          </S.Description>
                          <S.Description>
                            Interior São Paulo: até 6 dias utéis
                          </S.Description>
                          <S.Description>
                            Outros estados*: 7 a 15 dias utéis
                          </S.Description>
                          <S.Description>
                            *regiões de RR e AM pode chegar até 30 dias úteis
                          </S.Description>
                        </S.EstimateBox>
                      ) : (
                        <S.EstimateBox>
                          <S.InfoStockBottom>
                            {reviewOrder.perfum.secondary_text_stock}
                          </S.InfoStockBottom>
                        </S.EstimateBox>
                      )}
                    </S.BoxInfoDelivery>
                    <Button name="CONFIRMAR" type="submit" />
                  </S.FormWrapper>
                )}
              </S.FormContent>
            </S.Details>
            <Footer />
          </>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default ReviewOrder;
