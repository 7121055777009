import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: bold;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraRegular};
    max-width: 65%;
    letter-spacing: 0.2rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const StepThink = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 70%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Span = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.YaldeviRegular};
    width: 80%;
    text-align: center;
    margin: 2rem 0;
    letter-spacing: 0.15rem;
  `}
`;

export const TextArea = styled.textarea`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    border: none;
    resize: none;
    width: 80%;
    height: 15vh;
    margin-bottom: 2rem;
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.15rem;

    padding: 0.5rem;

    ${media.greaterThan('small')`
      width: 50%;
      height: 35vh;
    `}
  `}
`;

export const BoxTips = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0;
    max-width: 100%;
    /* width: 85%; */
  `}
`;

type hastagProps = {
  selected: number;
};

export const HastagTips = styled.div<hastagProps>`
  ${({ theme, selected }) => css`
    border: 1px solid ${theme.colors.gray};
    color: ${theme.colors.secondBlack};
    letter-spacing: 0.1rem;
    padding: 0.5rem 0.5rem;
    text-align: center;
    transition: 0.1s ease;
    font-size: ${theme.font.sizes.small};

    ${selected !== -1
      ? `background-color: ${theme.colors.secondBlack}; color: ${theme.colors.white};`
      : ''}
  `}
`;
