import * as S from './styles';

import Logo from '../../assets/images/logo.png';
import { BiMenu } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  function handleLogout() {
    localStorage.removeItem('token');
    document.location.href = 'https://amyi.co/scripts/logout.php';
  }

  return (
    <>
      <S.Wrapper>
        <S.ContentNav>
          <Link to="/dashboard">
            <S.Logo src={Logo} />
          </Link>
          <S.MenuLinks onClick={() => setOpen(!open)}>
            <BiMenu size={30} />
          </S.MenuLinks>
        </S.ContentNav>
      </S.Wrapper>

      <S.SecondMenu open={open}>
        <S.Content open={open}>
          <S.LinkMenu to="/dashboard">MINHA EXPERIÊNCIA AMYI</S.LinkMenu>
          <S.LinkMenu to="/account">MINHA CONTA</S.LinkMenu>
          <S.LinkMenu to="/requests">MEUS PEDIDOS</S.LinkMenu>
          <S.LinkMenu to="/history">HISTÓRICO</S.LinkMenu>
          {/* <S.LinkMenu to="/clubamyi">CLUBE AMYI</S.LinkMenu> */}
          <S.LinkMenu
            to="/"
            onClick={() => (document.location.href = 'https://amyi.co')}
          >
            IR PARA O SITE
          </S.LinkMenu>
          <S.LinkMenu to="/" onClick={handleLogout}>
            SAIR
          </S.LinkMenu>
        </S.Content>
      </S.SecondMenu>
    </>
  );
};

export default Navbar;
