import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 2rem;
  width: 100%;
`;

export const Main = styled.main`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    width: 100%;
  `}
`;

export const Select = styled.div`
  padding: 2rem 1rem;
`;

export const Placeholder = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    margin-bottom: 0.3rem;
    font-weight: bold;
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Options = styled.div`
  width: 100%;
  border-top: 1px solid white;
  padding: 0rem 1rem;
  max-height: 10rem;
  overflow: auto;
`;

export const Option = styled.div`
  border-bottom: 1px solid white;
  padding: 1rem 0;

  &:last-child {
    border: none;
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const Selected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
