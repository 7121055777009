import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > button,
  a {
    font-weight: normal;
    font-size: 1.2rem;
    margin: 1rem 0 0 0;
  }
`;

export const Title = styled.div`
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.2rem;
  text-align: center;

  margin-top: 1.6rem;
`;

export const Description = styled.div`
  font-family: 'Yaldevi Colombo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #494c4f;

  margin: 30px auto;
  width: 100%;

  a {
    font-family: 'Yaldevi Colombo';
    font-size: 12px;
    color: #494c4f;
    text-decoration: underline;
  }
`;

export const WrapperForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
`;

export const WrapperInput = styled.div`
  background-color: white;
  width: 100%;
`;

type LabelProps = {
  disabled?: boolean;
};

export const Label = styled.label<LabelProps>`
  ${({ theme, disabled }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.YaldeviRegular};
    letter-spacing: 0.2rem;
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-left: 0.5rem;

    opacity: ${disabled && 0.3};

    &:first-child {
      margin-top: 0;
    }

    &:nth-child(2) {
      margin-top: 0;
    }
  `}
`;

export const Input = styled(InputMask)`
  ${({ theme }) => css`
    border: none;
    border-bottom: 1px solid ${theme.colors.secondGray};
    padding: 0 0 1rem 0.5rem;
    display: block;
    width: 100%;

    color: ${theme.colors.black};
    font-weight: normal;
    letter-spacing: 0.1rem;
    font-size: 1.2rem !important;
    font-family: ${theme.font.family.LoraRegular};

    &:disabled {
      opacity: 0.5;
    }
  `}
`;
