import { HistoricState, HistoricActions } from './types';

const initialState: HistoricState = {
  perfums: [],
  error: false,
  loading: false,
};

export default function historic(
  state = initialState,
  action: HistoricActions
): HistoricState {
  switch (action.type) {
    case '@historic/HISTORIC_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@historic/HISTORIC_SUCCESS':
      return {
        ...state,
        perfums: action.payload.data.perfums,
        loading: false,
      };
    case '@historic/HISTORIC_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
