import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import { useEffect, useState } from 'react';
import * as S from './styles';

import img1 from '../../../../pages/OlfactoryJourney/JourneyPrimary/imagem1.jpg';
import img2 from '../../../../pages/OlfactoryJourney/JourneyPrimary/imagem2.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';

type ImageCircleProps = {
  data: Question;
};

type selectedProps = {
  legend: string;
  url: string;
};

const ImageCircle = ({ data }: ImageCircleProps) => {
  const [selected, setSelected] = useState<selectedProps[]>([]);
  const [value, setValue] = useState(1);
  const [imageCurrent, setImageCurrent] = useState('');
  const [legendCurrent, setLegendCurrent] = useState('');
  const [answersUser, setAnswersUser] = useState<any>([]);

  const dispatch = useDispatch();
  const { info_perfum } = useSelector((state: StoreState) => state.journey);
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');
  const [otherOption, setOtherOption] = useState('');

  function handleStep() {
    const answersUser = otherOption.length
      ? [...selected.map((item) => item.legend), otherOption]
      : [...selected.map((item) => item.legend)];

    const responseUser = {
      user_id: userId,
      journey_id: journeyId,
      perfum_id: info_perfum.perfum_id,
      question_id: data.question_id,
      answers_user: answersUser,
      answers_url: selected.map((item) => item.url),
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  useEffect(() => {
    if (data.answers_user.length >= 1) {
      setAnswersUser(data.answers_user);
    }
  }, [data]);

  const stepValue = (v: number) => Math.round(v * 8) / 8;

  function handleSelectedItem(legend: string, url: string) {
    const alredySelected = selected.findIndex((item) => item.legend === legend);

    if (alredySelected >= 0) {
      const filteredItems = selected.filter((item) => item.legend !== legend);
      setSelected(filteredItems);
    } else {
      setSelected([...selected, { legend, url }]);
    }
  }

  function handleRemoveLastItem() {
    setSelected([...selected.splice(0, selected.length - 1)]);
  }

  useEffect(() => {
    switch (Number(value.toFixed(2))) {
      case 0 | 1:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[0].url
        );
        setLegendCurrent(data.answers_avaliable[0].legend);
        break;

      case 0.13:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[1].url
        );
        setLegendCurrent(data.answers_avaliable[1].legend);

        break;

      case 0.25:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[2].url
        );
        setLegendCurrent(data.answers_avaliable[2].legend);

        break;

      case 0.38:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[3].url
        );
        setLegendCurrent(data.answers_avaliable[3].legend);

        break;

      case 0.5:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[4].url
        );
        setLegendCurrent(data.answers_avaliable[4].legend);

        break;

      case 0.63:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[5].url
        );
        setLegendCurrent(data.answers_avaliable[5].legend);
        break;

      case 0.75:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[6].url
        );
        setLegendCurrent(data.answers_avaliable[6].legend);
        break;

      case 0.88:
        setImageCurrent(
          process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[7].url
        );
        setLegendCurrent(data.answers_avaliable[7].legend);
        break;
    }
  }, [value]);

  return (
    <S.StepFeeling>
      <S.Title>{info_perfum?.perfum_name}</S.Title>
      <ProgressCircles position={data.question_id} />
      <S.Description>{data?.title}</S.Description>
      <S.Information>
        Gire a bolinha para explorar as respostas. <br /> Clique na imagem ao
        centro
        <br /> para selecionar quantas alternativas quiser.
      </S.Information>

      <S.BoxCircular>
        <S.StyledCircularInput
          value={value}
          onChange={(v: number) => setValue(stepValue(v))}
        >
          <S.StyledCircularTrack></S.StyledCircularTrack>
          <S.StyledCircularProgress
            stroke={`#494C4F`}
          ></S.StyledCircularProgress>
          <S.StyledCircularThumb />
        </S.StyledCircularInput>
        {/* <S.Image
          src={imageCurrent}
          onClick={() => handleSelectedItem(legendCurrent, imageCurrent)}
        /> */}
        <S.Image
          disable={Number(value.toFixed(2)) !== 1}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[0].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[0].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[0].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[0].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[0].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[0].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.13}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[1].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[1].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[1].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.25}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[2].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[2].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[2].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.38}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[3].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[3].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[3].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.5}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[4].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[4].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[4].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.63}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[5].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[5].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[5].url
            )
          }
        />
        <S.Image
          disable={Number(value.toFixed(2)) !== 0.75}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[6].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[6].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[6].url
            )
          }
        />

        <S.Image
          disable={Number(value.toFixed(2)) !== 0.88}
          src={
            process.env.REACT_APP_BASEURL_IMAGES + data.answers_avaliable[7].url
          }
          onClick={() =>
            handleSelectedItem(
              data.answers_avaliable[7].legend,
              process.env.REACT_APP_BASEURL_IMAGES +
                data.answers_avaliable[7].url
            )
          }
        />
      </S.BoxCircular>
      <S.SubTitle>{legendCurrent}</S.SubTitle>
      {data.answers_user.length >= 1 ? (
        <S.Options>
          {answersUser.map((select: any) => (
            <S.Option key={select.answer_user} img={select.answer_url} />
          ))}
          {/* <S.AddColor>+</S.AddColor> */}
        </S.Options>
      ) : (
        <S.Options>
          {selected.map((select) => (
            <S.Option key={select.legend} img={select.url} />
          ))}
          <S.AddColor onClick={() => handleRemoveLastItem()}>-</S.AddColor>
          <S.Input
            placeholder="outro"
            value={otherOption}
            onChange={(e) => setOtherOption(e.target.value)}
          />
        </S.Options>
      )}
      {selected.length >= 1 ||
      data.answers_user.length >= 1 ||
      otherOption.length >= 1 ? (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => handleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => handleStep()} />
          )}
        </>
      ) : (
        <Button name="ENVIAR" theme="disabled" />
      )}
    </S.StepFeeling>
  );
};

export default ImageCircle;
