import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    pointer-events: none;
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.sizes.medium};
    color: ${theme.colors.secondGray};
    border-bottom: 1px solid #ddd;

    .content__name {
      position: absolute;
      bottom: 0.5rem;
      transition: all 0.3s ease-in-out;
      /* text-transform: uppercase; */
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: -1px;
      border-bottom: 1px solid ${theme.colors.gray};
      transform: translateX(-100%);
      transition: all 0.5s ease-in-out;
    }
  `}
`;
export const Input = styled.input`
  padding-top: 3rem;
  width: 100%;
  border: 0;
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};

    ::placeholder {
      color: transparent;
    }

    &:focus + ${Label} .content__name,
    &:not(:placeholder-shown) + ${Label} .content__name {
      transform: translateY(-100%);
      left: 0;
      font-size: 1.4rem;
    }

    &:focus + ${Label}::after, &:not(:placeholder-shown) + ${Label}::after {
      transform: translateX(0);
    }
  `}
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
      color: ${theme.colors.primary};
    }
  `}
`;
