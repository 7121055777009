import { optionProps } from 'components/SelectSkin';
import { action } from 'typesafe-actions';
import { JourneyState, responseUser, SecondNoteUser } from './types';

export function perfumesRequest(userId: string | null, journeyId: number) {
  return action('@perfumes/PERFUMES_REQUEST', { userId, journeyId });
}

export function perfumesSuccess(data: JourneyState) {
  return action('@perfumes/PERFUMES_SUCCESS', {
    data,
  });
}

export function perfumesFailure() {
  return action('@perfumes/PERFUMES_FAILURE');
}

export function perfumsSelected(data: number[]) {
  return action('@perfumes/ADD_PERFUM', { data });
}

export function questionsRequest(
  userId: string | null,
  journeyId: number,
  id: number
) {
  return action('@perfumes/QUESTIONS_REQUEST', { userId, journeyId, id });
}

export function questionsSuccess(data: JourneyState) {
  return action('@perfumes/QUESTIONS_SUCCESS', {
    data,
  });
}

export function questionsFailure() {
  return action('@perfumes/QUESTIONS_FAILURE');
}

export function responseUserRequest(data: responseUser, tags?: any) {
  return action('@questions/RESPONSE_REQUEST', { data, tags });
}
export function responseUserSuccess(data: JourneyState) {
  return action('@questions/RESPONSE_SUCCESS');
}
export function responseUserFailure() {
  return action('@questions/RESPONSE_FAILURE');
}

export function secondNotesRequest(
  userId: string | null,
  journeyId: number,
  data: number[]
) {
  return action('@perfumes/SECONDNOTES_REQUEST', { userId, journeyId, data });
}

export function secondNotesSuccess(data: JourneyState) {
  return action('@perfumes/SECONDNOTES_SUCCESS', {
    data,
  });
}

export function secondNotesFailure() {
  return action('@perfumes/SECONDNOTES_FAILURE');
}

export function shippingSecondNotesRequest(data: any) {
  return action('@perfumes/SHIPPING_SECONDNOTES_REQUEST', data);
}

export function shippingSecondNotesSuccess() {
  return action('@perfumes/SHIPPING_SECONDNOTES_SUCCESS');
}

export function shippingSecondNotesFailure() {
  return action('@perfumes/SHIPPING_SECONDNOTES_FAILURE');
}

export function userSecondNotes(data: JourneyState) {
  return action('@perfumes/USER_SECONDNOTES', { data });
}

export function nextPerfum() {
  return action('@perfumes/PROX_PERFUM');
}

export function resetPerfum() {
  return action('@perfumes/RESET_PERFUM');
}

export function resetStep() {
  return action('@perfumes/RESET_STEP');
}

export function nextStep() {
  return action('@perfumes/NEXT_STEP');
}

export function prevStep() {
  return action('@perfumes/PREV_STEP');
}

export function openModalFinish() {
  return action('@perfumes/OPEN_MODAL_FINISH');
}

export function closeModalFinish() {
  return action('@perfumes/CLOSE_MODAL_FINISH');
}

export function closeModalFinishSecondNotes() {
  return action('@perfumes/CLOSE_MODAL_SECONDNOTES');
}

export function initJourneyHistory() {
  return action('@perfumes/HISTORY_JOURNEY_ACTIVE');
}

export function finishJourneyHistory() {
  return action('@perfumes/HISTORY_JOURNEY_DISABLED');
}

export function skinTestsRequest(
  userId: string | null,
  journeyId: number,
  perfum_id?: number
) {
  return action('@perfumes/SKIN_TEST_REQUEST', {
    userId,
    journeyId,
    perfum_id,
  });
}

export function skinTestsSuccess(data: optionProps[]) {
  return action('@perfumes/SKIN_TEST_SUCCESS', data);
}

export function skinTestsFailure() {
  return action('@perfumes/SKIN_TEST_FAILURE');
}

export function selectedPerfumSkinTest(id: number) {
  return action('@perfumes/SELECTED_SKIN_TEST', { id });
}

export function skinTestsSendingRequest(
  userId: string | null,
  journeyId: number,
  perfum_id?: number
) {
  return action('@perfumes/SKIN_TEST_SENDING_REQUEST', {
    userId,
    journeyId,
    perfum_id,
  });
}

export function skinTestsSendingSuccess() {
  return action('@perfumes/SKIN_TEST_SENDING_SUCCESS');
}

export function skinTestsSendingFailure() {
  return action('@perfumes/SKIN_TEST_SENDING_FAILURE');
}
