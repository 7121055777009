import * as S from './styles';

type ButtonProps = {
  name: string;
  link?: boolean;
  to?: string;
  theme?: 'primary' | 'secondary' | 'disabled' | 'history' | 'journey';
  type?: any;
  onClick?: () => void;
};

const Button = ({
  name,
  link = false,
  to = '/',
  theme = 'primary',
  onClick,
  type,
}: ButtonProps) => {
  const isLink = link;
  return (
    <>
      {isLink ? (
        <S.Navlink btn={theme} to={to}>
          {name}
        </S.Navlink>
      ) : (
        <S.Button btn={theme} onClick={onClick} type={type}>
          {name}
        </S.Button>
      )}
    </>
  );
};

export default Button;
