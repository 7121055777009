import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import img1 from '../../assets/images/guides/1.png';
import img2 from '../../assets/images/guides/2.png';
import img3 from '../../assets/images/guides/3.png';
import img4 from '../../assets/images/guides/4.png';
import img5 from '../../assets/images/guides/5.png';
import img42 from '../../assets/images/guides/4.2.png';
import { useState } from 'react';

export default function Guide() {
  const [currentSlide, setCurrentSlide] = useState(0);

  function next() {
    if (currentSlide < 5) {
      setCurrentSlide((oldState) => ++oldState);
    }
  }

  function prev() {
    if (currentSlide > 0) {
      setCurrentSlide((oldState) => --oldState);
    }
  }

  function updateCurrentSlide(index: number) {
    const slide = currentSlide;

    if (slide !== index) {
      setCurrentSlide(index);
    }
  }

  return (
    <>
      <S.Wrapper>
        <Navbar />
        <S.Container>
          <S.Content>
            <Carousel
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              useKeyboardArrows={false}
              autoPlay={false}
              infiniteLoop={false}
              emulateTouch={false}
            >
              <div>
                <S.Title className="text1">
                  Mais que um kit <h1>EXPERIÊNCIA AMYI</h1>
                </S.Title>
                <S.Image className="img1" src={img1} />
                <S.Description className="desc1">
                  Você irá viver uma das experiências mais inovadoras <br /> do
                  mundo para escolher seu novo perfume.
                </S.Description>
              </div>
              <div>
                <S.Title className="text1">
                  Busque um <h1>AMBIENTE TRANQUILO</h1>
                </S.Title>
                <S.Image className="img2" src={img2} />
                <S.Description className="desc2">
                  Nos próximos 20 minutos, esteja com você e vivencie,
                  <br /> três perfumes seguindo o roteiro sensorial.
                </S.Description>
              </div>
              <div>
                <S.Title className="text1">
                  Compreenda seus <h1>SENTIMENTOS E EMOÇÕES</h1>
                </S.Title>
                <S.Image className="img3" src={img3} />
                <S.Description className="desc3">
                  Primeiro, ajudaremos você a compreender o que <br /> cada
                  cheiro te desperta.
                </S.Description>
              </div>
              <div>
                <S.Title className="text1">
                  Aprenda sobre a <h1>ALTA PERFUMARIA</h1>
                </S.Title>
                <S.Image className="img4" src={img4} />
                <S.Description className="desc4">
                  Os perfumistas explicam suas criações e inspirações,
                  <br /> ajudando você a desvendar o universo da perfumaria.
                </S.Description>
              </div>
              <div>
                <S.Title className="text1">
                  Escolha, no seu tempo <h1>SEU NOVO PERFUME</h1>
                </S.Title>
                <S.Image className="img4" src={img42} />
                <S.Description className="desc4">
                  Ao final, você irá descobrir qual perfume Amyi
                  <br /> mais combina com você.
                </S.Description>
              </div>
              <div>
                <S.Title className="text1">
                  Chegou a hora <h1>Vamos lá?</h1>
                </S.Title>
                <S.Image className="img5" src={img5} />
                <S.Description>
                  Tenha uma deliciosa experiência ao encontro do seu novo
                  perfume!
                </S.Description>
              </div>
            </Carousel>
            {currentSlide === 5 ? (
              <Button name="PRÓXIMO" link to="/dashboard" />
            ) : (
              <S.ButtonWrapper>
                <Button name="PRÓXIMO" onClick={() => next()} />

                {currentSlide !== 0 && (
                  <Button
                    name="VOLTAR"
                    theme="secondary"
                    onClick={() => prev()}
                  />
                )}
              </S.ButtonWrapper>
            )}
          </S.Content>
        </S.Container>
        <Footer />
      </S.Wrapper>
    </>
  );
}
