import api from './api';

const RESOURCE = '/dashboard';

const dashboard = {
  dataUser: async (userId: string | null, journeyId: number) =>
    await api.get(RESOURCE + `?user_id=${userId}&journey_id=${journeyId}`),
};

export default dashboard;
