import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';
import ReactStars from 'react-rating-stars-component';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { InstapaperIcon, WhatsappIcon } from 'react-share';

import ranking from '../../assets/images/perfumes/ranking.svg';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import { perfumsRequest } from 'store/modules/perfums/actions';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';
import history from 'utils/history';
import divisorArrowIcon from 'assets/images/icones/divisor-arrow.svg';
import { ModalProductNotFound } from './ModalProductNotFound';
import { ListPerfum, PerfumsRanking } from 'store/modules/perfums/types';

const Perfume = () => {
  const [openModal, setOpenModal] = useState(false);
  const [productSelected, setProductSelected] = useState<ListPerfum>(
    {} as ListPerfum
  );

  const { perfumsRanking, loading } = useSelector(
    (state: StoreState) => state.perfums
  );

  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  const dispatch = useDispatch();

  const modalOpen = (perfumId: number) => {
    // setOpenModal(!openModal);
    history.push(`/revieworder/${perfumId}`);
  };

  const openModalProductNotFound = (perfum: ListPerfum) => {
    setProductSelected(perfum);
    setOpenModal(true);
  };

  useEffect(() => {
    dispatch(perfumsRequest(userId, journeyId));
  }, []);

  const handleRedirectShop = () => {
    document.location.href = perfumsRanking.shop_url;
  };

  const closeModalProductNotFound = () => {
    setOpenModal(false);
  };

  return (
    <S.Wrapper>
      <ModalProductNotFound
        open={openModal}
        product={productSelected}
        closeModal={closeModalProductNotFound}
      />
      <Navbar />
      <NavBack title="perfumes" />
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {perfumsRanking.podium?.length >= 3 ? (
            <S.Content>
              <S.Title>MEU RANKING</S.Title>
              <S.DescriptionJourney>
                {perfumsRanking.sub_legend}
              </S.DescriptionJourney>
              <S.BoxRanking>
                <S.Ranking
                  notFound={perfumsRanking.podium[1].perfum_stock === 'false'}
                >
                  <S.Title>2°</S.Title>
                  <div style={{ pointerEvents: 'none' }}>
                    <ReactStars
                      count={5}
                      value={perfumsRanking.podium[1].perfum_rating}
                      activeColor="#000"
                      color="#EBEBEB"
                      size={15}
                      filledIcon={AiFillStar}
                      emptyIcon={AiOutlineStar}
                    />
                  </div>
                  <S.Image
                    src={
                      process.env.REACT_APP_BASEURL_IMAGES +
                      perfumsRanking.podium[1].perfum_img
                    }
                  />
                  <S.Name>{perfumsRanking.podium[1].perfum_name}</S.Name>
                  <S.Description>
                    {perfumsRanking.podium[1].perfum_ingredients}
                  </S.Description>
                  <S.ButtonsRanking>
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[1].perfum_stock === 'true' && (
                        <Button
                          name="QUERO ESSE!"
                          onClick={() =>
                            modalOpen(perfumsRanking.podium[1].perfum_id)
                          }
                        />
                      )}
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[1].perfum_stock === 'false' && (
                        <S.NotFoundButton
                          onClick={() =>
                            openModalProductNotFound(perfumsRanking.podium[1])
                          }
                        >
                          AVISE-ME
                          <br /> QUANDO CHEGAR
                        </S.NotFoundButton>
                      )}
                    <S.ViewProductDetails
                      to={`perfumes/details/${perfumsRanking.podium[1].perfum_id}`}
                    >
                      ver detalhes
                    </S.ViewProductDetails>
                  </S.ButtonsRanking>
                </S.Ranking>
                <S.Ranking
                  notFound={perfumsRanking.podium[0].perfum_stock === 'false'}
                >
                  <S.Title>1°</S.Title>
                  <div style={{ pointerEvents: 'none' }}>
                    <ReactStars
                      count={5}
                      activeColor="#000"
                      value={perfumsRanking.podium[0].perfum_rating}
                      color="#EBEBEB"
                      size={15}
                      filledIcon={AiFillStar}
                      emptyIcon={AiOutlineStar}
                    />
                  </div>
                  <S.Image
                    src={
                      process.env.REACT_APP_BASEURL_IMAGES +
                      perfumsRanking.podium[0].perfum_img
                    }
                  />

                  <S.Name>{perfumsRanking.podium[0].perfum_name}</S.Name>
                  <S.Description>
                    {perfumsRanking.podium[0].perfum_ingredients}
                  </S.Description>
                  <S.ButtonsRanking>
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[0].perfum_stock === 'true' && (
                        <Button
                          name="QUERO ESSE!"
                          onClick={() =>
                            modalOpen(perfumsRanking.podium[0].perfum_id)
                          }
                        />
                      )}
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[0].perfum_stock === 'false' && (
                        <S.NotFoundButton
                          onClick={() =>
                            openModalProductNotFound(perfumsRanking.podium[0])
                          }
                        >
                          AVISE-ME
                          <br /> QUANDO CHEGAR
                        </S.NotFoundButton>
                      )}
                    <S.ViewProductDetails
                      to={`perfumes/details/${perfumsRanking.podium[0].perfum_id}`}
                    >
                      ver detalhes
                    </S.ViewProductDetails>
                  </S.ButtonsRanking>
                </S.Ranking>

                <S.Ranking
                  notFound={perfumsRanking.podium[2].perfum_stock === 'false'}
                >
                  <S.Title>3°</S.Title>
                  <div style={{ pointerEvents: 'none' }}>
                    <ReactStars
                      count={5}
                      activeColor="#000"
                      value={perfumsRanking.podium[2].perfum_rating}
                      color="#EBEBEB"
                      size={15}
                      filledIcon={AiFillStar}
                      emptyIcon={AiOutlineStar}
                    />
                  </div>

                  <S.Image
                    src={
                      process.env.REACT_APP_BASEURL_IMAGES +
                      perfumsRanking.podium[2].perfum_img
                    }
                  />
                  <S.Name>{perfumsRanking.podium[2].perfum_name}</S.Name>
                  <S.Description>
                    {perfumsRanking.podium[2].perfum_ingredients}
                  </S.Description>
                  <S.ButtonsRanking>
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[2].perfum_stock === 'true' && (
                        <Button
                          name="QUERO ESSE!"
                          onClick={() =>
                            modalOpen(perfumsRanking.podium[2].perfum_id)
                          }
                        />
                      )}
                    {perfumsRanking.shop_url === '' &&
                      perfumsRanking.podium[2].perfum_stock === 'false' && (
                        <S.NotFoundButton
                          onClick={() =>
                            openModalProductNotFound(perfumsRanking.podium[2])
                          }
                        >
                          AVISE-ME
                          <br /> QUANDO CHEGAR
                        </S.NotFoundButton>
                      )}
                    <S.ViewProductDetails
                      to={`perfumes/details/${perfumsRanking.podium[2].perfum_id}`}
                    >
                      ver detalhes
                    </S.ViewProductDetails>
                  </S.ButtonsRanking>
                </S.Ranking>
              </S.BoxRanking>

              <S.DivisorArrow src={divisorArrowIcon} />

              <S.SharedResult>
                <S.Title>Perfil olfativo:</S.Title>
                <S.Description>
                  <span>{perfumsRanking.profile_olfact.user_name}</span>, você
                  prefere perfumes {perfumsRanking.profile_olfact.type_perfum}{' '}
                  com notas {perfumsRanking.profile_olfact.perfums_notes[0]},{' '}
                  {perfumsRanking.profile_olfact.perfums_notes[1]} e{' '}
                  {perfumsRanking.profile_olfact.perfums_notes[2]}, que trazem
                  uma sensação de {perfumsRanking.profile_olfact.feelings}
                </S.Description>

                <S.BoxShare>
                  <S.Title>#amyievoce</S.Title>
                  <S.BoxHashTag>
                    {perfumsRanking?.hashtags?.map((item: string) => (
                      <S.Description key={item}>{item}</S.Description>
                    ))}
                  </S.BoxHashTag>
                </S.BoxShare>
                {/* <S.ShareButton onClick={() => history.push('/sharepodium')}>
                  COMPARTILHAR RESULTADO <GiShare size={15} />
                </S.ShareButton> */}
              </S.SharedResult>
            </S.Content>
          ) : (
            <S.Content>
              <S.Svg src={ranking} />
              <S.Title>Ranking</S.Title>
              <S.Description>{perfumsRanking.sub_legend}</S.Description>
              <Button
                name="FAZER JORNADA OLFATIVA"
                theme="secondary"
                to="/olfactory-journey"
                link
              />
            </S.Content>
          )}
          <S.Container>
            <S.SectionPerfumes>
              <S.Perfumes>
                {perfumsRanking?.list_perfum?.map((perfume) => (
                  <S.BoxPerfume key={perfume.perfum_id}>
                    <S.ContentBoxPerfume
                      notFound={perfume.perfum_stock === 'false'}
                    >
                      <S.Image
                        src={
                          process.env.REACT_APP_BASEURL_IMAGES +
                          perfume.perfum_img
                        }
                      />
                      <S.Infos>
                        {perfumsRanking.podium?.length >= 1 ? (
                          <>
                            <S.ContentPerfume>
                              <S.BoxTitle>
                                <S.Position>
                                  {perfume.perfum_position}°
                                </S.Position>
                                <div>
                                  <S.Title>{perfume.perfum_name}</S.Title>
                                  <S.Description>
                                    {perfume.perfum_ingredients}
                                  </S.Description>
                                </div>
                              </S.BoxTitle>
                            </S.ContentPerfume>
                          </>
                        ) : (
                          <>
                            <S.Title>{perfume.perfum_name}</S.Title>
                            <S.Description>
                              {perfume.perfum_ingredients}
                            </S.Description>
                          </>
                        )}

                        <S.Buttons>
                          {perfume.perfum_stock === 'true' ? (
                            <Button
                              name="QUERO ESSE!"
                              onClick={() => modalOpen(perfume.perfum_id)}
                            />
                          ) : (
                            <S.NotFoundButton
                              onClick={() => openModalProductNotFound(perfume)}
                            >
                              AVISE-ME <br /> QUANDO CHEGAR
                            </S.NotFoundButton>
                          )}

                          <S.ViewProductDetails
                            to={`perfumes/details/${perfume.perfum_id}`}
                          >
                            ver detalhes
                          </S.ViewProductDetails>
                        </S.Buttons>
                      </S.Infos>
                    </S.ContentBoxPerfume>
                  </S.BoxPerfume>
                ))}
              </S.Perfumes>
            </S.SectionPerfumes>

            {/* {perfumsRanking.shop_url !== '' && (
              <S.SectionInformation>
                <S.Information>
                  <S.Title>Muito mais vantagem</S.Title>
                  <S.Description>
                    Você se apaixonou por vários perfumes e está difícil
                    escolher um só? Não se preocupe! Agora você faz parte do
                    Clube Amyi e outros perfumes tem um preço muito especial.
                  </S.Description>

                  <Button
                    name="APROVEITAR AGORA"
                    onClick={handleRedirectShop}
                  />
                </S.Information>
                <Footer />
              </S.SectionInformation>
            )} */}
          </S.Container>
        </>
      )}
    </S.Wrapper>
  );
};

export default Perfume;
