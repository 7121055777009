import api from './api';

const BASEURL = '/bonus';

export const curiosityFacts = {
  findAllArticles: async () => await api.get(`${BASEURL}/articles`),
  findOneArticles: async (id: number) =>
    await api.get(`${BASEURL}/articles/article?article=${id}`),
  findAllWatch: async () => await api.get(`${BASEURL}/videos`),
  findAudios: async () => await api.get(`${BASEURL}/audios`),
};
