import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 100vh;
    margin: 0 auto;

    .wrapperImageWeb {
      max-width: ${theme.maxwidthMobile};
      margin: 0 auto;
    }

    ${media.greaterThan('small')`
      button, a {
        width: 20%;
        text-align: center;
      }
    `}
  `}
`;

export const TopBar = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    margin: 0 auto;
  `}
`;

export const Image = styled.img`
  width: 5rem;
  transform: translateY(0.4rem);
`;

export const NewTitle = styled.h1`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    color: ${theme.colors.secondBlack};
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    letter-spacing: 0.2rem;

    &::before {
      content: '';
      display: block;
      width: 7rem;
      height: 0.1rem;
      margin: 0 2rem;
      background-color: #404040;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xxlarge};
    font-weight: bold;
    letter-spacing: 0.1rem;
  `}
`;

export const BoxRanking = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 0.8rem;
    padding: 2rem;
    padding-top: 4rem;
    border-top: 1px solid ${theme.colors.secondGray};
    display: flex;

    ${media.greaterThan('small')`
      width: ${theme.maxwidthMobile};
      margin: 0 auto;
    `}
  `}
`;

export const Name = styled.div``;

export const Description = styled.p`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
    letter-spacing: 1px;
    text-align: center;

    font-size: ${theme.font.sizes.small};
  `}
`;

export const Buttons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 1rem;

    > button,
    a {
      height: 3.5rem;
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      width: 100%;
      font-size: 1.2rem;
    }
  `}
`;

export const Ranking = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 1.5rem 1rem; */
    width: 11rem;
    box-shadow: 0px 0px 8px #00000038;

    span {
      margin-left: 0.3rem;
    }

    &:nth-child(2) {
      transform: translateY(-2rem);
    }

    ${Title} {
      font-size: ${theme.font.sizes.xxextraLarge};
      color: ${theme.colors.black};
      font-family: ${theme.font.family.LoraVariable};
      font-weight: bold;
      text-align: center;
      margin-left: 1.5rem;
    }

    ${Buttons} {
      display: flex;
      flex-direction: column;

      > a {
        &:first-child {
          font-size: 0.9rem;
        }
      }

      > button {
        margin-top: 1rem;
        font-size: 0.9rem;
        height: 4rem;
      }
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      padding: 0;
      margin: 1rem 0 2rem 0;
      width: 100%;
      max-width: 100%;
      font-size: 0.8rem;
    }

    ${Image} {
      margin: 1rem 0;
      width: 10rem;
    }

    ${Name} {
      font-weight: bold;
      letter-spacing: 0.2rem;
      font-size: ${theme.font.sizes.medium};
      font-family: ${theme.font.family.LoraRegular};
      margin-top: 1rem;
      margin-bottom: 0;
    }
  `}
`;

export const Line = styled.img`
  display: block;
  margin: 0.6rem auto;
`;

export const SharedResult = styled.div`
  ${({ theme }) => css`
    text-align: center;

    ${Title} {
      color: #404040;
      font-family: ${theme.font.family.LoraVariable};
      font-size: ${theme.font.sizes.large};
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      color: ${theme.colors.black};
      line-height: 2rem;
    }
  `}
`;

export const BoxBottom = styled.div`
  ${({ theme }) => css`
    background-color: #404040;
    height: 14rem;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    display: flex;
    justify-content: space-around;
    padding-top: 2rem;

    ${Title} {
      color: ${theme.colors.white};
      font-size: 1.6rem;
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  padding: 2rem 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a,
  button {
    max-width: 50%;
    font-size: 1.3rem;
  }
`;

export const DownloadButton = styled.a`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: normal;
    letter-spacing: 0.1rem;

    font-size: ${theme.font.sizes.medium};

    height: 3.8rem;
  `}
`;

export const Links = styled(Link)`
  ${({ theme }) => css`
    margin-top: 2rem;
    color: #0c0c0c;
    text-decoration: underline;
  `}
`;

export const AreaPrint = styled.div``;
