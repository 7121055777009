import {
  AccountAction,
  AccountState,
  DetailsOrder,
  DetailsAddress,
  InfoUser,
  Journeys,
} from './types';

const initialState: AccountState = {
  error: false,
  loading: false,
  loadingCreatingManual: false,
  detailsOrder: {} as DetailsOrder,
  detailsAddress: {} as DetailsAddress,
  info_user: {} as InfoUser,
  orders: [],
  journeys: {} as Journeys,
  journeyId: 0,
};

export default function account(
  state = initialState,
  action: AccountAction
): AccountState {
  switch (action.type) {
    case '@account/ORDERS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@account/ORDERS_SUCCESS':
      return {
        ...state,
        loading: false,
        orders: action.payload.data,
      };
    case '@account/ORDERS_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };

    case '@account/DETAILS_ORDERS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@account/DETAILS_ORDERS_SUCCESS':
      return {
        ...state,
        loading: false,
        detailsOrder: action.payload.data,
      };
    case '@account/DETAILS_ORDERS_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case '@account/DETAILS_ADDRESS_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@account/DETAILS_ADDRESS_SUCCESS':
      return {
        ...state,
        loading: false,
        detailsAddress: action.payload.data,
      };
    case '@account/DETAILS_ADDRESS_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case '@account/DETAILS_ACCOUNT_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@account/DETAILS_ACCOUNT_SUCCESS':
      return {
        ...state,
        loading: false,
        info_user: action.payload.data,
      };
    case '@account/DETAILS_ACCOUNT_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case '@account/HOME_DATA_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@account/HOME_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        journeys: action.payload.Journeys,
      };
    case '@account/HOME_DATA_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case '@account/SELECT_JOURNEY_REQUEST':
      return {
        ...state,
        journeyId: action.payload.journey_id,
      };

    case '@account/CREATING_JOURNEY_REQUEST':
      return {
        ...state,
        loading: true,
        loadingCreatingManual: true,
      };
    case '@account/CREATING_JOURNEY_SUCCESS':
      return {
        ...state,
        loadingCreatingManual: false,
        loading: false,
        journeyId: action.payload.journey_id,
      };
    case '@account/CREATING_JOURNEY_FAILURE':
      return {
        ...state,
        loading: false,
        loadingCreatingManual: false,
      };

    default:
      return state;
  }
}
