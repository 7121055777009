import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import ReactCompareImage from 'react-compare-image';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';

type ImageCompareProps = {
  data: Question;
};

const ImageCompare = ({ data }: ImageCompareProps) => {
  const [position, setPosition] = useState(0);
  const [answersUser, setAnswersUser] = useState<any>([]);
  const [status, setStatus] = useState('');
  const [urlSelected, setUrlSelected] = useState('');
  const { perfum_id, perfum_name } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const { loading } = useSelector((state: StoreState) => state.journey);
  const userId = localStorage.getItem('user_id_amyi');

  const dispatch = useDispatch();

  const HandleStep = () => {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: [status],
      answers_url: [urlSelected],
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  };

  useEffect(() => {
    if (position < 0.5) {
      setStatus(data.answers_avaliable[1].legend);
      setUrlSelected(data.answers_avaliable[1].url);
    } else {
      setStatus(data.answers_avaliable[0].legend);
      setUrlSelected(data.answers_avaliable[0].url);
    }
  }, [position, data]);

  useEffect(() => {
    setAnswersUser(
      data.answers_user.length >= 1 ? data.answers_user?.[0].answer_user : ''
    );

    if (answersUser[0]?.answer_user === data?.answers_avaliable[1]?.legend) {
      setPosition(0);
    } else {
      setPosition(1);
    }
  }, [answersUser, data]);

  return (
    <S.StepImages>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          {data.answers_user.length ? (
            <>
              <ProgressCircles position={data.question_id} />
              <S.Description>{data.title}</S.Description>
              <S.WrapperCompare>
                <ReactCompareImage
                  leftImage={
                    process.env.REACT_APP_BASEURL_IMAGES +
                    data.answers_user[0].answer_url
                  }
                  rightImage={
                    process.env.REACT_APP_BASEURL_IMAGES +
                    data.answers_user[0].answer_url
                  }
                  onSliderPositionChange={setPosition}
                />
                <S.DescriptionImgAnswerUser>
                  <S.NameImageAnswerUser>
                    {data.answers_user[0].answer_user}
                  </S.NameImageAnswerUser>
                </S.DescriptionImgAnswerUser>
              </S.WrapperCompare>
            </>
          ) : (
            <>
              <ProgressCircles position={data.question_id} />
              <S.Description>{data.title}</S.Description>
              <S.WrapperCompare>
                <ReactCompareImage
                  leftImage={
                    process.env.REACT_APP_BASEURL_IMAGES +
                    data.answers_avaliable[0].url
                  }
                  rightImage={
                    process.env.REACT_APP_BASEURL_IMAGES +
                    data.answers_avaliable[1].url
                  }
                  onSliderPositionChange={setPosition}
                />
                <S.DescriptionImg>
                  <S.NameImageLeft position={position}>
                    {data.answers_avaliable[0].legend}
                  </S.NameImageLeft>

                  <S.NameImageRight position={position}>
                    {data.answers_avaliable[1].legend}
                  </S.NameImageRight>
                </S.DescriptionImg>
              </S.WrapperCompare>
            </>
          )}
        </>
      )}

      {position === 0 ? (
        <Button name="PRÓXIMO" theme="journey" />
      ) : (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => HandleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => HandleStep()} />
          )}
        </>
      )}
    </S.StepImages>
  );
};

export default ImageCompare;
