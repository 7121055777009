import styled, { css, DefaultTheme } from 'styled-components';
import { ReactSVG } from 'react-svg';

export const Wrapper = styled.main``;

export const Vote = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;

const tagModifiers = {
  naogostei: (theme: DefaultTheme) => css`
    transform: translateX(-57%);
  `,
  gosteipouco: (theme: DefaultTheme) => css`
    transform: translateX(-20%);
  `,
  gostei: (theme: DefaultTheme) => css`
    transform: translateX(4%);
  `,
  gosteimuito: (theme: DefaultTheme) => css`
    transform: translateX(45%);
  `,
  amei: (theme: DefaultTheme) => css`
    transform: translateX(110%);
  `,
};

export type TagProps = {
  note: 'naogostei' | 'gosteipouco' | 'gostei' | 'gosteimuito' | 'amei';
};

export const Tag = styled.div<TagProps>`
  ${({ theme, note }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.secondBlack};

    p {
      padding: 0.5rem 1rem;
      display: flex;
      flex-direction: column;

      &:before {
        content: '';
        display: block;
        position: fixed;
        top: -5px;
        left: 40%;
        width: 1rem;
        height: 1rem;
        transform: rotate(135deg);
        background-color: ${theme.colors.secondBlack};
      }
    }
    ${!!note && tagModifiers[note](theme)}
  `}
`;

export const Svg = styled(ReactSVG)`
  width: 3rem;
  height: 3rem;
  margin: 1rem;
`;
