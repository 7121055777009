const theme = {
  maxwidthDesktop: '110rem',
  maxwidthMobile: '35rem',
  colors: {
    primary: '#EBEBEB',
    white: '#FFFFFF',
    black: '#0C0C0C',
    secondBlack: '#494C4F ',
    fourthGray: '#494C4F',
    gray: '#00000029',
    secondGray: '#BFBFBF',
    threeGray: '#F1F1F1',
    green: '#4E741C',
    orange: '#E5CD99',
    secondOrange: '#C48600',
    lineGray: '#707070',
    borderBoxGray: '#8E8E8E',
  },

  border: {
    radius: {
      xsmall: '0.5rem',
      small: '1.0rem',
      medium: '1.5rem',
      large: '2rem',
      xlarge: '2.5rem',
    },
  },

  font: {
    family: {
      encodeSansLight: 'EncodeSansLight',
      EncodeSansMedium: 'Encode-SansMedium',
      LoraItalic: 'LoraItalic',
      LoraRegular: 'LoraRegular',
      LoraVariable: 'LoraVariable',
      YaldeviMedium: 'YaldeviMedium',
      YaldeviSemiBold: 'YaldeviSemiBold',
      YaldeviRegular: 'YaldeviRegular',
    },
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2rem',
      xxlarge: '2.2rem',
      xxxlarge: '2.4rem',
      extraLarge: '2.6rem',
      xextraLarge: '2.8rem',
      xxextraLarge: '3.2rem',
      xxxextraLarge: '3.6rem',
      xxxxextraLarge: '4.2rem',
      extrabiglarge: '4.6rem',
      xextrabiglarge: '5.0rem',
    },
  },

  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    xmedium: '2.8rem',
    medium: '3.2rem',
    large: '4.4rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
    xxxlarge: '9rem',
    extraLarge: '16rem',
  },
};

export default theme;
