import { action } from 'typesafe-actions';
import {
  Billing,
  CreateJourney,
  CreateJourneyManual,
  DetailsAddress,
  DetailsOrder,
  InfoUser,
  Journey,
  Journeys,
  Order,
  ProfileEditing,
  ShippingAccount,
} from './types';

export function accountOrdersRequest(userId: string | null) {
  return action('@account/ORDERS_REQUEST', { userId });
}

export function accountOrdersSuccess({ data }: { data: Order[] }) {
  return action('@account/ORDERS_SUCCESS', { data });
}

export function accountOrdersFailure() {
  return action('@account/ORDERS_FAILURE');
}

export function detailsOrdersRequest(id: number) {
  return action('@account/DETAILS_ORDERS_REQUEST', { id });
}

export function detailsOrdersSuccess({ data }: { data: DetailsOrder }) {
  return action('@account/DETAILS_ORDERS_SUCCESS', { data });
}

export function detailsOrdersFailure() {
  return action('@account/DETAILS_ORDERS_FAILURE');
}

export function detailsAddressRequest(
  userId: string | null,
  journeyId: number
) {
  return action('@account/DETAILS_ADDRESS_REQUEST', { userId, journeyId });
}

export function detailsAddressSuccess({ data }: { data: DetailsAddress }) {
  return action('@account/DETAILS_ADDRESS_SUCCESS', { data });
}

export function detailsAddressFailure() {
  return action('@account/DETAILS_ADDRESS_FAILURE');
}

export function detailsAccountRequest(userId: string | null) {
  return action('@account/DETAILS_ACCOUNT_REQUEST', { userId });
}

export function detailsAccountSuccess({ data }: { data: InfoUser }) {
  return action('@account/DETAILS_ACCOUNT_SUCCESS', { data });
}

export function detailsAccountFailure() {
  return action('@account/DETAILS_ACCOUNT_FAILURE');
}

export function editingAccountRequest(
  data: ProfileEditing,
  userId: string | null
) {
  return action('@account/EDITING_ACCOUNT_REQUEST', { data, userId });
}

export function editingAccountSuccess() {
  return action('@account/EDITING_ACCOUNT_SUCCESS');
}

export function editingAccountFailure() {
  return action('@account/EDITING_ACCOUNT_FAILURE');
}

export function editingAddressRequest(
  data: ShippingAccount | Billing,
  userId: string | null
) {
  return action('@account/EDITING_ADDRESS_REQUEST', { data, userId });
}

export function editingAddressSuccess() {
  return action('@account/EDITING_ADDRESS_SUCCESS');
}

export function editingAddressFailure() {
  return action('@account/EDITING_ADDRESS_FAILURE');
}

export function getHomeDataRequest(user_id: number) {
  return action('@account/HOME_DATA_REQUEST', { user_id });
}

export function getHomeDataSuccess({ Journeys }: { Journeys: Journeys }) {
  return action('@account/HOME_DATA_SUCCESS', { Journeys });
}

export function getHomeDataFailure() {
  return action('@account/HOME_DATA_FAILURE');
}

export function creatingJourneyRequest(data: CreateJourney) {
  return action('@account/CREATING_JOURNEY_REQUEST', { data });
}

export function creatingJourneySuccess(journey_id: number) {
  return action('@account/CREATING_JOURNEY_SUCCESS', { journey_id });
}

export function creatingJourneyFailure() {
  return action('@account/CREATING_JOURNEY_FAILURE');
}

export function creatingJourneyManualRequest(data: CreateJourneyManual) {
  return action('@account/CREATING_JOURNEY_REQUEST', { data });
}

export function creatingJourneyManualSuccess() {
  return action('@account/CREATING_JOURNEY_MANUAL_SUCCESS');
}

export function creatingJourneyManualFailure() {
  return action('@account/CREATING_JOURNEY_FAILURE');
}

export function selectJourneyId(journey_id: number) {
  return action('@account/SELECT_JOURNEY_REQUEST', { journey_id });
}
