import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';
import { GrFormClose } from 'react-icons/gr';

function RequestsMoviment() {
  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Closed to="/requests">
          <GrFormClose size={28} />
        </S.Closed>
        <S.Content>
          <S.Title>Últimas Movimentações</S.Title>
          <S.SubTitle>Pedido #123456</S.SubTitle>

          <S.Table>
            <tr>
              <th>Data</th>
              <th>Status</th>
            </tr>
            <tr>
              <td>11/12/2020</td>
              <td>Enviando</td>
            </tr>
            <tr>
              <td>10/12/2020</td>
              <td>Processando</td>
            </tr>
            <tr>
              <td>10/12/2020</td>
              <td>Aguardando</td>
            </tr>
          </S.Table>

          <S.SubTitle>Legendas</S.SubTitle>
          <S.BoxLegends>
            <S.Description>
              Aguardando: recebemos o seu pedido e vamos confirmar seu
              pagamento.
            </S.Description>
            <S.Description>
              Cancelado: seu pagamento não foi identificado dentro do prazo de
              recebimento e foi cancelado.
            </S.Description>
            <S.Description>
              Processando: pagamento identificado e seu pedido será liberado
              para envio. Assim que ele for coletado pelos Correios, você
              receberá seu código de rastreio por e-mail.
            </S.Description>
            <S.Description>
              Enviado: o processamento do seu pedido foi concluído em nosso
              sistema e sua entrega está a caminho. Se você comprou a
              Experiência Amyi, saiba que a escolha do seu perfume preferido não
              tem prazo limite. Escolha no seu tempo.
            </S.Description>
          </S.BoxLegends>
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}

export default RequestsMoviment;
