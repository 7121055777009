import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import { useEffect, useState } from 'react';
import { Question } from 'store/modules/olfactoryJourney/types';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';

type CommentProps = {
  subdescription: string;
  data: Question;
};

type selectedProps = {
  title: string;
};

const Comment = ({ subdescription, data }: CommentProps) => {
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState<selectedProps[]>([]);
  const [answersUser, setAnswersUser] = useState('');

  const dispatch = useDispatch();
  const { perfum_name, perfum_id } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const userId = localStorage.getItem('user_id_amyi');

  const { loading } = useSelector((state: StoreState) => state.journey);

  function selection(title: string) {
    if (data.answers_user.length === 0) {
      const alredySelected = selected.findIndex((item) => item.title === title);

      if (alredySelected >= 0) {
        const filteredItems = selected.filter((item) => item.title !== title);
        setSelected(filteredItems);
      } else {
        setSelected([...selected, { title }]);
      }
    }
  }

  function handleStep() {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: [value],
      answers_url: [],
    };

    const tags = {
      ...selected.map((item) => item.title),
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser, tags));
  }

  useEffect(() => {
    setAnswersUser(
      data.answers_user.length >= 1 ? data.answers_user?.[0].answer_user : ''
    );

    if (data.answer_tags) {
      const array: selectedProps[] = [];

      data.answer_tags.map((item) => array.push({ title: item.answer_tags }));

      setSelected(array);
    }

    setValue(answersUser);
  }, [answersUser, data]);

  return (
    <S.StepThink>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data.question_id} />
          <S.Description>{data.title}</S.Description>
          <S.Span>{subdescription}</S.Span>
          <S.TextArea
            value={value}
            disabled={data.answers_user.length >= 1}
            onChange={({ target }) => setValue(target.value)}
            placeholder="CLIQUE PARA ESCREVER"
          />
          <S.Description>Precisa de uma inspiração?</S.Description>
          <S.BoxTips>
            {data.answers_avaliable.map((item: string) => (
              <S.HastagTips
                selected={selected.findIndex(
                  (selected) => selected.title === item
                )}
                key={item}
                onClick={() => selection(item)}
              >
                {item}
              </S.HastagTips>
            ))}
          </S.BoxTips>
        </>
      )}
      <>
        {data.answers_user.length >= 1 ? (
          <Button name="CONTINUAR" onClick={() => handleStep()} />
        ) : (
          <Button name="ENVIAR" onClick={() => handleStep()} />
        )}
      </>
    </S.StepThink>
  );
};

export default Comment;
