import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

function PlayEducational() {
  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Content>
          <div>
            <S.Title>
              Conhecendo a <br /> perfumaria criativa
            </S.Title>
            <S.Iframe
              src="https://player.vimeo.com/video/577585306"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></S.Iframe>
            {/* <S.Description>
              Para começar, vamos conhecer os encantos da perfumaria mais
              criativa do mundo.
            </S.Description> */}
          </div>

          <Button name="PRÓXIMO" link to="/olfactory-journey/primary" />
        </S.Content>
      </S.Wrapper>
      <Footer />
    </>
  );
}

export default PlayEducational;
