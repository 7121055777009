import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const StepCarrousel = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 70%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${media.greaterThan('small')`
      > button, a {
        width: 25%;
      }
    `}
  `}
`;

export const Options = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;

type OptionColor = {
  value?: number;
  img?: string;
};

export const Option = styled.div<OptionColor>`
  ${({ theme, value, img }) => css`
    margin: 0 0.5rem;
    min-width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    background-color: ${!!value && `hsl(${value * 100}, 100%, 50%)`};
    border: 1px solid ${theme.colors.primary};

    background-image: url(${img});
    background-size: cover;
  `}
`;

export const AddColor = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #838384;

    min-width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 2px solid #838384;
    margin-right: 1rem;
  `}
`;

type ImageProps = {
  active?: number;
};

export const Image = styled.img<ImageProps>`
  ${({ active }) => css`
    max-width: 85%;
    margin: 0 auto;
    opacity: ${active !== -1 ? '0.6' : '1'};
    filter: ${active !== -1 ? 'grayscale(50%)' : 'grayscale(0%)'};
  `}
`;

export const Confirmed = styled.div`
  ${({ theme }) => css`
    /* width: 85%; */
    height: 200px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-top: -190px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    /* &:before {
      content: '';
      display: block;
      position: absolute;
      height: 250px;
      width: 120%;
      background: #000000 0% 0% no-repeat padding-box;
      opacity: 0.15;
    } */

    svg {
      width: 8rem;
      height: 8rem;
      color: ${theme.colors.white};
      z-index: 99;
    }

    ${media.greaterThan('small')`
      height: 400px;
      margin-top: -400px;
      &:before {
        position: absolute;
        top: 0;
        width: 600px;
        height: 400px;
      }
    `}
  `}
`;

export const WrapperCarousel = styled.div`
  width: 100%;
  margin-top: 2rem;

  .slick-prev {
    background: transparent;
    left: 0rem;
    top: 35%;
    width: 32px;
    height: 40px;
    z-index: 9;
    padding: 0rem 1rem;
  }

  .slick-next {
    right: 2rem;
    top: 35%;
    width: 32px;
    height: 40px;
    padding: 0rem 3rem;
  }

  .slick-prev:before,
  .slick-next:before {
    color: gray;
    font-size: 40px;
  }

  .slick-slide {
    transition: 0.5s ease;
    opacity: 0.5;

    .confirmed {
      &:before {
        display: none;
      }
    }

    img {
      max-width: 80%;
      margin-top: 1rem;
      object-fit: cover;
    }

    ${Options} {
      display: none;
    }
  }

  .slick-active {
    transition: 0.5s ease;
    opacity: 1;

    .confirmed {
      &:before {
        display: block;
      }
    }

    img {
      transition: transform 0.5s ease;
      transform: scale(1.2);

      /* margin-top: 2rem; */
      /* height: 200px; */
      object-fit: cover;
    }

    ${Options} {
      display: flex;
    }
  }

  ${media.greaterThan('small')`
    width: 40%;

    .slick-prev, .slick-next {
      top: 40%;
    } 

    .slick-active {
      img {
        height: 400px;
      }
    }

    .slick-slide {
      img {
        height: 300px;
      }
    }
  `}
`;

export const Legend = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.YaldeviRegular};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    text-align: center;
    margin-top: 3rem;
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary};
    text-align: center;
    font-family: ${theme.font.family.YaldeviRegular};
    height: 2.8rem;
    font-size: 16px;
  `}
`;

export const Information = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.xsmall};
    margin: 1rem 0;
  `}
`;
