import {
  CuriosityAction,
  CuriosityState,
  Article,
  Video,
  Audio,
} from './types';

const initialState: CuriosityState = {
  error: false,
  loading: false,
  loadingModal: false,
  videos: [] as Video[],
  articles: [] as Article[],
  article: [] as Article[],
  audios: [] as Audio[],
};

export default function curiosityFacts(
  state = initialState,
  action: CuriosityAction
): CuriosityState {
  switch (action.type) {
    case '@curiosityFacts/ARTICLES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@curiosityFacts/ARTICLES_SUCCESS':
      return {
        ...state,
        loading: false,
        articles: action.payload.data,
      };
    case '@curiosityFacts/ARTICLES_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case '@curiosityFacts/ARTICLE_DETAILS_REQUEST':
      return {
        ...state,
        loadingModal: true,
      };
    case '@curiosityFacts/ARTICLE_DETAILS_SUCCESS':
      return {
        ...state,
        loadingModal: false,
        article: action.payload.data,
      };
    case '@curiosityFacts/ARTICLE_DETAILS_FAILURE':
      return {
        ...state,
        error: true,
        loadingModal: false,
      };
    case '@curiosityFacts/VIDEO_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@curiosityFacts/VIDEO_SUCCESS':
      return {
        ...state,
        loading: false,
        videos: action.payload.data,
      };
    case '@curiosityFacts/VIDEO_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case '@curiosityFacts/AUDIO_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case '@curiosityFacts/AUDIO_SUCCESS':
      return {
        ...state,
        loading: false,
        audios: action.payload.data,
      };
    case '@curiosityFacts/AUDIO_FAILURE':
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
