import * as S from './styles';

import infoCircle from '../../../assets/images/icones/info-green.svg';
import { Link } from 'react-router-dom';

export type SubNavProps = {
  pass?: number;
};

const SubNav = ({ pass = 1 }: SubNavProps) => (
  <S.Wrapper>
    <S.Content>
      <S.BoxLeft>
        <S.Title>jornada sensorial e educativa</S.Title>
        {pass === 1 && (
          <S.Circles>
            <S.Circle className="active" pass={pass}>
              <span>{pass}</span>
            </S.Circle>
            <S.Circle className="disabled center" />
            <S.Circle className="disabled center" />
            <S.Circle className="disabled" />
          </S.Circles>
        )}
        {pass === 2 && (
          <S.Circles>
            <S.Circle className="disabled"></S.Circle>
            <S.Circle className="active center" pass={pass}>
              <span>{pass}</span>
            </S.Circle>
            <S.Circle className="disabled" />
            <S.Circle className="disabled" />
          </S.Circles>
        )}
        {pass === 3 && (
          <S.Circles>
            <S.Circle className="disabled"></S.Circle>
            <S.Circle className="disabled center" />
            <S.Circle className="active" pass={pass}>
              <span>{pass}</span>
            </S.Circle>
            <S.Circle className="disabled center" />
          </S.Circles>
        )}
        {pass === 4 && (
          <S.Circles>
            <S.Circle className="disabled"></S.Circle>
            <S.Circle className="disabled center" />
            <S.Circle className="disabled" />
            <S.Circle className="active" pass={pass}>
              <span>{pass}</span>
            </S.Circle>
          </S.Circles>
        )}
      </S.BoxLeft>
      <S.BoxRight>
        <Link to="/instructions">
          <S.Svg src={infoCircle} />
        </Link>
      </S.BoxRight>
    </S.Content>
  </S.Wrapper>
);

export default SubNav;
