import NavBack from 'components/NavBack';
import Navbar from 'components/Navbar';
import * as S from './styles';
import logo from 'assets/images/logo.png';
import ReactStars from 'react-rating-stars-component';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import line from './line.png';
import Button from 'components/Button';
import html2canvas from 'html2canvas';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toJpeg } from 'html-to-image';
import renderImageToPrint from 'utils/renderImageToPrint';
import history from 'utils/history';
import { perfumsRequest } from 'store/modules/perfums/actions';

export default function SharePodium() {
  const [imagem, setImagem] = useState<any>();
  const { perfumsRanking, loading } = useSelector(
    (state: StoreState) => state.perfums
  );
  const userId = localStorage.getItem('user_id_amyi');
  const { journeyId } = useSelector((state: StoreState) => state.account);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(perfumsRequest(userId, journeyId));
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toJpeg(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'amyi.jpeg';
        link.href = dataUrl;
        link.click();
        history.push('/sharepodium/success');
      })
      .catch((err) => {
        return err;
      });
  }, [ref]);

  return (
    <S.Wrapper>
      <Navbar />
      <NavBack title="perfumes" link="/perfumes" />

      <div className="wrapperImageWeb">
        <S.AreaPrint
          className="sectionPrint"
          ref={ref}
          style={{ backgroundColor: '#EBEBEB' }}
        >
          <S.TopBar>
            <S.Image src={logo} />
            <S.NewTitle>meus favoritos</S.NewTitle>
          </S.TopBar>

          <S.BoxRanking>
            <S.Ranking>
              <S.Image
                src={renderImageToPrint(
                  perfumsRanking.podium[1].perfum_name
                    .toLowerCase()
                    .replace(' ', '_')
                    .replace('.', '')
                )}
              />

              <div style={{ pointerEvents: 'none' }}>
                <ReactStars
                  count={5}
                  value={perfumsRanking.podium[1].perfum_rating}
                  activeColor="#000"
                  color="#EBEBEB"
                  size={15}
                  filledIcon={AiFillStar}
                  emptyIcon={AiOutlineStar}
                />
              </div>
              <S.Name>{perfumsRanking.podium[1].perfum_name}</S.Name>
              <S.Description>
                {perfumsRanking.podium[1].perfum_ingredients}
              </S.Description>
            </S.Ranking>
            <S.Ranking>
              <S.Image
                src={renderImageToPrint(
                  perfumsRanking.podium[0].perfum_name
                    .toLowerCase()
                    .replace(' ', '_')
                    .replace('.', '')
                )}
              />

              <div style={{ pointerEvents: 'none' }}>
                <ReactStars
                  count={5}
                  activeColor="#000"
                  value={perfumsRanking.podium[0].perfum_rating}
                  color="#EBEBEB"
                  size={15}
                  filledIcon={AiFillStar}
                  emptyIcon={AiOutlineStar}
                />
              </div>

              <S.Name>{perfumsRanking.podium[0].perfum_name}</S.Name>
              <S.Description>
                {perfumsRanking.podium[0].perfum_ingredients}
              </S.Description>
            </S.Ranking>

            <S.Ranking>
              <S.Image
                src={renderImageToPrint(
                  perfumsRanking.podium[2].perfum_name
                    .toLowerCase()
                    .replace(' ', '_')
                    .replace('.', '')
                )}
              />

              <div style={{ pointerEvents: 'none' }}>
                <ReactStars
                  count={5}
                  activeColor="#000"
                  value={perfumsRanking.podium[2].perfum_rating}
                  color="#EBEBEB"
                  size={15}
                  filledIcon={AiFillStar}
                  emptyIcon={AiOutlineStar}
                />
              </div>
              <S.Name>{perfumsRanking.podium[2].perfum_name}</S.Name>
              <S.Description>
                {perfumsRanking.podium[2].perfum_ingredients}
              </S.Description>
            </S.Ranking>
          </S.BoxRanking>

          <S.Line src={line} />

          <S.SharedResult>
            <S.Title>Perfil olfativo:</S.Title>
            <S.Description>
              {perfumsRanking.profile_olfact.user_name}, você prefere perfumes{' '}
              {perfumsRanking.profile_olfact.type_perfum} com notas{' '}
              {perfumsRanking.profile_olfact.perfums_notes[0]},{' '}
              {perfumsRanking.profile_olfact.perfums_notes[1]} e{' '}
              {perfumsRanking.profile_olfact.perfums_notes[2]}, que trazem uma
              sensação de {perfumsRanking.profile_olfact.feelings}
            </S.Description>
          </S.SharedResult>

          <S.BoxBottom>
            <S.Title>Descubra a sua coleção</S.Title>
            <S.Title>@amyi_perfumes</S.Title>
          </S.BoxBottom>
        </S.AreaPrint>
      </div>

      <S.ButtonWrapper onClick={() => onButtonClick()}>
        <S.DownloadButton download="file.png" href={imagem}>
          SALVAR RANKING
        </S.DownloadButton>
        <S.Links to="/perfumes">Agora não.</S.Links>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
}
