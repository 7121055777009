import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

function Account() {
  return (
    <>
      <S.Wrapper>
        <Navbar />
        <S.Content>
          <S.Title>Minha conta</S.Title>
          <S.Description>
            Olá, este é seu espaço para gerenciar seus dados.<br></br> O que
            você gostaria de acessar?
          </S.Description>

          <S.Links to="/dashboard">MINHA EXPERIÊNCIA AMYI</S.Links>
          <S.Links to="/requests">MEUS PEDIDOS</S.Links>
          <S.Links to="/profile">MEU PERFIL</S.Links>
          <S.Links to="/address">MEUS ENDEREÇOS</S.Links>
        </S.Content>
      </S.Wrapper>
      <Footer />
    </>
  );
}

export default Account;
