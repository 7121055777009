import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import * as S from './styles';

import familia from '../../../../assets/images/perfumes/details/familia.png';
import inovacao from '../../../../assets/images/perfumes/details/inovacao.png';
import intensidade from '../../../../assets/images/perfumes/details/intensidade.png';
import { Question } from 'store/modules/olfactoryJourney/types';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import { nextStep } from 'store/modules/olfactoryJourney/actions';
import LoadingRound from 'components/LoadingRound';

type DescVideoProps = {
  data: Question;
};

const DescVideo = ({ data }: DescVideoProps) => {
  const { info_perfum, loading } = useSelector(
    (state: StoreState) => state.journey
  );

  const dispatch = useDispatch();
  const handleNextStep = () => {
    dispatch(nextStep());
  };

  return (
    <S.StepTips>
      <S.Title>{info_perfum?.perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data.question_id} />
          <S.Description>{data.title}</S.Description>
          <S.Iframe
            width="300"
            height="160"
            src={data.answers_avaliable[0]}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></S.Iframe>
          {/* <S.GridInformations>
            <S.Information>
              <S.Image src={familia} />
              <S.Description>{info_perfum?.perfum_family}</S.Description>
            </S.Information>
            <S.Information>
              <S.Image src={intensidade} />
              <S.Description>{info_perfum?.perfum_intensity}</S.Description>
            </S.Information>
            <S.Information>
              <S.Image src={inovacao} />
              <S.Description>{info_perfum?.perfum_inovation}</S.Description>
            </S.Information>
          </S.GridInformations> */}
          <Button name="PRÓXIMO" onClick={() => handleNextStep()} />
        </>
      )}
    </S.StepTips>
  );
};

export default DescVideo;
