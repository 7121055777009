import { Switch, Route, Router } from 'react-router-dom';
import history from '../utils/history';

import Login from '../pages/Login';
import Welcome from 'pages/Welcome';
import Guide from 'pages/Guide';
import Dashboard from 'pages/Dashboard';
import OlfactoryJourney from 'pages/OlfactoryJourney';
import GuideOlfactory from 'pages/OlfactoryJourney/GuideOlfactory';
import Perfume from 'pages/Perfume';
import DetailsPerfume from 'pages/Perfume/DetailsPerfume';
import JourneyPrimary from 'pages/OlfactoryJourney/JourneyPrimary';
import Instructions from 'pages/Instructions';
import Account from 'pages/Account';
import MyRequests from 'pages/Requests';
import RequestsMoviment from 'pages/Requests/Moviment';
import RequestsDetails from 'pages/Requests/Details';
import Profile from 'pages/Requests/Profile';
import Address from 'pages/Requests/Address';
import GiftCode from 'pages/GiftCode';
import ReviewOrder from 'pages/ReviewOrder';
import History from 'pages/History';
import SkinTest from 'pages/SkinTest';
import ClubAmyi from 'pages/ClubAmyi';
import CuriosityFacts from 'pages/CuriosityFacts';
import NotesPerfum from 'pages/OlfactoryJourney/NotesPerfum';
import SecondNotes from 'pages/OlfactoryJourney/SecondNotes';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NotesFinallyProvider } from 'components/StarsVote/notesFinallyContext';
import SkinTestFlux from 'pages/OlfactoryJourney/SkinTestFlux';
import PlayEducational from 'pages/OlfactoryJourney/PlayEducational';
import Auth from 'pages/Auth';

import Index from 'pages/Index';

import ResponseUser from 'pages/OlfactoryJourney/ResponsesUser';
import CustomRoute from './CustomRoute';
import JourneyResponses from 'pages/History/DetailsResponse';
import WatchPage from 'pages/Watch';
import AlterPerfums from 'pages/OlfactoryJourney/AlterPerfums';
import SelectPerfumCorrect from 'pages/OlfactoryJourney/SelectPerfumCorrect';
import SharePodium from 'pages/Perfume/SharePodium';
import SharePodiumSuccess from 'pages/Perfume/SharePodiumSuccess';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Routes() {
  return (
    <Router history={history}>
      <ScrollToTop />
      <Switch>
        <NotesFinallyProvider>
          <CustomRoute path="/auth/:token" exact component={Auth} />
          <CustomRoute isPrivate path="/" exact component={Index} />
          <CustomRoute isPrivate path="/welcome" exact component={Welcome} />
          <CustomRoute
            isPrivate
            path="/dashboard"
            exact
            component={Dashboard}
          />
          <CustomRoute isPrivate path="/login" exact component={Login} />
          <CustomRoute isPrivate path="/guide" exact component={Guide} />
          <CustomRoute isPrivate path="/perfumes" exact component={Perfume} />
          <CustomRoute isPrivate path="/profile" exact component={Profile} />
          <CustomRoute isPrivate path="/address" exact component={Address} />
          <CustomRoute isPrivate path="/account" exact component={Account} />
          <CustomRoute isPrivate path="/history" exact component={History} />
          <CustomRoute isPrivate path="/clubamyi" exact component={ClubAmyi} />
          <CustomRoute
            isPrivate
            path="/curiosityfacts"
            exact
            component={CuriosityFacts}
          />
          <CustomRoute isPrivate path="/skintest" exact component={SkinTest} />
          <CustomRoute isPrivate path="/giftcode" exact component={GiftCode} />
          <CustomRoute
            isPrivate
            path="/journeyResponse/:id"
            exact
            component={JourneyResponses}
          />
          <CustomRoute
            isPrivate
            path="/revieworder/:id"
            exact
            component={ReviewOrder}
          />
          <CustomRoute
            isPrivate
            path="/requests/moviment/:id"
            exact
            component={RequestsMoviment}
          />
          <CustomRoute
            isPrivate
            path="/requests/details/:id"
            exact
            component={RequestsDetails}
          />
          <CustomRoute
            isPrivate
            path="/requests"
            exact
            component={MyRequests}
          />
          <CustomRoute
            isPrivate
            path="/instructions"
            exact
            component={Instructions}
          />
          <CustomRoute
            isPrivate
            path="/perfumes/details/:id"
            exact
            component={DetailsPerfume}
          />
          <CustomRoute
            isPrivate
            path="/olfactory-journey"
            exact
            component={OlfactoryJourney}
          />
          <CustomRoute
            isPrivate
            path="/notes/skintest"
            exact
            component={SkinTestFlux}
          />

          <CustomRoute
            isPrivate
            path="/olfactory-journey/guide"
            exact
            component={GuideOlfactory}
          />
          <CustomRoute
            isPrivate
            path="/olfactory-journey/playeducational"
            exact
            component={PlayEducational}
          />
          <CustomRoute
            isPrivate
            path="/olfactory-journey/primary"
            exact
            component={JourneyPrimary}
          />
          <CustomRoute
            isPrivate
            path="/olfactory-journey/responseuser/:idPerfum"
            exact
            component={ResponseUser}
          />
          <CustomRoute
            isPrivate
            path="/notes/perfums"
            exact
            component={NotesPerfum}
          />
          <CustomRoute
            isPrivate
            path="/notes/second"
            exact
            component={SecondNotes}
          />
          <CustomRoute isPrivate path="/watch" exact component={WatchPage} />
          <CustomRoute
            isPrivate
            path="/alterPerfums"
            exact
            component={AlterPerfums}
          />
          <CustomRoute
            isPrivate
            path="/selectPerfumCorrect"
            exact
            component={SelectPerfumCorrect}
          />
          <CustomRoute
            isPrivate
            path="/sharepodium"
            exact
            component={SharePodium}
          />
          <CustomRoute
            isPrivate
            path="/sharepodium/success"
            exact
            component={SharePodiumSuccess}
          />
        </NotesFinallyProvider>
      </Switch>
    </Router>
  );
}
