import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%:

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 80vh;

    > a, button {
      margin-top: 5rem;
      width: 70%;
      font-size: 1.2rem;
      font-weight: 400;
    }

  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: ${theme.font.sizes.large};
    margin-top: 5rem;
    font-family: ${theme.font.family.LoraRegular};
    font-weight: medium;
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    margin: 2rem auto;
    text-align: center;
    max-width: 80%;
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
  `}
`;

export const Image = styled.img`
  width: 20rem;
  margin: 0 auto;
  display: block;
`;
