import Button from 'components/Button';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';

import Logo from '../../assets/images/ClubeAmyi/clubeamyi.jpg';

function ClubAmyi() {
  function handleRedirect() {
    document.location.href = 'https://www.amyi.co/loja-clube-amyi';
  }

  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Image src={Logo} />
        <S.Title>Muito mais vantagem</S.Title>
        <S.Description>
          Ao viver a Experiência Amyi, você faz parte do nosso clube de
          benefícios e vantagens especiais.
        </S.Description>
        <S.Description>
          Desconto em produtos, promoções, gifts e oportunidades incríveis para
          seguirmos sempre lado a lado.
        </S.Description>
        <S.Description>
          A primeira delas, todos os Perfumes Amyi em 100ml tem um preço
          especial para você.
        </S.Description>
        <Button name="APROVEITAR AGORA" onClick={() => handleRedirect()} />
      </S.Wrapper>
      <Footer />
    </>
  );
}

export default ClubAmyi;
