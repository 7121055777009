import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;

    font-size: ${theme.font.sizes.xxextraLarge};
    font-family: ${theme.font.family.LoraVariable};
    letter-spacing: 0.2rem;
    font-weight: bold;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;

    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    text-align: left;
    color: ${theme.colors.secondBlack};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 1.4rem auto;

    ${Title} {
      font-size: ${theme.font.sizes.large};
      text-align: left;
    }

    ${Description} {
      width: 100%;
      margin-top: ${theme.spacings.xsmall};
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const LinkHeader = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    width: 1.5rem;
    height: 1.7rem;
    color: ${theme.colors.secondBlack};
  `}
`;

export const Image = styled.img`
  width: 2.5rem;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Image} {
    width: 1.4rem;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    min-height: 75vh;
    background-color: ${theme.colors.primary};
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    ${Title} {
      font-size: 24px;
      text-align: left;
      letter-spacing: 0.2rem;
      margin-bottom: 0.5rem;
      font-family: ${theme.font.family.LoraVariable};
      font-weight: 700;
      width: 210px;
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
      font-size: 16px;
      max-width: 95%;
    }

    > button,
    a {
      width: 127px;
    }

    ${media.lessThan('medium')`
      ${Title} {
        font-size: 16px;
        width: 80%;
      }

      > button,
      a {
        width: 190px;
      }
    `}
  `}
`;

export const CodeInsertWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      height: 42px;
      width: 60px !important;
      margin: 0 !important;
    }

    .info-tooltip {
      height: 25px;
    }

    #info-tooltip {
      height: 35px !important;
    }

    input {
      width: 190px;
      height: 30px;
      font-size: 15px;
      color: #494c4f;
      text-align: center;

      &::placeholder {
        font-size: 15px;
        color: #494c4f;
      }
    }

    ${media.lessThan('medium')`
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      width: 100%;
      margin-top: 12px;

      .info-tooltip {
        position: absolute;
        right: 0;
      }

      input {
        width: 126px;
        height: 18px;
        padding: 4px;
        font-size: 12px;

        &::placeholder {
          font-size: 8px;
        }
      } 

      button {
        width: 126px !important;
      }
    `}
  `}
`;

export const CustomCssTooltip = css`
  > div {
    width: 350px;
  }

  ${media.lessThan('medium')`
    > div {
      width: 250px;
      left: -100px;
      font-size: 12px;
    }
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-items: center;
    align-items: center;
    gap: 2rem;

    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    background-color: ${theme.colors.white};
    padding: 1.6rem;
    margin-bottom: 0.8rem;

    ${Image} {
      width: 129px;
      max-height: 99px;
    }

    ${Svg} {
      width: 2rem;
    }

    ${Title} {
      letter-spacing: 0;
    }

    ${Description} {
      font-size: 1.2rem;
    }

    button {
      margin: 1rem 0;
      font-size: 1rem;
      width: 127px;
      height: 18px;
    }

    ${media.greaterThan('medium')`
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      height: 200px;

      ${Image} {
        width: 220px;
        max-height: 170px;
      }

      ${Description} {
        width: 60%;
        text-align: left;
      }

      button, a {
        width: 150px;
        height: 30px;
      }
    `}
  `}
`;

export const WrapperCode = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    height: 74vh;
    padding-top: 4rem;
  `}
`;

export const BoxJourneyNulled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: 2rem;
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `}
`;

export const ButtonRequestCod = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};
    max-width: 80%;
    color: ${theme.colors.white};
    display: block;
    margin: 0 auto;
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    margin-top: 2rem;
    padding: 1rem;
    letter-spacing: 0.1rem;
    min-width: 45%;

    span {
      font-weight: bold;
    }

    main {
      min-height: 0;
    }

    svg {
      height: 20px;
    }
  `}
`;

export const WrapperCodeInsert = styled(WrapperCode)`
  height: 85vh;

  ${BoxJourneyNulled} {
    flex-direction: column;
  }

  ${ButtonRequestCod} {
    margin-left: 55%;
  }
`;

export const ButtonWrapperFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  > button {
    margin-bottom: 2rem;
  }
`;

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    letter-spacing: 0.3rem;
    border: 1px solid ${theme.colors.secondGray};
    margin-top: 2rem;
    font-family: ${theme.font.family.LoraRegular};
    font-size: 17px;
  `}
`;

export const Tabs = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${theme.colors.white};
    margin-top: 2rem;

    ${media.greaterThan('small')`
      justify-content: center;
      align-items: center;
    `}
  `}
`;

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    &:after {
      content: '';
      display: block;
      margin: 0 auto;
      transform: translateY(5px);
      width: 8rem;
      height: 0.2rem;
      background-color: ${theme.colors.black};
      border-radius: 1rem;
      animation: hoverAnimation 0.2s forwards;
    }

    &:first-child {
      &:after {
        animation: hoverAnimationPrimary 0.2s forwards;
      }
    }

    @keyframes hoverAnimation {
      from {
        width: 0;
        left: 50%;
      }
      to {
        width: 8rem;
        left: 0;
      }
    }

    @keyframes hoverAnimationPrimary {
      from {
        width: 0;
        left: 50%;
      }
      to {
        width: 14.5rem;
        left: 0;
      }
    }
  `,
};

type OptionProps = {
  active?: boolean;
};

export const Option = styled.div<OptionProps>`
  ${({ theme, active }) => css`
    color: ${theme.colors.secondBlack};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: ${theme.font.family.YaldeviMedium};
    cursor: pointer;
    flex: 1;

    ${!!active && wrapperModifiers.active(theme)}

    ${media.greaterThan('small')`
      flex: 0.3;
    `}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    padding: 2rem 0;

    ${media.greaterThan('small')`
      width: 100%;
      margin: 0 auto;
    `}
  `}
`;

export const BoxLink = styled(Link)`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 5rem 2fr;
    justify-items: center;
    align-items: center;
    gap: 2rem;

    min-height: 10rem;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    background-color: ${theme.colors.white};
    padding: 2rem;
    margin-bottom: 0.8rem;

    ${Svg} {
      width: 2rem;
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
      display: flex;
    `}
  `}
`;

export const Legend = styled(Description)`
  font-size: 1rem;
  margin-top: 2rem;
`;

export const OpenCode = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: ${theme.colors.borderBoxGray};
    text-decoration-line: underline;
    margin: 2rem auto;
    display: block;
  `}
`;
