import { accountOrdersRequest } from 'store/modules/account/actions';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as actions from './actions';
import { account } from 'services/account';
import { ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import history from 'utils/history';

export function* getOrders({
  payload,
}: ActionType<typeof actions.accountOrdersRequest>) {
  try {
    const { data } = yield call(account.listOrders, payload.userId);

    yield put(actions.accountOrdersSuccess({ data: data.orders }));
  } catch (err) {
    yield put(actions.accountOrdersFailure());
  }
}

export function* getDetailsOrder({
  payload,
}: ActionType<typeof actions.detailsOrdersRequest>) {
  try {
    const { data } = yield call(account.detailsOrders, payload.id);

    yield put(actions.detailsOrdersSuccess({ data: data.orders }));
  } catch (err) {
    yield put(actions.detailsOrdersFailure());
  }
}

export function* getDetailsAddress({
  payload,
}: ActionType<typeof actions.detailsAddressRequest>) {
  try {
    const { data } = yield call(
      account.detailsAddress,
      payload.userId,
      payload.journeyId
    );

    yield put(actions.detailsAddressSuccess({ data }));
  } catch (err) {
    yield put(actions.detailsAddressFailure());
  }
}

export function* getDetailsAccount({
  payload,
}: ActionType<typeof actions.detailsAccountRequest>) {
  try {
    const { data } = yield call(account.detailsAccount, payload.userId);

    yield put(actions.detailsAccountSuccess({ data: data.info_user }));
  } catch (err) {
    yield put(actions.detailsAccountFailure());
  }
}

export function* editingAccount({
  payload,
}: ActionType<typeof actions.editingAccountRequest>) {
  try {
    const { data } = yield call(
      account.editingProfile,
      payload.data,
      payload.userId
    );

    toast.success(data.data.msg);

    yield put(actions.editingAccountSuccess());
  } catch (err) {
    yield put(actions.editingAccountFailure());
    toast.error('Não foi possivel alterar os dados, tente novamente!');
  }
}

export function* editingAddress({
  payload,
}: ActionType<typeof actions.editingAddressRequest>) {
  try {
    const { data } = yield call(
      account.editingAddress,
      payload.userId,
      payload.data
    );

    toast.success(data.msg);

    yield put(actions.editingAddressSuccess());
  } catch (err) {
    yield put(actions.editingAddressFailure());
    toast.error('Não foi possivel alterar os dados, tente novamente!');
  }
}

export function* getDetailsHome({
  payload,
}: ActionType<typeof actions.getHomeDataRequest>) {
  try {
    const { data } = yield call(account.listHome, payload.user_id);

    yield put(actions.getHomeDataSuccess({ Journeys: data }));
  } catch (err) {
    yield put(actions.getHomeDataFailure());
  }
}

export function* creatingJourney({
  payload,
}: ActionType<typeof actions.creatingJourneyRequest>) {
  try {
    const { data } = yield call(account.creatingManual, payload.data);

    yield put(actions.creatingJourneySuccess(data.journey_id));
    history.push('/guide');
  } catch (err) {
    toast.error(err.response.data.msg);
    yield put(actions.creatingJourneyFailure());
  }
}

export function* creatingJourneyManual({
  payload,
}: ActionType<typeof actions.creatingJourneyManualRequest>) {
  try {
    const { data } = yield call(account.creatingManual, payload.data);

    yield put(actions.creatingJourneyManualSuccess());
  } catch (err) {
    toast.error(err.response.data.msg);
    yield put(actions.creatingJourneyManualFailure());
  }
}

export default all([
  takeLatest('@account/ORDERS_REQUEST', getOrders),
  takeLatest('@account/DETAILS_ORDERS_REQUEST', getDetailsOrder),
  takeLatest('@account/DETAILS_ADDRESS_REQUEST', getDetailsAddress),
  takeLatest('@account/DETAILS_ACCOUNT_REQUEST', getDetailsAccount),
  takeLatest('@account/EDITING_ACCOUNT_REQUEST', editingAccount),
  takeLatest('@account/EDITING_ADDRESS_REQUEST', editingAddress),
  takeLatest('@account/HOME_DATA_REQUEST', getDetailsHome),
  takeLatest('@account/CREATING_JOURNEY_REQUEST', creatingJourney),
]);
