import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import * as S from './styles';
import img1 from '../../../../pages/OlfactoryJourney/JourneyPrimary/imagem1.jpg';
import img2 from '../../../../pages/OlfactoryJourney/JourneyPrimary/imagem2.jpg';
import Button from 'components/Button';

import { FiCheck } from 'react-icons/fi';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';

type ImageGridProps = {
  data: Question;
  information: string;
};

type selectedProps = {
  title: string;
  url: string;
};

const ImageGrid = ({ data, information }: ImageGridProps) => {
  const [selected, setSelected] = useState<selectedProps[]>([]);
  const [answersUser, setAnswersUser] = useState<any>([]);
  const dispatch = useDispatch();

  const { perfum_name, perfum_id } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const { loading } = useSelector((state: StoreState) => state.journey);
  const userId = localStorage.getItem('user_id_amyi');
  const [otherOption, setOtherOption] = useState('');

  function selection(title: string, url: string) {
    const alredySelected = selected.findIndex((item) => item.title === title);

    if (alredySelected >= 0) {
      const filteredItems = selected.filter((item) => item.title !== title);
      setSelected(filteredItems);
    } else {
      setSelected([...selected, { title, url }]);
    }
  }

  useEffect(() => {
    setAnswersUser(data.answers_user);
  }, []);

  function handleStep() {
    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: selected.map((item) => item.title),
      answers_url: selected.map((item) => item.url),
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  }

  return (
    <S.Wrapper>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data.question_id} />
          <S.Description>{data.title}</S.Description>
          <S.Information>Selecione quantas alternativas quiser</S.Information>
          <S.GridImages>
            {data.answers_avaliable.map((item) => (
              <S.BoxImage
                key={item.legend}
                onClick={() => selection(item.legend, item.url)}
              >
                {answersUser.length > 0 ? (
                  <S.WrapperImage>
                    <S.Image
                      key={item.id}
                      src={process.env.REACT_APP_BASEURL_IMAGES + item.url}
                      selected={answersUser.findIndex(
                        (selected: any) => selected.answer_url === item.url
                      )}
                    />
                    {answersUser.findIndex(
                      (selected: any) => selected.answer_user === item.legend
                    ) !== -1 && (
                      <S.ImageBox>
                        <FiCheck size={40} color="white" />
                      </S.ImageBox>
                    )}
                  </S.WrapperImage>
                ) : (
                  <S.WrapperImage>
                    <S.Image
                      key={item.id}
                      src={process.env.REACT_APP_BASEURL_IMAGES + item.url}
                      selected={selected.findIndex(
                        (selected) => selected.title === item.legend
                      )}
                    />
                    {selected.findIndex(
                      (selected) => selected.title === item.legend
                    ) !== -1 && (
                      <S.ImageBox>
                        <FiCheck size={40} color="white" />
                      </S.ImageBox>
                    )}
                  </S.WrapperImage>
                )}

                <S.Title>{item.legend}</S.Title>
              </S.BoxImage>
            ))}
          </S.GridImages>
          {/* <S.Input
            placeholder="outro"
            value={otherOption}
            onChange={(e) => setOtherOption(e.target.value)}
          /> */}
        </>
      )}
      {selected.length >= 1 ||
      answersUser.length >= 1 ||
      otherOption.length >= 1 ? (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => handleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => handleStep()} />
          )}
        </>
      ) : (
        <Button name="ENVIAR" theme="disabled" />
      )}
    </S.Wrapper>
  );
};

export default ImageGrid;
