import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }to {
    transform: rotate(360deg)
  }
`;

export const Container = styled.main`
  width: 100%;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: ${rotate} ease-in-out 0.5s infinite;
  }
`;
