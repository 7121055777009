import styled, { css, DefaultTheme } from 'styled-components';
import Switch from 'react-switch';
import media from 'styled-media-query';

export const Title = styled.h1`
  ${({ theme }) => css`
    text-align: center;
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.secondBlack};
    font-family: ${theme.font.family.LoraRegular};
    letter-spacing: 0.3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    max-width: 55%;
    letter-spacing: 0.1rem;
    margin: 0 1.5rem;

    &:after,
    &:before {
      content: '';
      display: block;
      margin: 1.5rem 0;
      width: 100%;
      height: 0.1rem;
      background-color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${Title} {
      margin-top: 1rem;
      font-size: ${theme.font.sizes.xlarge};
      font-weight: bold;
      color: ${theme.colors.black};
    }

    ${Description} {
      max-width: 65%;
      text-align: center;
      margin-bottom: 0;
      &:after,
      &:before {
        display: none;
      }
    }

    ${media.greaterThan('small')`
      button, a {
        max-width: 30%;
      }
    `}
  `}
`;

export const Image = styled.img`
  margin: 2rem 0;
  max-width: 70%;

  ${media.greaterThan('small')`
    max-width: 40%;
  `}
`;

type InputProps = {
  value?: boolean;
};

export const SectionInput = styled.div<InputProps>`
  ${({ value }) => css`
    margin: 2rem 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

type boxChecked = {
  checked: boolean;
};

export const Box = styled.div<boxChecked>`
  ${({ theme, checked }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${theme.maxwidthMobile};
    max-width: 70%;

    ${Description} {
      margin: 1rem 0;
      font-family: ${theme.font.family.YaldeviRegular};

      &:first-child {
        color: ${!checked ? `${theme.colors.secondBlack}` : `#A0A0A0`};
      }

      &:last-child {
        color: ${checked ? `${theme.colors.secondBlack}` : `#A0A0A0`};
      }
    }
  `}
`;

const wrapperSwitch = {
  active: (theme: DefaultTheme) => css`
    .react-switch-bg {
      background-color: ${theme.colors.primary} !important;
      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 50%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 100%;
        background-color: ${theme.colors.secondBlack};
      }
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    .react-switch-bg {
      background-color: ${theme.colors.primary} !important;
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        width: 50%;
        height: 100%;
        background-color: ${theme.colors.secondBlack};
      }
    }
  `,
};

type SwitchProps = {
  mode: 'active' | 'disabled';
};

export const SwitchComponent = styled(Switch)<SwitchProps>`
  ${({ theme, checked, mode }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 80%;

    .react-switch-bg {
      width: 100% !important;
      height: 14px !important;
    }

    .react-switch-handle {
      transform: ${checked
        ? `translate(978%, -0.7rem) !important`
        : 'translate(-10%, -0.7rem) !important'};
      z-index: 99;
      box-shadow: 0px 0px 8px #00000033;
    }

    ${!!mode && wrapperSwitch[mode](theme)}
  `}
`;
