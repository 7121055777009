import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    min-height: 85vh;
    padding: 1rem 0;
  `}
`;
