import * as S from './styles';
import ArrowBack from '../../assets/images/icones/arrow-left-light.svg';

type NavBackProps = {
  title: string;
  link?: string;
  onClick?: () => void;
};

const NavBack = ({ title, link = '/dashboard', onClick }: NavBackProps) => (
  <S.WrapperHeader onClick={onClick}>
    <S.Header>
      <S.LinkHeader to={link}>
        <S.Svg src={ArrowBack} />
      </S.LinkHeader>
      <S.Title>{title}</S.Title>
    </S.Header>
  </S.WrapperHeader>
);

export default NavBack;
