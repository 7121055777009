import { action } from 'typesafe-actions';
import { DashboardState } from './types';

export function dashboardRequest(userId: string | null, journeyId: number) {
  return action('@dashboard/DASHBOARD_REQUEST', { userId, journeyId });
}

export function dashboardSuccess(data: DashboardState) {
  return action('@dashboard/DASHBOARD_SUCCESS', {
    data,
  });
}

export function dashboardFailure() {
  return action('@dashboard/DASHBOARD_FAILURE');
}
