import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  min-height: 70vh;
`;

export const Box = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 8px #00000029;
    cursor: pointer;

    background-color: ${theme.colors.white};
    max-width: 90%;
    margin: 1rem auto;
    padding: 1rem 0;

    div {
      margin-left: 1rem;
    }
  `}
`;

export const Image = styled.img`
  width: 10rem;
  padding-left: 1rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 1.3rem;
    max-width: 100%;
    line-height: 2rem;
    letter-spacing: 0.2rem;
    font-family: ${theme.font.family.LoraVariable};
    font-weight: 500;
  `}
`;

export const Author = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    max-width: 90%;
    letter-spacing: 0.1rem;
    color: ${theme.colors.secondBlack};
    font-weight: normal;
    font-family: ${theme.font.family.YaldeviRegular};
    margin-top: 1rem;
  `}
`;

export const ModalDetails = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    position: absolute;
    top: 0;
    z-index: 99;
    min-height: 200vh;
    width: 100%;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 0 auto;

    ${Image} {
      width: 100%;
    }

    ${Title} {
      font-size: ${theme.font.sizes.extraLarge};
      line-height: 3rem;
      text-align: center;
    }

    ${Author} {
      text-align: center;
      margin-bottom: 2rem;
    }
  `}
`;

export const Text = styled.div`
  max-width: 100%;
  p {
    max-width: 100%;
    margin: 1rem 0;
  }

  img {
    max-width: 100%;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 8rem;
  right: 3rem;
  z-index: 99;
`;
