import Button from 'components/Button';
import ProgressCircles from 'components/OlfactoryJourney/ProgressCircles';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  responseUserRequest,
} from 'store/modules/olfactoryJourney/actions';

import ReinventedColorWheel from 'reinvented-color-wheel/react';
import 'reinvented-color-wheel/css/reinvented-color-wheel.min.css';
import { Question } from 'store/modules/olfactoryJourney/types';
import { StoreState } from 'store/createStore';
import LoadingRound from 'components/LoadingRound';

type ColorCircle = {
  data: Question;
};

type Answers = {
  answer_user: string;
  answer_url: string;
};

const ColorCircle = ({ data }: ColorCircle) => {
  const [colors, setColors] = useState<string[]>([]);
  const [answersUser, setAnswersUser] = useState<string[]>();
  const [hex, setHex] = useState('');
  const dispatch = useDispatch();
  const { perfum_name, perfum_id } = useSelector(
    (state: StoreState) => state.journey.info_perfum
  );
  const { journeyId } = useSelector((state: StoreState) => state.account);
  const { loading } = useSelector((state: StoreState) => state.journey);
  const [otherOption, setOtherOption] = useState('');

  const userId = localStorage.getItem('user_id_amyi');

  useEffect(() => {
    setAnswersUser(data.answers_user.map((item) => item.answer_user));
    setColors(data.answers_user.map((item) => item.answer_user));
  }, []);

  function addColors() {
    setColors([...colors, hex]);
  }

  function removeColors() {
    setColors([...colors.splice(0, colors.length - 1)]);
  }

  const HandleStep = () => {
    const answersUser = otherOption.length
      ? [...colors, otherOption]
      : [...colors];

    const responseUser = {
      user_id: userId,
      perfum_id,
      journey_id: journeyId,
      question_id: data.question_id,
      answers_user: answersUser,
      answers_url: [],
    };

    data.answers_user.length >= 1
      ? dispatch(nextStep())
      : dispatch(responseUserRequest(responseUser));
  };

  return (
    <S.Colors>
      <S.Title>{perfum_name}</S.Title>
      {loading ? (
        <LoadingRound />
      ) : (
        <>
          <ProgressCircles position={data.question_id} />

          <S.Description>{data.title}</S.Description>

          <S.Information>
            Gire a bolinha para mudar as opções.
            <br />
            Clique na imagem ao centro para selecionar.
          </S.Information>

          <S.Information>Selecione até 3 cores</S.Information>

          <S.OptionCircle>
            <ReinventedColorWheel
              hex={hex}
              wheelDiameter={200}
              wheelThickness={5}
              handleDiameter={16}
              wheelReflectsSaturation
              onChange={({ hex }) => setHex(hex)}
            ></ReinventedColorWheel>
            {colors.length >= 3 ? (
              <S.Option color={colors[2]}></S.Option>
            ) : (
              <S.Option color={hex} onClick={addColors}></S.Option>
            )}
          </S.OptionCircle>

          <S.Options>
            {colors.map((color, index) => (
              <S.Option key={index} color={color}></S.Option>
            ))}

            <S.AddColor onClick={removeColors}>-</S.AddColor>
            <S.Input
              placeholder="outro"
              value={otherOption}
              onChange={(e) => setOtherOption(e.target.value)}
            />
          </S.Options>
        </>
      )}
      {colors.length >= 1 || otherOption.length >= 1 ? (
        <>
          {data.answers_user.length >= 1 ? (
            <Button name="CONTINUAR" onClick={() => HandleStep()} />
          ) : (
            <Button name="ENVIAR" onClick={() => HandleStep()} />
          )}
        </>
      ) : (
        <Button name="ENVIAR" theme="journey" />
      )}
    </S.Colors>
  );
};

export default ColorCircle;
