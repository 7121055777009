import { useState } from 'react';
import * as S from './styles';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { selectedPerfumSkinTest } from 'store/modules/olfactoryJourney/actions';

export type optionProps = {
  id: number;
  name: string;
  description: string;
};

export type selectSkinProps = {
  items?: optionProps[];
};

const SelectSkin = ({ items }: selectSkinProps) => {
  const [open, setOpen] = useState(false);
  const [idSelected, setIdSelected] = useState<number>();
  const [selected, setSelected] = useState('Selecionar perfume');
  const [description, setDescription] = useState('');

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(!open);
  };

  const selection = (item: optionProps) => {
    setSelected(item.name);
    setDescription(item.description);
    setIdSelected(item.id);
    setOpen(!open);

    dispatch(selectedPerfumSkinTest(item.id));
  };

  return (
    <>
      <S.Wrapper>
        <S.Main>
          <S.Select onClick={handleOpen}>
            {description !== '' ? (
              <S.Selected>
                <div>
                  <S.Title>{selected}</S.Title>
                  <S.Description>{description}</S.Description>
                </div>
                <IoIosArrowDown size={15} />
              </S.Selected>
            ) : (
              <S.Placeholder>
                {selected} <IoIosArrowDown size={15} />
              </S.Placeholder>
            )}
          </S.Select>
          {open && (
            <S.Options>
              {items?.map((item) => (
                <S.Option key={item.id} onClick={() => selection(item)}>
                  <S.Title>{item.name}</S.Title>
                  <S.Description>{item.description}</S.Description>
                </S.Option>
              ))}
            </S.Options>
          )}
        </S.Main>
      </S.Wrapper>
    </>
  );
};

export default SelectSkin;
