import Modal from 'components/Modal';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    overflow: hidden;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;
    height: 85vh;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    font-family: ${theme.font.family.LoraRegular};
    line-height: 2rem;
    letter-spacing: 0.1rem;
    text-align: center;
    margin: 2rem 0;
  `}
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    width: 3.5rem;
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    box-shadow: 0px 0px 8px #00000029;

    ${Title} {
      margin: 0;
      margin-top: 2.5rem;
      color: ${theme.colors.green};
      font-weight: normal;
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.2rem;
    }
  `}
`;

export const InputCod = styled.input`
  ${({ theme }) => css`
    margin-top: 1rem;
    color: ${theme.colors.black};
    font-family: ${theme.font.family.LoraRegular};
    font-weight: bold;
    text-align: center;
    width: 90%;
    height: 4rem;
    border: 1px solid ${theme.colors.primary};
    letter-spacing: 0.3rem;
    color: ${theme.colors.black};

    &::placeholder {
      color: ${theme.colors.secondGray};
    }
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.green};
    font-family: ${theme.font.family.LoraRegular};
    background: none;
    text-transform: none;
    letter-spacing: 0.2rem;
  `}
`;

export const ButtonBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0 0 0;
    justify-items: start;

    > button,
    a {
      font-weight: normal;
      font-size: ${theme.font.sizes.xsmall};
      height: 3.5rem;
      width: 90%;
    }

    ${Button} {
      font-weight: bold;
      text-align: left;
    }
  `}
`;

export const ButtonLink = styled(Link)`
  ${({ theme }) => css`
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20vh;
    padding: 1rem 0;
    border: 1px solid ${theme.colors.secondGray};
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    font-weight: normal;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;
  `}
`;

export const ButtonContinue = styled.button`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 20vh;
    height: 3.5rem;
    border: 1px solid ${theme.colors.secondGray};
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    font-weight: normal;
    letter-spacing: 0.1rem;
    margin-bottom: 2rem;

    &:disabled {
      border: 1px solid ${theme.colors.secondGray};
      color: ${theme.colors.secondGray};
      background: none;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.secondBlack};
    text-align: center;
    letter-spacing: 0.1rem;
    font-size: ${theme.font.sizes.small};
  `}
`;

export const RecoveryModal = styled(Modal)``;

export const ModalMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    ${Title} {
      font-weight: bold;
      letter-spacing: 0.3rem;
      line-height: 3rem;
      font-size: ${theme.font.sizes.xxlarge};
      font-family: ${theme.font.family.LoraRegular};
      width: 100%;
    }

    ${Description} {
      color: ${theme.colors.black};
      line-height: 2rem;
    }

    ${InputCod} {
      background: none;
      text-align: left;
      width: 100%;
      padding-left: 1rem;
      border: 1px solid ${theme.colors.secondGray};
      margin: 2rem 0;
      color: ${theme.colors.black};

      &::placeholder {
        color: ${theme.colors.secondGray};
      }
    }

    > button,
    a {
      width: 100%;
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.2rem;
    }
  `}
`;

export const ModalMainError = styled(ModalMain)`
  > button {
    margin-top: 2rem;
  }
`;

export const MessageBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.fourthGray};
    margin-top: 2rem;
    padding: 1.6rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      top: -0.5rem;
      right: 50%;
      transform: rotate(135deg);
      background-color: ${theme.colors.fourthGray};
    }

    ${Description} {
      color: ${theme.colors.white};
      font-family: ${theme.font.family.YaldeviRegular};
      font-size: ${theme.font.sizes.small};
      text-align: left;
      font-weight: normal;
      letter-spacing: 0.2rem;
      width: 90%;
    }
  `}
`;
