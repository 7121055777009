import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    max-width: 100%;
    margin: 0 auto;
    height: 100vh;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    button,
    a {
      width: 50%;
      font-size: 1.3rem;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.lineGray};
    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    padding: 2rem 0;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::after {
      content: '';
      margin: 0 auto;
      width: 35rem;
      height: 0.1rem;
      margin-top: 1rem;
      border-bottom: 0.25px solid #747474;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    font-weight: 500;
    color: ${theme.colors.black};
    font-size: 1.4rem;
    text-align: left;
    max-width: 33rem;
    letter-spacing: 0.125rem;
    margin: 0 auto;
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
    padding: 2rem 4rem;
    margin: 3.7rem 0 7.7rem 0;

    ${Description} {
      color: #494c4f;
      text-align: center;
      max-width: 100%;
      letter-spacing: 0.1;
    }
  `}
`;
