import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import styled, { css, DefaultTheme } from 'styled-components';
import media from 'styled-media-query';
import { newBoxProps } from '.';

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;

    font-size: ${theme.font.sizes.xxextraLarge};
    font-family: ${theme.font.family.LoraVariable};
    letter-spacing: 0.2rem;
    font-weight: bold;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.font.sizes.small};
    letter-spacing: 0.1rem;

    font-family: ${theme.font.family.YaldeviRegular};
    font-weight: normal;
    text-align: left;
    color: ${theme.colors.secondBlack};

    p {
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.small};
      letter-spacing: 0.1rem;

      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
      text-align: left;
      color: ${theme.colors.secondBlack};
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    width: ${theme.maxwidthMobile};
    max-width: 100%;
    margin: 1.4rem auto;

    ${Title} {
      font-size: ${theme.font.sizes.large};
      text-align: left;
    }

    ${Description} {
      width: 100%;
      margin-top: ${theme.spacings.xsmall};
    }

    ${media.greaterThan('small')`
      width: ${theme.maxwidthDesktop};
    `}
  `}
`;

export const LinkHeader = styled(Link)`
  width: 3rem;
  height: 3rem;
`;

export const Svg = styled(ReactSVG)`
  ${({ theme }) => css`
    width: 1.5rem;
    height: 1.7rem;
    color: ${theme.colors.secondBlack};
  `}
`;

export const Image = styled.img`
  width: 2.5rem;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Image} {
    width: 1.4rem;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};

    padding: 2rem 0;
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    ${Title} {
      font-size: ${theme.font.sizes.large};
      text-align: left;
      letter-spacing: 0.2rem;
      margin-bottom: 0.5rem;
      font-family: ${theme.font.family.LoraVariable};
      font-weight: normal;
    }

    ${Description} {
      font-family: ${theme.font.family.YaldeviRegular};
      font-weight: normal;
      font-size: ${theme.font.sizes.small};
      max-width: 95%;
    }
  `}
`;

const newBoxModifiers = {
  initial: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.borderBoxGray};
  `,
  medium: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.borderBoxGray};
  `,
  completed: (theme: DefaultTheme) => css`
    background: rgba(222, 217, 217, 0.6);
  `,
  none: () => css``,
};

type BoxProps = {
  borderActive?: boolean;
  boxStatus?: 'initial' | 'medium' | 'completed' | 'none';
};

export const Box = styled(Link)<BoxProps>`
  ${({ theme, borderActive = false, boxStatus = 'none' }) => css`
    display: grid;
    grid-template-columns: 5rem 2fr;
    justify-items: center;
    align-items: center;
    gap: 2rem;

    min-height: 10rem;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    background-color: ${theme.colors.white};
    padding: 2rem;
    margin-bottom: 0.8rem;

    border: ${borderActive ? `1px solid ${theme.colors.borderBoxGray}` : ''};

    ${Svg} {
      width: 2rem;
    }

    ${!!boxStatus && newBoxModifiers[boxStatus](theme)}

    ${media.greaterThan('small')`
      width: 30%;
      display: flex;
    `}
  `}
`;

type ProgressBarTypes = {
  progress?: number;
};

export const ProgressBar = styled.div<ProgressBarTypes>`
  ${({ theme, progress }) => css`
    margin-top: 0.7rem;
    width: 100%;
    height: 0.6rem;
    background-color: ${theme.colors.threeGray};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0.5rem;

    &:after {
      content: '';
      display: block;
      height: 0.6rem;
      width: ${progress + '%'};
      background-color: ${theme.colors.green};
      color: red;
      border-radius: 0.5rem;
    }
  `}
`;

export const WrapperProgress = styled.div`
  ${({ theme }) => css`
    width: 80%;
    margin-left: 7rem;
    margin-top: 1rem;

    > div {
      display: grid;
      grid-template-columns: 5fr 1fr;
      width: 100%;

      ${Title} {
        margin-top: 0.3rem;
        text-align: right;
        font-weight: normal;
        font-family: ${theme.font.family.LoraRegular};
        font-size: ${theme.font.sizes.xsmall};
        margin-bottom: 0;
      }
    }

    ${Title} {
      text-align: left;
      color: ${theme.colors.green};
      font-size: 1rem;
      margin-top: 0.5rem;
      font-family: ${theme.font.family.LoraRegular};
      font-weight: 600;
      letter-spacing: 0.2rem;
    }

    ${media.greaterThan('small')`
      margin-left: 5rem;
    `}
  `}
`;

export const NewBox = styled(Link)<newBoxProps>`
  ${({ theme, boxStatus = 'initial' }) => css`
    display: flex;
    flex-direction: column;
    /* align-items: center; */

    > div:first-child {
      display: grid;
      grid-template-columns: 5rem 2fr;
      justify-items: center;
      align-items: center;
      gap: 2rem;
    }

    min-height: 10rem;
    width: ${theme.maxwidthMobile};
    margin: 0 auto;

    background-color: ${theme.colors.white};
    padding: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 0.8rem;

    ${Svg} {
      width: 2rem;
    }

    ${!!boxStatus && newBoxModifiers[boxStatus](theme)}

    ${media.greaterThan('small')`
      width: 30%;
      display: flex;

      > div:first-child {
        justify-items: left;
        align-items: left;
        gap: 0rem;
      }
    `}
  `}
`;

export const BoxSvg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuPrincipal = styled(Link)`
  ${({ theme }) => css`
    font-family: ${theme.font.family.LoraRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: ${theme.colors.borderBoxGray};
    text-decoration-line: underline;
    margin: 2rem auto;
    display: block;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  `}
`;

export const IconPerfum = styled.img`
  max-width: 2rem;
`;
