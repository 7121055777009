import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import * as S from './styles';
import { GrFormClose } from 'react-icons/gr';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { detailsOrdersRequest } from 'store/modules/account/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';
import currentFormat from 'utils/currentFormat';
import LoadingRound from 'components/LoadingRound';

function RequestsDetails() {
  type idParams = {
    id: string;
  };
  const { id } = useParams<idParams>();

  const dispatch = useDispatch();

  const { detailsOrder, loading } = useSelector(
    (state: StoreState) => state.account
  );

  useEffect(() => {
    dispatch(detailsOrdersRequest(Number(id)));
  }, [dispatch, id]);

  if (detailsOrder.product !== undefined) {
    const totalAmount = detailsOrder.product.reduce(
      (acc, element) => element.order_total_ammount + acc,
      0
    );
  }
  return (
    <>
      <Navbar />
      <S.Wrapper>
        <S.Closed to="/requests">
          <GrFormClose size={28} />
        </S.Closed>
        <S.Content>
          {loading ? (
            <LoadingRound />
          ) : (
            <>
              <S.Title>Mais Detalhes</S.Title>
              <S.SubTitle>Meu pedido #{id}</S.SubTitle>

              <S.Details>
                {detailsOrder?.product?.map((item) => (
                  <S.Box key={item.order_product}>
                    <S.Title>
                      {item?.order_product} <span>{item.order_qty} Unid.</span>
                    </S.Title>
                    <S.Value>{currentFormat(item.order_total_ammount)}</S.Value>
                  </S.Box>
                ))}

                <S.Box>
                  <S.Title>Frete</S.Title>
                  <S.Value>
                    R$ {detailsOrder?.total_shipping?.replace('.', ',')}
                  </S.Value>
                </S.Box>

                <S.Box>
                  <S.TitleTotal>Total do Pedido</S.TitleTotal>

                  <S.ValueTotal>
                    {currentFormat(Number(detailsOrder.total_order))}
                  </S.ValueTotal>
                </S.Box>
              </S.Details>

              <S.SubTitle>Entrega</S.SubTitle>
              <S.BoxDescription>
                <S.ItemBox>
                  <S.Title>
                    <span>Nome</span>
                    {detailsOrder?.shipping?.order_billing_first_name +
                      ' ' +
                      detailsOrder?.shipping?.order_billing_last_name}
                  </S.Title>
                </S.ItemBox>
                <S.ItemBox>
                  <S.Title>
                    <span>Endereço</span>
                    {detailsOrder?.shipping?.order_shipping_address},{' '}
                    {detailsOrder?.shipping?.order_shipping_number}
                  </S.Title>
                </S.ItemBox>
                <S.Row>
                  <S.Title>
                    <span>Complemento</span>
                    {detailsOrder?.shipping?.order_complement}
                  </S.Title>
                  <S.Title>
                    <span>Bairro</span>
                    {detailsOrder?.shipping?.order_neighborhood}
                  </S.Title>
                </S.Row>
                <S.Row>
                  <S.Title>
                    <span>CEP</span>
                    {detailsOrder?.shipping?.order_shipping_postcode}
                  </S.Title>
                  <S.Title>
                    <span>Cidade</span>
                    {detailsOrder?.shipping?.order_shipping_city}
                  </S.Title>
                </S.Row>
              </S.BoxDescription>
            </>
          )}
        </S.Content>
        <Footer />
      </S.Wrapper>
    </>
  );
}

export default RequestsDetails;
